import React, {createContext, useContext, useEffect, useState} from 'react';
import {useErrorContext} from "../error/ErrorContext";
import {useAccountContext} from "../account/AccountContext";
import {loadStripe} from "@stripe/stripe-js";
import {fetchPaymentCustomerSession, fetchPaymentSubscription} from "../../api/api";
import {useEnvironmentContext} from "../environment/EnvironmentContext";
import i18n from "../../i18n";

/**
 * Initialize Stripe
 */
// const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PRIVATE_KEY}`);

/**
 * PaymentContext
 */
const PaymentContext = createContext();

/**
 * usePaymentContext
 */
export const usePaymentContext = () => {
	return useContext(PaymentContext);
};

/**
 * PaymentProvider
 */
export const PaymentProvider = ({ children }) => {

	const {getIdToken} = useAccountContext();
	const {onError} = useErrorContext();

	const {environment} = useEnvironmentContext();

	const [stripePromise, setStripePromise] = useState(null);

	useEffect(() => {
		if (environment && environment.paymentPublishableKey) {
			const stripe = loadStripe(`${environment.paymentPublishableKey}`, {locale: i18n.language});
			setStripePromise(stripe);
		}
	}, [environment]);

	/**
	 * subscription
	 */
	const subscription = async (priceId) => {

		try {
			const sessionId = await fetchPaymentSubscription(priceId, await getIdToken());
			const stripe = await stripePromise;

			await stripe.redirectToCheckout({ sessionId: sessionId });
		}
		catch (error) {
			onError(error);
		}
	};

	/**
	 * customerSession
	 */
	const customerSession = async () => {

		try {
			const url = await fetchPaymentCustomerSession(await getIdToken());
			window.open(url, "_self")
		}
		catch (error) {
			// onError(error);
		}
	};

	/**
	 * paymentContributeLink
	 */
	const paymentContributeLink = environment.paymentContributeLink;

	return <PaymentContext.Provider value={{subscription, customerSession, paymentContributeLink}}>
				{children}
			</PaymentContext.Provider>
};