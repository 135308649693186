import React, {createContext, useContext, useState} from 'react';
import {useTimeout} from "@mantine/hooks";
import {theme} from "../../Theme";

/**
 * LoadingContext
 */
const LoadingContext = createContext();

/**
 * useLoadingContext
 */
export const useLoadingContext = () => {
	return useContext(LoadingContext);
};

/**
 * LoadingProvider
 */
export const LoadingProvider = ({ children }) => {

	const [loaded, setLoaded] = useState(false);
	const [showHeader, setShowHeader] = useState(true);
	const [showFooter, setShowFooter] = useState(true);

	/**
	 * @returns {function(): void}
	 */
	const isLoading = (showHeader = true, showFooter = true) => {
		console.debug("loading")
		setShowHeader(showHeader);
		setShowFooter(showFooter);
		setLoaded(false);
	};

	const { start: startLoadedTimeout } = useTimeout(() => {

		// Scroll immediately to the top (or any other y value you specify)
		setTimeout(function() {
			window.scroll({
				top: -1, // If 0, the scroll doesn't have any effect
				left: 0
			});
		},10)

		setLoaded(true);
		console.debug("loaded");
	}, theme.custom.components.loader.timeoutMs);

	/**
	 * @returns {function(): void}
	 */
	const isLoaded = () => {
		startLoadedTimeout();
	};

	return (
		<LoadingContext.Provider value={{loaded, showHeader, showFooter, isLoading, isLoaded}}>
			{children}
		</LoadingContext.Provider>
	);
};