import {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useSetState} from "@mantine/hooks";

/**
 * keyState
 *
 * @example
 *
 * navigate(`...`, {state: {...keyState()}});
 */
export function keyState() {
    return {key: Date.now()};
}

/**
 * useStateKey
 *
 * @param stateKeyName
 */
const useStateKey = ({stateKeyName = "key"} = {}) => {

    const location = useLocation();
    const [stateKey, setStateKey] = useState(undefined);

    useEffect(() => {
        setStateKey(location.state?.[stateKeyName] ?? Date.now());
    }, [location]);

    return {
        stateKey,
        location
    }
}

/**
 * useKey
 *
 * @param enabled
 * @param onKeyChange
 * @param stateKeyName
 * @example
 *
 * navigate(`...`, {state: {key: Date.now()}});
 *
 * const {key, stateKey} = useKey({enabled: true, () => {
 *  ...
 * });
 *
 * ...
 */
const useKey = ({enabled = true, isLoading, onKeyChange, stateKeyName = "key"} = {}) => {

    const [key, setKey] = useState(undefined);
    const [random, setRandom] = useState(Math.random());
    const {stateKey} = useStateKey({stateKeyName: stateKeyName});

    useEffect(() => {

        if(enabled && key !== stateKey) {
            // console.debug(
            //     `enabled ${enabled}\n` +
            //     `key ${key}\n` +
            //     `locationKey ${locationKey}\n`
            // )

            if(isLoading) {
                isLoading();
            }

            if(onKeyChange) {
                onKeyChange(key, stateKey)
            }

            setRandom(Math.random());
            setKey(stateKey);
        }

    }, [key, stateKey, enabled, isLoading, onKeyChange, stateKeyName]);

    return {
        enabled,
        key,
        stateKey,
        random
    };
};

export default useKey;