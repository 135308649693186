import React, {useEffect, useState} from "react";
import {Box} from "@mantine/core";

/**
 * SelectionChangeMemo
 */
export const SelectionChangeMemo = React.memo(({ selectionChange, children }) => {

	// Track the last selection change to control rendering
	const [lastSelectionChange, setLastSelectionChange] = useState(undefined);

	// Effect to update the `lastSelectionChange` only when `selectionChange` changes
	useEffect(() => {
		if (selectionChange !== lastSelectionChange) {
			setLastSelectionChange(selectionChange);
		}
	}, [selectionChange, lastSelectionChange]);

	// If the component should not update, return null
	if (selectionChange !== lastSelectionChange) {
		return null;
	}

	return <Box w={"100%"} h={"100%"}>
				{children}
			</Box>
}, (prevProps, nextProps) => {
	// Prevent re-render if `selectionChange` is the same
	return prevProps.selectionChange === nextProps.selectionChange;
});