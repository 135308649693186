import React, {useEffect, useRef} from 'react';
import {Box} from "@mantine/core";
import classes from './YouTubeEmbed.module.css';
import i18n from "../../i18n";

/**
 * YouTubeEmbed
 */
export const YouTubeEmbed = ({ videoId, onPlay, onPause, onStop }) => {

    const playerRef = useRef(null);

    const videoUrl = `https://www.youtube.com/embed/${videoId}?enablejsapi=1&hl=${i18n.language || 'en'}&cc_lang_pref=${i18n.language || 'en'}&cc_load_policy=1&controls=1&disablekb=1`;

    useEffect(() => {
        // Load the YouTube IFrame API if it’s not already loaded
        if (!window.YT) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(tag);

            // Setup callback to initialize the player after the API loads
            window.onYouTubeIframeAPIReady = () => {
                initializePlayer();
            };
        } else {
            initializePlayer();
        }

        // Cleanup
        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    }, [videoId]);

    // Initialize the YouTube player with the given videoId
    const initializePlayer = () => {
        playerRef.current = new window.YT.Player(`youtube-player-${videoId}`, {
            videoId: videoId,
            events: {
                onStateChange: onPlayerStateChange,
            },
        });
    };

    // Handle player state changes
    const onPlayerStateChange = (event) => {
        if (event.data === window.YT.PlayerState.PLAYING && onPlay) {
            onPlay();
        }
        else if (event.data === window.YT.PlayerState.PAUSED && onPause) {
            onPause();
        }
        else if (event.data === window.YT.PlayerState.ENDED && onStop) {
            onStop();
        }
    };

    return (
        <Box className={classes.youtubeembedroot}>
            <iframe
                id={`youtube-player-${videoId}`}
                src={videoUrl}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </Box>
    );
};