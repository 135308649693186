import React, {useState} from 'react';
import {useDisclosure} from "@mantine/hooks";
import {Anchor, Box, Button, Group, Modal, ScrollArea, Space, Stack, Table, Text, Title} from "@mantine/core";
import classes from "./Features.module.css";
import {StripedTable} from "../stripedTable/StripedTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faHandHoldingHeart} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {useFeatures} from "./useFeatures";
import {PaymentCustomerManagement, PaymentSubscription} from "../payment/Payment";
import {useAccountContext} from "../account/AccountContext";
import {daysFromNow} from "../../util/time";
import {SimpleBox} from "../simpleBox/SimpleBox";
import {useEnvironmentContext} from "../environment/EnvironmentContext";

/**
 * FeaturesBanner
 */
export const FeaturesBanner = ({color = "secondary"}) => {

	const {t} = useTranslation();
	const [left] = useState(Math.random() < 0.6);

	const {environment} = useEnvironmentContext();
	const { isAuthenticated, userAccount } = useAccountContext();

	const FeatureIcon = () => <FontAwesomeIcon icon={faHandHoldingHeart} className={classes.featureslinkicon}/>

	return	<FeaturesPlan targetComponent={<Anchor target={"_blank"} underline="never">
							<SimpleBox color={color} highlightOnHover>
								<Group justify={"space-between"} align={"center"}>
									{left && <FeatureIcon />}
									<Stack gap={0} flex={1}>
										<Text flex={1} size={"sm"}>
											{t("common.loginNow")}&nbsp;{t("feature.featurePlans.accountDescription", {trialDays: environment.paymentPremiumTrialDays})}
										</Text>
										<Text>
										</Text>
									</Stack>
									{!left && <FeatureIcon />}
									{/*{left &&*/}
									{/*    <FontAwesomeIcon icon={faUpRightFromSquare} color={`var(--mantine-color-${color}-3)`} style={{width: "18px", height: "18px"}}/>*/}
									{/*}*/}
								</Group>
							</SimpleBox>
						</Anchor>
				}/>
}


/**
 * FeaturesPlan
 */
export const FeaturesPlan = ({targetComponent}) => {

	const [opened, { open, close }] = useDisclosure(false);

	const { isAuthenticated, userAccount } = useAccountContext();

	const { Features, FeaturePlans } = useFeatures();

	const {t} = useTranslation();

	const planKeys = Object.keys(FeaturePlans); // FREE, ACCOUNT, PREMIUM

	const color = userAccount?.isPremium() ? "tertiary" : "secondary";


	/**
	 * getHighestEnabledFeaturePlan
	 */
	const getHighestEnabledFeaturePlan = () => {

		const plans = Object.values(FeaturePlans)
									.filter(plan => plan.enabled)
									.sort((a, b) => b.priority - a.priority);

		return plans.length > 0 ? plans[0] : undefined;
	};

	/**
	 * getPlanKey
	 */
	const getPlanKey = (plan) => {
		return Object.keys(FeaturePlans).find(key => FeaturePlans[key] === plan);
	};

	/**
	 * isPlanIncluded
	 */
	const isPlanIncluded = (featurePlanKey, currentPlanKey) => {

		if (!featurePlanKey || !currentPlanKey) {
			return false;
		}

		return FeaturePlans[currentPlanKey].priority >= FeaturePlans[featurePlanKey].priority;
	};

	/**
	 * PremiumPlanHeader
	 */
	const PremiumPlanHeader = () => {

		return userAccount?.isPremium() ?
			<>
				{userAccount.getProducts().map((product, index) => (
					<Stack gap={"xs"} pt={7} align="center" justify="flex-start">
						<Text inherit wrap={"nowrap"}>{product.name}</Text>
						<Text size={"xs"} pb={"md"}>{t("account.subscriptionExpiration", {days: daysFromNow(product.validTo)})}</Text>
					</Stack>
				))}
				<PaymentCustomerManagement color={color}/>
			</>
			:
			FeaturePlans.PREMIUM.payments && FeaturePlans.PREMIUM.payments.length > 0 &&
				<>
					<Text inherit pt={7} c={FeaturePlans.PREMIUM.enabled ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
						{FeaturePlans.PREMIUM.name}
					</Text>

					{!userAccount?.isPremium() && userAccount?.isPremiumTrial() ?
						<Text size={"xs"} pt={"xs"} fw={700} c={`var(--mantine-color-${color}-6)`}>{t("account.trial", {trialDaysLeft: userAccount.getPremiumTrialDays() - userAccount.getPremiumTrialDaysLeft(), trialDays: userAccount.getPremiumTrialDays()})}</Text>
						:
						<>
							{FeaturePlans.PREMIUM.description && !isAuthenticated &&
								<Text size={"xs"} pt={"xs"} c={FeaturePlans.PREMIUM.enabled ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
									{FeaturePlans.PREMIUM.description}
								</Text>
							}
							<Button.Group gap={2} pt={"xs"} orientation={"vertical"}>
								{FeaturePlans.PREMIUM.payments.map(payment => (
									<PaymentSubscription priceUsd={payment.priceUsd} priceId={payment.priceId} pricePeriod={payment.pricePeriod} disabled={!payment.enabled} color={color}/>
								))}
							</Button.Group>
						</>
					}
				</>
	}

	return (
		<>
			<Modal opened={opened}
				   color={color}
				   centered
				   closeOnClickOutside
				   withCloseButton={false}
				   size={"xl"}
				   overlayProps={{color: `var(--mantine-color-${color}-12)`, backgroundOpacity: 0.75, blur: 7}}
				   zIndex={404}
				   onClose={close}
				   classNames={{
					   // root: classes.modalroot,
					   header: classes.modalheader,
					   // content: classes.modalcontent,
					   // inner: classes.modalinner,
					   body: classes.modalbody,
				   }}
			>
				<Title order={2} p={"md"} pb={"md"} c={`var(--mantine-color-${color}-9)`} flex={1}>{t('feature.plans')}</Title>

				<ScrollArea type={"hover"}
							offsetScrollbars={false}
							w={"100%"}
							h={"70vh"}
							classNames={{
								scrollbar: classes.scrollbar,
								thumb: classes.thumb,
								corner: classes.corner
							}}>
					<StripedTable stickyHeader striped={false} style={{color: `var(--mantine-color-${color}-9)`}} className={classes.table}>
						<Table.Thead>
							<Table.Tr>
								<Table.Th w={"40%"} style={{position: 'sticky', left: 0, backgroundColor: `var(--mantine-color-body)`, zIndex: 1, verticalAlign: "top"}}>
									<Text inherit pt={7}>
										{t("feature.feature")}
									</Text>
								</Table.Th>
								{planKeys.map(planKey => (
									<Table.Th key={planKey} ta={"center"} style={{verticalAlign: "top", backgroundColor: `var(--mantine-color-body)`, width: "200px"}}>
										<Stack gap={0}>
											{planKey.toLowerCase() === FeaturePlans.PREMIUM.id ?
												<PremiumPlanHeader/>
												:
												<>
													<Text inherit pt={7} c={FeaturePlans[planKey].id === getHighestEnabledFeaturePlan().id ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
														{FeaturePlans[planKey].name}
													</Text>
													{FeaturePlans[planKey].description &&
														<Text size={"xs"} pt={"xs"} c={FeaturePlans[planKey].id === getHighestEnabledFeaturePlan().id ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
															{FeaturePlans[planKey].description}
														</Text>
													}
												</>
											}
											<Space h={"md"}/>
										</Stack>
									</Table.Th>
								))}
							</Table.Tr>
						</Table.Thead>
						<Space h={2}/>
						<Table.Tbody>

							{Object.keys(Features).map(categoryKey => {
								const category = Features[categoryKey];
								return (
									<React.Fragment key={categoryKey}>
										<Table.Tr bg={`var(--mantine-color-${color}-light-hover)`}>
											<Table.Td fw={700} style={{position: 'sticky', left: 0, zIndex: 1}}>
												<Group justify="space-between" w={"100%"}>
													<Text inherit>
														{category.name}
													</Text>
													<Text size={"xs"}>
														{category.note}
													</Text>
												</Group>
											</Table.Td>
											{planKeys.map(planKey => (
												<Table.Td key={`${categoryKey}-${planKey}`} ta={"center"}>
												</Table.Td>
											))}
										</Table.Tr>

										{Object.keys(category.features).map(featureKey => {
											const feature = category.features[featureKey];
											return (
												<Table.Tr key={featureKey}>
													<Table.Td pl={"xl"} style={{position: 'sticky', backgroundColor: "var(--mantine-color-body)", left: 0, zIndex: 1 }}>
														<Group justify="space-between" w={"100%"}>
															<Text inherit c={feature.plan.enabled ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
																{feature.name}
															</Text>
															<Text size={"xs"}>
																{feature.note}
															</Text>
														</Group>
													</Table.Td>
													{planKeys.map(planKey => (
														<Table.Td key={`${featureKey}-${planKey}`} ta={"center"} /*bg={feature.plan.enabled ? "var(--mantine-color-green-outline-hover)" : "var(--mantine-color-red-outline-hover)"}*/>
															{/*{isPlanIncluded(getPlanKey(feature.plan), planKey) && (*/}
															{/*	feature.plan.enabled ?*/}
															{/*			<FontAwesomeIcon icon={faCircle} size={"xs"} style={{color: `var(--mantine-color-${color}-6)`}}/>*/}
															{/*		:*/}
															{/*			<FontAwesomeIcon icon={faCircle} size={"xs"} style={{color: `var(--mantine-color-${color}-9)`}}/>*/}
															{/*)}*/}
															{isPlanIncluded(getPlanKey(feature.plan), planKey) && (
																planKey.toLowerCase() === getHighestEnabledFeaturePlan().id ?
																		<FontAwesomeIcon icon={faCircle} size={"xs"} style={{color: `var(--mantine-color-${color}-6)`}}/>
																	:
																		<FontAwesomeIcon icon={faCircle} size={"xs"} style={{color: `var(--mantine-color-${color}-9)`}}/>
															)}
														</Table.Td>
													))}
												</Table.Tr>
											);
										})}
									</React.Fragment>
								);
							})}
							<Space h={"lg"}/>
						</Table.Tbody>
					</StripedTable>
				</ScrollArea>
			</Modal>

			<Box onClick={open}>
				{targetComponent}
			</Box>
		</>
	);
}