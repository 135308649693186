import React from 'react';
import {Table} from '@mantine/core';
import classes from './StripedTable.module.css';

/**
 * StripedTable
 *
 * @param props
 * @returns {Element}
 */
export const StripedTable = (props) => {

    const {striped= true, stripedColor, highlightOnHover, highlightOnHoverColor, stickyHeader = false, stickyHeaderOffset = 0, captionSide, withRowBorders = false, withColumnBorders = false, children} = props;

    return (
        <Table className={classes.table}
               {...props}
               withRowBorders={withRowBorders}
               withColumnBorders={withColumnBorders}
               stripedColor={`var(--mantine-color-${stripedColor ? stripedColor : "primary"}-outline-hover)`}
               highlightOnHover={highlightOnHover}
               highlightOnHoverColor={`var(--mantine-color-${highlightOnHoverColor ? highlightOnHoverColor : "primary"}-light-hover)`}
               stickyHeader={stickyHeader}
               stickyHeaderOffset={stickyHeaderOffset}
               striped={striped}
               captionSide={captionSide}>

            {children}
        </Table>
    );
}