import React, {useState} from 'react';
import {
    Anchor,
    Box, Button,
    Card, Center,
    Container,
    Divider,
    Group,
    Image, Overlay,
    Stack,
    Text,
    Transition
} from "@mantine/core";
import {stringToImageSrc} from "../../util/utils";
import {localized} from "../../i18n";
import {useNavigate} from "react-router-dom";
import useKey, {keyState} from "../useKey";
import useRecipeImage from "./useRecipeImage";
import useResult from "../useResult";
import classes from './RecipeLink.module.css';
import {formatIsoDuration} from "../../util/time";
import {faChevronRight, faClock, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Icon} from "../../components/icons/Icons";
import {useTranslation} from "react-i18next";
import {RecipeAuthorUser, RecipeDiet, RecipeNew, sortedCategories} from "./RecipeUtil";
import useUser from "../useUser";
import {Settings, useAccountContext} from "../../components/account/AccountContext";
import {faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons/faHeart";
import {faHeart} from "@fortawesome/free-regular-svg-icons/faHeart";
import {useFeatures} from "../../components/features/useFeatures";

/**
 * RecipeIconTitle
 */
export const RecipeIconTitle = (props) => {

    const {textKey = 'common.recipes', color = "var(--mantine-color-white)", withIcon = true} = props;

    const { t } = useTranslation();

    return <Group gap="2" wrap={"nowrap"} {...props}>
        {!withIcon ? null :
            <Icon name={"hat-chef"} style={{width: "18px", height: "18px", marginBottom: "-2px", fill: color}}/>
        }
        <Text pl={withIcon ? 4 : 0} size={"sm"} fw={700}>{t(textKey)}</Text>
    </Group>
}

/**
 * RecipeGptLink
 */
export const RecipeGptLink = (props) => {

    const { t } = useTranslation();

    return  <Button size='xl' variant='filled' color="secondary"
                    justify="space-between" fullWidth
                    rightSection={<FontAwesomeIcon icon={faUpRightFromSquare} style={{color: "var(--mantine-color-white)"}} size={"sm"}/>}
                    leftSection={<Icon name={"gpt"} style={{width: "38px", height: "38px", marginLeft: "-8px", fill: "var(--mantine-color-white)"}} />}
                    component="a" target="_blank" href="https://chatgpt.com/g/g-FDauaPnt4-cuckookitchen-ai"
                    {...props}>

        <Box style={{ display: 'flex', overflow: 'hidden', width: '100%' }}>
            <Text size="sm" c="white" w="100%" ta={"left"} style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                {t("recipe.actionCallGpt")}
            </Text>
        </Box>
    </Button>
}

/**
 * Image169WithZoom
 */
const Image169WithZoom = ({src, recipe, user, showCategory, hovered}) => {

    const { isAuthenticated, userAccount, updateUserAccountSettings } = useAccountContext();
    const [settings] = useState(isAuthenticated ? userAccount.getSettings() : new Settings({}));
    const [favorite, setFavorite] = useState(settings.isFavoriteRecipe(recipe.recipeId));
    const { Features } = useFeatures();

    const {t} = useTranslation();

    /**
     * handleFavorite
     */
    function handleFavorite() {
        settings.setFavoriteRecipe(recipe.recipeId);
        updateUserAccountSettings(userAccount);
        setFavorite(settings.isFavoriteRecipe(recipe.recipeId));
    }

    return (
        <Box className={classes.recipelinkimage169root}>
            <Transition
                mounted={true}
                transition="scale"
                timingFunction="ease-in-out">
                {(styles) => (
                    <>
                        <Image
                            src={src}
                            className={classes.recipelinkimage169image}
                            style={{
                                transform: hovered? 'scale(1.1)' : 'scale(1)',  // Scale on hover
                                transition: 'transform 0.15s ease-in-out',  // Smooth transition
                            }}
                            withPlaceholder
                        />
                        <RecipeNew recipe={recipe} className={classes.recipelinkimage169new}/>
                        <RecipeAuthorUser user={user} className={classes.recipelinkimage169avatar} />
                    </>
                )}
            </Transition>

            <Container className={classes.recipelinkimage169overlay} p={"md"}>
                <Overlay color="var(--mantine-color-primary-9)" backgroundOpacity={!hovered ? 0.8 : 0.95} blur={0} zIndex={1}/>
                <Group gap={0} justify="space-between" wrap={"nowrap"} className={classes.recipelinkimage169title}>
                    <Group align="flex-start" gap={6} wrap={"nowrap"} >

                        {Features.account.features.recipeFavorites.plan.enabled &&
                            <FontAwesomeIcon icon={settings.isFavoriteRecipe(recipe.recipeId) ? faHeartSolid : faHeart} size={"md"} color={"white"} opacity={0.25}
                                             style={{height: "18px", paddingTop: "4px"}}
                                             onClick={(event) => {event.stopPropagation(); event.preventDefault(); handleFavorite()}}/>
                        }

                        <Stack gap={0}>
                            <Text c={"white"} lineClamp={1} pb={4}>
                                {localized(recipe, 'name')}
                            </Text>
                            {showCategory ?
                                <Text c={"white"} size={'xs'} opacity={0.75}>
                                    {sortedCategories(recipe.categories)
                                        .map((category, index) => localized(category, 'name'))
                                        .join(" / ")}
                                </Text>
                                :
                                // <Text c={"white"} size={'xs'} opacity={0.75}>
                                //     &nbsp;
                                // </Text>
                                null
                            }
                        </Stack>
                    </Group>
                    <FontAwesomeIcon icon={faChevronRight} color={"white"} />
                </Group>
            </Container>
        </Box>
    );
};

/**
 * RecipeLink
 *
 * @param recipe
 * @param recipeTarget
 * @param recipeHighlight
 * @param showCategory
 * @param categoryTarget
 * @returns {Element}
 * @constructor
 */
const RecipeLink = ({recipe, recipeTarget = "_self", recipeHighlight,
                     showCategory = true, categoryTarget = "_self"}) => {

    const [hovered, setHovered] = useState(false);

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {key, stateKey} =
        useKey({
            onKeyChange: () => {
                console.debug(`RecipeLink`);
                refetchItem();
                refetchUser();
            }
        });

    const { data: dataItem, isLoaded: isLoadedItem,
            reset: resetItem, refetch: refetchItem} =
        useRecipeImage({
            recipeId: recipe.recipeId
        })

    const { data: dataUser, isLoaded: isLoadedUser,
        reset: resetUser, refetch: refetchUser} =
        useUser({
            userId: recipe.author
        })

    const {isSuccess, isError} =
        useResult({
            isSuccess: isLoadedItem && isLoadedUser
        })

    return (!isSuccess ? null :

        <Anchor target={recipeTarget} underline="never"
                onClick={() => recipeNavigate(navigate, recipe)}
                className={classes.recipelinkroot}
                >
            <Card shadow="none" padding="0"
                  // withBorder style={{border: "2px solid var(--mantine-color-primary-light-hover)"}}
                    style={{height: "100%"}}
                  bg={hovered ? "var(--mantine-color-primary-light-hover)" : "var(--mantine-color-primary-outline-hover)"}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
            >
                <Card.Section>
                    <Image169WithZoom src={stringToImageSrc(dataItem)} hovered={hovered} recipe={recipe} user={dataUser} showCategory={showCategory}/>
                </Card.Section>

                <Stack align="stretch" justify="space-between" gap={0} flex={1}>

                    <Box p={"md"} pb={0}>
                        <Text c={"var(--mantine-color-primary-9)"} size="sm" lineClamp={3}>
                            {localized(recipe, 'description')}
                        </Text>
                        {/*<Text c={"var(--mantine-color-primary-9)"} size={"sm"} pt={"xs"}>{t("recipe.author", {author: dataUser.name})}</Text>*/}
                    </Box>

                    <Box pt={"lg"}>
                        <Center pl={"md"} pr={"md"} pb={"md"}>
                            <RecipeDiet recipe={recipe}/>
                        </Center>

                        <Group align="flex-start" justify="space-between" gap="0" p={"md"} pt={0}>
                            <Stack align="stretch" justify="center" gap="4" w={"33%"}>
                                <Group justify="center" gap="6">
                                    <FontAwesomeIcon icon={faClock} style={{color: "var(--mantine-color-primary-9)"}}/>
                                    <Text c={"var(--mantine-color-primary-9)"} ta={"center"}>{formatIsoDuration(recipe.totalTime)}</Text>
                                </Group>
                                <Text c={"var(--mantine-color-primary-9)"} ta={"center"} size={'xs'} opacity={0.75}>
                                    {t("recipe.totalTime")}
                                </Text>
                            </Stack>
                            <Divider w={1} orientation="vertical" color={"var(--mantine-color-primary-9)"} opacity={0.1}/>
                            {recipe.difficulty === undefined ? <Stack w={"33%"}/> :
                                <Stack align="stretch" justify="center" gap="4" w={"33%"}>
                                    <Group justify="center" gap="6">
                                        <Icon name={recipe.difficulty} style={{width: "18px", height: "18px", fill: "var(--mantine-color-primary-9)", marginBottom: "-2px"}}/>
                                    </Group>
                                    <Text c={"var(--mantine-color-primary-9)"} ta={"center"} size={'xs'} opacity={0.75}>
                                        {t("recipe.difficulty")}
                                    </Text>
                                </Stack>
                            }
                            <Divider w={1} orientation="vertical" color={"var(--mantine-color-primary-9)"} opacity={0.1}/>
                            <Stack align="stretch" justify="center" gap="4" w={"33%"}>
                                <Group justify="center" gap="6">
                                    <Icon name={"leaf"} style={{width: "18px", height: "18px", marginBottom: "-5px", fill: "var(--mantine-color-primary-9)"}}/>
                                    <Text c={"var(--mantine-color-primary-9)"} ta={"center"}>{recipe.ingredientsCount}</Text>
                                </Group>
                                <Text c={"var(--mantine-color-primary-9)"} ta={"center"} size={'xs'} opacity={0.75}>
                                    {t("common.ingredients")}
                                </Text>
                            </Stack>
                        </Group>
                    </Box>
                </Stack>
            </Card>
        </Anchor>
    );
};

/**
 * recipeNavigate
 *
 * @returns {React.JSX.Element|undefined}
 * @constructor
 */
export function recipeNavigate(navigate, recipe) {

    if(!recipe) {
        return;
    }

    navigate(`/recipe/${recipe.recipeId}`, {state: {...keyState()}});
}

export default RecipeLink;