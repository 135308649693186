import {createContext, useCallback, useContext, useState} from 'react';

/**
 * DnDContext
 */
const DnDContext = createContext([null, (_) => {}]);

/**
 * useDnDContext
 */
export const useDnDContext = () => {
	return useContext(DnDContext);
}

/**
 * DnDProvider
 */
export const DnDProvider = ({ children }) => {

	const [node, setNode] = useState(undefined);
	const [edge, setEdge] = useState(undefined);

	/**
	 * reset
	 */
	const reset = useCallback(() => {
			setNode(undefined);
			setEdge(undefined);
		},
		[],
	);

	return (
		<DnDContext.Provider value={[node, setNode, edge, setEdge, reset]}>
			{children}
		</DnDContext.Provider>
	);
}