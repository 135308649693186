import {alpha, createTheme, rgba} from '@mantine/core';

// TODO Check https://omatsuri.app/color-shades-generator for shaders (darken: 10%, saturation: -20%),

// https://mantine.dev/theming/default-theme/



/**
 * @type {MantineThemeOverride}
 */
export const theme= createTheme({
    scale: 1,
    fontSmoothing: true,
    focusRing: "auto",
    white: '#FAF8FA',
    black: '#94397E',
    colors: {
        // shocking_pink
        primary: [
            '#FAF8FA',
            '#EADAE7',
            '#DFBCD7',
            '#D99DCB',
            '#D97BC3',
            '#E055BF',
            '#EF2AC1',
            '#D527AD',
            '#B03393',
            '#94397E',
            '#7D3C6D',
            '#6A3C5F',
            '#5C3A54'
        ],
        // giants_orange
        secondary: [
            '#FEFDFD',
            '#EEE3DE',
            '#E4C9BF',
            '#DFB09D',
            '#E19779',
            '#EB7C4F',
            '#FF5F1F',
            '#E6551C',
            '#BE542A',
            '#9E5133',
            '#844D37',
            '#704939',
            '#604338'
        ],
        // picton_blue
        tertiary: [
            '#C7D8DE',
            '#ABC9D4',
            '#8DBDCF',
            '#6DB5D0',
            '#49B0D7',
            '#20B0E6',
            '#00ADEE',
            '#1592C1',
            '#237C9E',
            '#2A6B83',
            '#2E5D6E',
            '#2F515D',
            '#2F4750'
        ],
        // amber
        alternative1: [
            '#EAE6DA',
            '#DED5BD',
            '#D7C99F',
            '#D5BF7E',
            '#DBBA5A',
            '#E8BA30',
            '#FFBF00',
            '#CFA017',
            '#A98825',
            '#8D752D',
            '#766531',
            '#645833',
            '#564D32'
        ],
        // blue_munsell
        alternative2: [
            '#97B4B8',
            '#7FABB1',
            '#67A4AD',
            '#4EA1AD',
            '#3D9AA8',
            '#2B95A5',
            '#1992A4',
            '#237A87',
            '#296771',
            '#2B585F',
            '#2B4C51',
            '#2A4245',
            '#29393C'
        ],
    },
    primaryShade: {
        light: 6,
        dark: 8
    },
    primaryColor: 'primary',
    autoContrast: false,
    luminanceThreshold: 0.3,
    fontFamily: 'Noto Sans',
    fontFamilyMonospace: "ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace",
    respectReducedMotion: false,
    cursorType: 'pointer',
    defaultGradient: {
        from: "primary",
        to: "secondary",
        deg: 45
    },
    // defaultRadius: "sm",
    defaultRadius: "md",
    // activeClassName: "mantine-active",
    activeClassName: "",
    focusClassName: "",
    headings: {
        fontFamily: 'Noto Serif',
        fontWeight: 400,
        textWrap: "wrap",
        sizes: {
            h1: {
                fontSize: "calc(2.625rem * var(--mantine-scale))",
                lineHeight: "1.3"
            },
            h2: {
                fontSize: "calc(1.625rem * var(--mantine-scale))",
                lineHeight: "1.35"
            },
            h3: {
                fontSize: "calc(1.375rem * var(--mantine-scale))",
                lineHeight: "1.4"
            },
            h4: {
                fontSize: "calc(1.125rem * var(--mantine-scale))",
                lineHeight: "1.45"
            },
            h5: {
                fontSize: "calc(1rem * var(--mantine-scale))",
                lineHeight: "1.5"
            },
            h6: {
                fontSize: "calc(0.875rem * var(--mantine-scale))",
                lineHeight: "1.5"
            }
        }
    },
    fontSizes: {
        xs: "calc(0.75rem * var(--mantine-scale))",
        sm: "calc(0.875rem * var(--mantine-scale))",
        md: "calc(1rem * var(--mantine-scale))",
        lg: "calc(1.125rem * var(--mantine-scale))",
        xl: "calc(1.25rem * var(--mantine-scale))"
    },
    lineHeights: {
        xs: "1.4",
        sm: "1.45",
        md: "1.55",
        lg: "1.6",
        xl: "1.65"
    },
    radius: {
        xs: "calc(0.125rem * var(--mantine-scale))",
        sm: "calc(0.25rem * var(--mantine-scale))",
        md: "calc(0.5rem * var(--mantine-scale))",
        lg: "calc(1rem * var(--mantine-scale))",
        xl: "calc(2rem * var(--mantine-scale))"
    },
    spacing: {
        xs: "calc(0.625rem * var(--mantine-scale))",
        sm: "calc(0.75rem * var(--mantine-scale))",
        md: "calc(1rem * var(--mantine-scale))",
        lg: "calc(1.25rem * var(--mantine-scale))",
        xl: "calc(2rem * var(--mantine-scale))"
    },
    breakpoints: {
        xs: "36em",
        sm: "48em",
        md: "62em",
        lg: "75em",
        xl: "88em"
    },
    shadows: {
        xs: "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(92, 58, 84, 0.05), 0 calc(0.0625rem * var(--mantine-scale)) calc(0.125rem * var(--mantine-scale)) rgba(0, 0, 0, 0.1)",
        sm: "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(92, 58, 84, 0.05), rgba(92, 58, 84, 0.30) 0 calc(0.625rem * var(--mantine-scale)) calc(0.9375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(92, 58, 84, 0.04) 0 calc(0.4375rem * var(--mantine-scale)) calc(0.4375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))",
        md: "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(92, 58, 84, 0.05), rgba(92, 58, 84, 0.05) 0 calc(1.25rem * var(--mantine-scale)) calc(1.5625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(92, 58, 84, 0.04) 0 calc(0.625rem * var(--mantine-scale)) calc(0.625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))",
        lg: "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(92, 58, 84, 0.05), rgba(92, 58, 84, 0.05) 0 calc(1.75rem * var(--mantine-scale)) calc(1.4375rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(92, 58, 84, 0.04) 0 calc(0.75rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))",
        xl: "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(92, 58, 84, 0.05), rgba(92, 58, 84, 0.05) 0 calc(2.25rem * var(--mantine-scale)) calc(1.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(92, 58, 84, 0.04) 0 calc(1.0625rem * var(--mantine-scale)) calc(1.0625rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))"
    },
    other: {},
    components: {
        Container: {
            defaultProps: {
                size: 'lg',
            },
        },
    },
    custom: {
        mediaQuery: {
            xs: "(max-width: 36em)",
            sm: "(max-width: 48em)",
            md: "(max-width: 62em)",
            lg: "(max-width: 75em)",
            xl: "(max-width: 88em)",
        },
        ads: {
            active: true
        },
        components: {
            carousel: {
                defaultInterval: 10000
            },
            languageselector: {
                tooltipEnTimeoutMs: 5000
            },
            loader: {
                timeoutMs: 300,
                timeoutMaxMs: 5000
            },
            header: {
                height: "68px",
                heightPx: 68,
                searchTextIntervalMs: 10000
            },
            page: {
                // defaultSlidesHeight: 'calc(62vh + 200px)',
                defaultSlidesHeight: '62vh', // Sezione aurea
                noSlidesHeight: '0px',
                noSlidesImageHeight: '200px',
            },
            footer: {
                height: "360px",
                heightMobile: "160px"
            },
            tab: {
                height: "54px"
            },
            sponsor: {
                randomSponsors: 10
            }
        },
        home: {
            randomIngredients: 2,
            randomRecipes: 2,
            randomLinkedIngredients: 2,
            randomUtensils: 2,
        },
        ingredient: {
            paginationSize: 12
        },
        molecule: {
            paginationSize: 12
        },
        recipe: {
            paginationSize: 12,
            isNewInDays: 14
        },

        myFunction: function() {
          return Math.random();
        },
        deepOrangeLight: '#FFDDAA',
        deepOrangeDark: '#FFFFFF',
    }
});

// TODO check Virtual colors!!!!!!

export const cssVariablesResolver = (theme) => ({
    variables: {
        '--mantine-custom-components-loader-timeoutMs': theme.custom.components.loader.timeoutMs,
        '--mantine-custom-components-header-height': theme.custom.components.header.height,
        '--mantine-custom-components-footer-height': theme.custom.components.footer.height,
        '--mantine-custom-components-footer-heightMobile': theme.custom.components.footer.heightMobile,
        '--mantine-custom-components-tab-noSlidesImageHeight': theme.custom.components.tab.noSlidesImageHeight,
        '--mantine-custom-color-body-light-hover': rgba(theme.white, 0.65),
    },
    light: {
        '--mantine-custom-color-deep-orange': theme.custom.deepOrangeLight,
    },
    dark: {
        '--mantine-custom-color-deep-orange': theme.custom.deepOrangeDark,
    },
});