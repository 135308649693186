import React, {useEffect, useState} from "react";
import {
    Anchor,
    Box,
    Button, Center,
    Checkbox,
    Group,
    Modal,
    Paper,
    Stack,
    Text,
    TextInput, Title
} from '@mantine/core';
import classes from './Sponsor.module.css';
import useKey from "../../pages/useKey";
import useSponsorAll from "./useSponsorAll";
import {theme} from "../../Theme";
import {CustomCarousel} from "../carousel/CustomCarousel";
import {useDisclosure} from "@mantine/hooks";
import {useTranslation} from "react-i18next";
import {useForm} from "@mantine/form";
import useSendMail from "../../pages/useSendMail";
import useMedia from "../../pages/useMedia";
import {SimpleBox} from "../simpleBox/SimpleBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandHoldingHeart, faMedal} from "@fortawesome/free-solid-svg-icons";

const slideMaxWidth = 100;

/**
 * SponsorsBanner
 */
export const SponsorsBanner = ({color = "secondary"}) => {

    const {t} = useTranslation();
    const [left] = useState(Math.random() < 0.6);

    const SponsorsIcon = () => <FontAwesomeIcon icon={faMedal} className={classes.sponsorslinkicon}/>

    return	<SponsorsModal targetComponent={<Anchor target={"_blank"} underline="never">
        <SimpleBox color={color} highlightOnHover>
            <Group justify={"space-between"} align={"center"}>
                {left && <SponsorsIcon />}
                <Stack gap={0} flex={1}>
                    <Text flex={1} size={"sm"}>
                        {t("sponsor.sponsoringDescription")}
                    </Text>
                </Stack>
                {!left && <SponsorsIcon />}
                {/*{left &&*/}
                {/*    <FontAwesomeIcon icon={faUpRightFromSquare} color={`var(--mantine-color-${color}-3)`} style={{width: "18px", height: "18px"}}/>*/}
                {/*}*/}
            </Group>
        </SimpleBox>
    </Anchor>
    }/>
}

/**
 * SponsorsModal
 */
const SponsorsModal = ({section = "*", targetColor = "", targetComponent = undefined}) => {

    const [opened, { close, open }] = useDisclosure(false);

    const { isSm } = useMedia();

    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");

    const {t} = useTranslation();

    const {reset: resetSendEmail, refetch: refetchSendEmail} =
        useSendMail({
            subject: subject,
            body: body
        })

    useEffect(() => {

        if(subject && body) {
            refetchSendEmail();
        }

    }, [subject, body]);

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            companyUrl: "",
            email: "",
            sectionAll: section.toLowerCase() === "*",
            sectionHome: section.toLowerCase() === "home",
            sectionSearch: section.toLowerCase() === "search",
            sectionIngredients: section.toLowerCase() === "entitycategory",
            sectionIngredient: section.toLowerCase() === "ingredient",
            sectionSingleIngredient: section.toLowerCase() === "ingredient",
            sectionMolecules: section.toLowerCase() === "molecules",
            sectionMolecule: section.toLowerCase() === "molecule",
            sectionRecipes: section.toLowerCase() === "recipecategory",
            sectionRecipe: section.toLowerCase() === "recipe",
            sectionSingleRecipe: section.toLowerCase() === "recipe",
        },
        validate: {
            companyUrl: (value) => value ? null : t("common.fieldRequired"),
            email: (value) => value ? null : t("common.fieldRequired"),
        },
    });

    /**
     * closeNow
     */
    const closeNow = () => {

        setTimeout(function() {
            form.reset();
            setSubject("");
            setBody("");
        },100);

        close();
    }

    return <>
        <Modal opened={opened}
               onClose={closeNow}
               closeOnClickOutside
               withCloseButton={false}
               centered
               size={"lg"}
               title={<Title order={2}>{t("sponsor.sponsoring")}</Title>}
               overlayProps={{color: "var(--mantine-color-secondary-12)", backgroundOpacity: 0.75, blur: 7}}
               zIndex={404}
               classNames={{
                   root: classes.modalroot,
                   header: classes.modalheader,
                   content: classes.modalcontent,
                   inner: classes.modalinner,
                   body: classes.modalbody,
               }}>

            <Text>
                {t("sponsor.sponsoringDescription")}
            </Text>

            <form onSubmit={form.onSubmit((values) => {
                setSubject(`[SPONSORING] ${values.companyUrl}`);
                setBody(JSON.stringify(values, null, 4));
                closeNow();
            })}>
                <SimpleBox color={"secondary"} pt={"lg"}>
                    <Stack gap={"xs"} >
                        <TextInput
                            label={t("sponsor.companyUrl")}
                            placeholder={t("sponsor.companyUrl")}
                            key={form.key('companyUrl')}
                            {...form.getInputProps('companyUrl')}
                            classNames={{
                                input: classes.sponsorinputinput,
                                label: classes.sponsorinputlabel
                            }}
                        />
                        <TextInput
                            label={t("sponsor.email")}
                            placeholder={t("sponsor.email")}
                            key={form.key('email')}
                            {...form.getInputProps('email')}
                            classNames={{
                                input: classes.sponsorinputinput,
                                label: classes.sponsorinputlabel
                            }}
                        />
                    </Stack>
                </SimpleBox>

                <SimpleBox color={"secondary"} pt={"md"}>
                    <Stack gap={"xs"}>
                        <Checkbox
                            label={t("sponsor.sectionAll")}
                            size={"sm"} radius={"sm"} color={"secondary"}
                            classNames={{
                                input: classes.sponsorcheckboxinput,
                            }}
                            key={form.key('sectionAll')}
                            {...form.getInputProps('sectionAll', { type: 'checkbox' })}
                        />
                        <Checkbox
                            label={t("sponsor.sectionHome")}
                            size={"sm"} radius={"sm"} color={"secondary"}
                            classNames={{
                                input: classes.sponsorcheckboxinput,
                            }}
                            key={form.key('sectionHome')}
                            {...form.getInputProps('sectionHome', { type: 'checkbox' })}
                        />
                        <Checkbox
                            label={t("sponsor.sectionSearch")}
                            size={"sm"} radius={"sm"} color={"secondary"}
                            classNames={{
                                input: classes.sponsorcheckboxinput,
                            }}
                            key={form.key('sectionSearch')}
                            {...form.getInputProps('sectionSearch', { type: 'checkbox' })}
                        />
                        <Checkbox
                            label={t("sponsor.sectionIngredients")}
                            size={"sm"} radius={"sm"} color={"secondary"}
                            classNames={{
                                input: classes.sponsorcheckboxinput,
                            }}
                            key={form.key('sectionIngredients')}
                            {...form.getInputProps('sectionIngredients', { type: 'checkbox' })}
                        />
                        <Checkbox
                            label={t("sponsor.sectionIngredient")}
                            size={"sm"} radius={"sm"} color={"secondary"}
                            classNames={{
                                input: classes.sponsorcheckboxinput,
                            }}
                            key={form.key('sectionIngredient')}
                            {...form.getInputProps('sectionIngredient', { type: 'checkbox' })}
                        />
                        <Checkbox
                            label={t("sponsor.sectionSingleIngredient")}
                            size={"sm"} radius={"sm"} color={"secondary"}
                            classNames={{
                                input: classes.sponsorcheckboxinput,
                            }}
                            key={form.key('sectionSingleIngredient')}
                            {...form.getInputProps('sectionSingleIngredient', { type: 'checkbox' })}
                        />
                        <Checkbox
                            label={t("sponsor.sectionMolecules")}
                            size={"sm"} radius={"sm"} color={"secondary"}
                            classNames={{
                                input: classes.sponsorcheckboxinput,
                            }}
                            key={form.key('sectionMolecules')}
                            {...form.getInputProps('sectionMolecules', { type: 'checkbox' })}
                        />
                        <Checkbox
                            label={t("sponsor.sectionRecipes")}
                            size={"sm"} radius={"sm"} color={"secondary"}
                            classNames={{
                                input: classes.sponsorcheckboxinput,
                            }}
                            key={form.key('sectionRecipes')}
                            {...form.getInputProps('sectionRecipes', { type: 'checkbox' })}
                        />
                        <Checkbox
                            label={t("sponsor.sectionRecipe")}
                            size={"sm"} radius={"sm"} color={"secondary"}
                            classNames={{
                                input: classes.sponsorcheckboxinput,
                            }}
                            key={form.key('sectionRecipe')}
                            {...form.getInputProps('sectionRecipe', { type: 'checkbox' })}
                        />
                        <Checkbox
                            label={t("sponsor.sectionSingleRecipe")}
                            size={"sm"} radius={"sm"} color={"secondary"}
                            classNames={{
                                input: classes.sponsorcheckboxinput,
                            }}
                            key={form.key('sectionSingleRecipe')}
                            {...form.getInputProps('sectionSingleRecipe', { type: 'checkbox' })}
                        />
                    </Stack>
                </SimpleBox>

                <Group justify={!isSm ? "flex-end" : "space-between"} pt={"md"}>
                    <Button color={"secondary"} miw={140} onClick={closeNow}>{t("common.cancel")}</Button>
                    <Button color={"secondary"} miw={140} type="submit">{t("sponsor.send")}</Button>
                </Group>
            </form>

        </Modal>

        <Box onClick={open}>
            {targetComponent ? targetComponent :
                <Group justify="center">
                    <Button
                        leftSection={<FontAwesomeIcon icon={faMedal} style={{width: "18px", height: "18px", fill: targetColor}}/>}
                        color={targetColor}
                        variant={"subtle"} size={"compact-xs"} fw={400}>
                        {t("sponsor.cta")}
                    </Button>
                </Group>
            }
        </Box>
    </>
}

/**
 * Sponsors
 */
export const Sponsors = ({section = "*", id = undefined}) => {

    const {key, stateKey} =
        useKey({
            onKeyChange: () => {
                refetchItems();
            }
        });

    const { data: dataItems, isLoaded: isLoadedItems, totalCount: totalCountItems,
        reset: resetItems, refetch: refetchItems} =
        useSponsorAll({
            random: theme.custom.components.sponsor.randomSponsors,
            section: section,
            id: id
        })

    /**
     * DataItemsCarousel
     */
    const DataItemsCarousel = () => {

        return (
            <CustomCarousel interval = {0}
                            withControls={false}
                            withIndicators={false}
                            slideSize={"20%"}
                            dragFree
                            slideGap={"xs"}
            >
                {dataItems?.map((item, index) =>
                    <CustomCarousel.Slide>
                        <Center h={"100%"}>
                            <Anchor target={"_blank"} underline="never"
                                    href={item.sponsorId} ta={"center"}>
                                    <Stack align="stretch" justify="center" w={slideMaxWidth} h={"100%"}>
                                        <Box dangerouslySetInnerHTML={{ __html: item.picture }} className={classes.sponsorpicture} w={slideMaxWidth} />
                                        {/*<Text size={"xs"} c={"gray"}>{index} - {item.name}</Text>*/}
                                    </Stack>
                            </Anchor>
                        </Center>
                    </CustomCarousel.Slide>
                )}
            </CustomCarousel>
        )
    }

    return isLoadedItems &&
        <Paper shadow={"none"} m={"md"} style={{overflow: "hidden"}}>
            <Stack align={"center"} justify="center" gap={dataItems.length > 0 ? "xs" : 0}>
                <DataItemsCarousel/>
                <SponsorsModal section={section} targetColor={dataItems.length > 0 ? "var(--mantine-color-gray-6)" : "var(--mantine-color-secondary-6)"}/>
            </Stack>
        </Paper>
}