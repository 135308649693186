import useKey from "../useKey";
import useUtensilAll from "./useUtensilAll";
import {theme} from "../../Theme";
import {CustomCarousel} from "../../components/carousel/CustomCarousel";
import {AmazonLink} from "../../components/amazon/Amazon";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import {Center, Group, Stack, Text} from "@mantine/core";
import {Icon} from "../../components/icons/Icons";
import classes from './UtensilLink.module.css';
import {useTranslation} from "react-i18next";
import useMedia from "../useMedia";

/**
 * Utensils
 */
export const Utensils = () => {

	const { t } = useTranslation();

	const { isSm } = useMedia();

	const {key, stateKey} =
		useKey({
			onKeyChange: () => {
				refetchItems();
			}
		});

	const { data: dataItems, isLoaded: isLoadedItems, totalCount: totalCountItems,
		reset: resetItems, refetch: refetchItems} =
		useUtensilAll({
			random: theme.custom.home.randomUtensils,
		})

	/**
	 * DataItemsCarousel
	 */
	const DataItemsCarousel = () => {

		return (
			<CustomCarousel interval = {0}
							color={"secondary"}
							withControls={false}
							withIndicators={false}
							slideSize={{ base: '100%', sm: '50%'}}
							slidesToScroll={{ base: 1, sm: 2}}
							slideGap={"md"}>
				{dataItems?.map((item, index) =>
					<CustomCarousel.Slide>
						<AmazonLink utensil={item} showDescription/>
					</CustomCarousel.Slide>
				)}
			</CustomCarousel>
		)
	}

	return !isLoadedItems ? null :
		<SimpleBox color={"secondary"}>
			{!isSm ?
				<Group justify={"space-between"} align={"center"}>
					<Icon name={"utensil"} className={classes.utensilicon}/>
					<DataItemsCarousel/>
				</Group>
				:
				<Stack>
					<Center>
						<Icon name={"utensil"} className={classes.utensilicon} style={{width: "60px"}}/>
					</Center>
					<DataItemsCarousel/>
				</Stack>
			}
			<Center pt={"md"}>
				<Text size={"xs"}>{t("recipe.utensilsDescription")}</Text>
			</Center>
		</SimpleBox>
}