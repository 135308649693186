import React from 'react';
import {Group, Loader} from '@mantine/core';

/**
 * CustomLoader
 *
 * @returns {Element}
 */
export const CustomLoader = ({justify}) => {

    return (
        <Group justify={justify}>
            <Loader color="white" type="dots" size={18}/>
        </Group>
    );
}