import React from 'react';
import {
    Box,
    Menu, Overlay
} from "@mantine/core";
import classes from './CustomMenu.module.css';

/**
 * CustomMenu
 */
export const CustomMenu = (props) => {

    const {children, offset = 21} = props;

    return (
        <Menu trigger={"click"} offset={offset} withArrow arrowPosition="center" arrowSize={16} zIndex={"calc(var(--mantine-z-index-overlay) + 1)"}
              shadow={"sm"} transitionProps={{ duration: 0 }}
              classNames={{
                  dropdown: classes.menudropdown,
                  arrow: classes.menuarrow,
                  item: classes.menuitem
              }}>
            {children}
        </Menu>
    )
}

/**
 * CustomMenuTarget
 */
const CustomMenuTarget = (props) => {

    const {children} = props;

    return (
        <Menu.Target {...props}>
            {children}
        </Menu.Target>
    )
}

CustomMenu.Target = CustomMenuTarget;

/**
 * CustomMenuDropdown
 */
const CustomMenuDropdown = (props) => {

    const {children, withOverlay = false } = props;

    return (
        <Menu.Dropdown {...props}>
            <Overlay className={classes.menudropdownoverlay} color="var(--mantine-color-secondary-6)" backgroundOpacity={withOverlay ? 0.30 : 1} blur={withOverlay ? 14 : 0}/>
            <Box className={classes.menudropdowncontent}>
                {children}
            </Box>
        </Menu.Dropdown>
    )
}

CustomMenu.Dropdown = CustomMenuDropdown;

/**
* CustomMenuItem
*/
const CustomMenuItem = (props) => {

    const {children} = props;

    return (
        <Menu.Item p={0} pb={"xs"} closeMenuOnClick {...props}>
            {children}
        </Menu.Item>
    )
}

CustomMenu.Item = CustomMenuItem;