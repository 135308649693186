import React, {useEffect, useState} from 'react';
import {
    Container, NumberFormatter,
    Space, Stack, Table, Text, useMantineTheme,
} from '@mantine/core';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import i18n, {localized, parameterLanguage} from "../../i18n";
import classes from './RecipesPage.module.css';
import {Page, PageCard, PageTitle} from "../../components/page/Page";
import {useTranslation} from "react-i18next";
import RecipeList from "./RecipeList";
import {Icon} from "../../components/icons/Icons";
import useKey from "../useKey";
import useRecipeCategory from "./useRecipeCategory";
import useResult from "../useResult";
import {AdvertisingGrid} from "../../components/advertising/Advertising";
import {Pie, PieChart, ResponsiveContainer, Sector} from "recharts";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {recipeCategoryNavigate} from "./RecipeCategoryLink";
import {useApplicationContext} from "../../components/application/ApplicationContext";
import {useLoadingContext} from "../../components/loading/LoadingContext";
import {RecipeGptLink, RecipeIconTitle} from "./RecipeLink";
import {homeNavigate} from "../home/HomeLink";
import {Sponsors} from "../../components/sponsor/Sponsor";
import useInsights from "../useInsights";
import useMedia from "../useMedia";
import {theme} from "../../Theme";

/**
 * @returns {Element}
 * @constructor
 */
const RecipesCard = ({recipeCategory, recipesTotalCount}) => {

    const { recipeCategories } = useApplicationContext();
    const [activeIndex, setActiveIndex] = useState(getDefaultActiveIndex());

    const navigate = useNavigate();

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const { isSm } = useMedia();

    const data = recipeCategories.map(recipeCategory => ({
        recipeCategory: recipeCategory,
        name: localized(recipeCategory, "name"),
        value: recipeCategory.recipeCount
    }));

    function getDefaultActiveIndex() {
        return recipeCategories.findIndex(rc => rc.recipeCategoryId === recipeCategory.recipeCategoryId);
    }

    /**
     * @param props
     * @returns {Element}
     */
    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        const wrapText = (text, maxCharsPerLine) => {
            const words = text.split(" ");
            let lines = [];
            let currentLine = "";

            words.forEach((word) => {
                // If adding the current word doesn't exceed the limit, let's add it to the current line.
                if ((currentLine + word).length <= maxCharsPerLine) {
                    currentLine += word + " ";
                } else {
                    // Otherwise, we close the current line and start a new one.
                    lines.push(currentLine.trim());
                    currentLine = word + " ";
                }
            });

            // We add the last line
            lines.push(currentLine.trim());

            return lines;
        };

        return (
            <g>
                <text x={cx} y={cy} textAnchor="middle" fontSize={14} fontWeight={isSm ? 400 : 700} fill={"white"}>
                    {wrapText(payload.name, 16).map((line, index) => (
                        <tspan key={index} x={cx} dy={`${index * 1.2}em`}>
                            {line}
                        </tspan>
                    ))}
                </text>
                <text x={cx} y={"94%"} textAnchor="middle" fontSize={12} fontWeight={400} fill={"white"}>
                    {t("recipe.recipesGraph", {
                        recipeCategory: localized(props.recipeCategory, "name"),
                        percent: (percent * 100).toFixed(0)
                    })}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={"white"}
                    paddingAngle={1}
                    strokeWidth={0}
                    style={{cursor: 'pointer'}}
                    cornerRadius={1}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 8}
                    outerRadius={outerRadius + 10}
                    fill={"white"}
                    cornerRadius={1}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={"white"} strokeWidth={2}
                      strokeDasharray="2.5 2.5" fill="none"/>
                {/*<circle cx={ex} cy={ey} r={3} fill={"white"} stroke="none"/>*/}
                <text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={5} textAnchor={textAnchor} fill="white">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
                {/*<text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={20} textAnchor={textAnchor} fontSize={12} fill="var(--mantine-color-tertiary-6)">*/}
                {/*    {`(Rate ${(percent * 100).toFixed(2)}%)`}*/}
                {/*</text>*/}
            </g>
        );
    };

    return (
        <PageCard>

            <PageCard.Section pb={"md"} style={{height: "330px"}}
                              onMouseLeave={() => setActiveIndex(getDefaultActiveIndex())}>
                <ResponsiveContainer width="100%" height="100%" style={{backgroundColor: "rgba(255, 255, 255, 0.12)"}}>
                    <PieChart width={300} height={330}>
                        <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={data}
                            cx="50%"
                            cy="46%"
                            innerRadius={70}
                            outerRadius={90}
                            fill="white"
                            opacity={0.3}
                            dataKey="value"
                            onMouseEnter={(event, index) => setActiveIndex(index)}
                            onClick={(event, index) => {
                                if (event.recipeCategory.recipeCategoryId !== recipeCategory.recipeCategoryId) {
                                    recipeCategoryNavigate(navigate, event.recipeCategory)
                                }
                            }}
                            paddingAngle={1}
                            strokeWidth={0}
                            isAnimationActive={false}
                            startAngle={90}
                            endAngle={-270}
                            cornerRadius={1}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </PageCard.Section>

            <StripedTable stripedColor={"tertiary"} className={classes.recipescardtable}>
                <Table.Tbody>
                    <Table.Tr>
                        <Table.Td pl={0}>
                            <RecipeIconTitle/>
                        </Table.Td>
                        <Table.Td pr={0} ta={"right"}>
                            <Text>
                                {recipesTotalCount}
                            </Text>
                        </Table.Td>
                    </Table.Tr>
                </Table.Tbody>
            </StripedTable>
        </PageCard>
    );
}

/**
 * RecipesPage
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const RecipesPage = (props) => {

    const {paramLng} = useParams();
    const lng = parameterLanguage(paramLng);

    const {paramParentRecipeCategoryId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const parentRecipeCategoryId = location.state?.parentRecipeCategoryId || paramParentRecipeCategoryId;
    const recipeCategoryId = location.state?.recipeCategoryId;

    const [isLoadedRecipes, setIsLoadedRecipes] = useState(false);
    const [recipesTotalCount, setRecipesTotalCount] = useState(0);

    const { isLoading, isLoaded } = useLoadingContext();

    const { t } = useTranslation();

    const {key, stateKey, random} =
        useKey({
			isLoading: isLoading,
			onKeyChange: () => {
                console.debug(`RecipesPage`);

                setIsLoadedRecipes(false);
                setRecipesTotalCount(0);

                refetchItem();
			}}
		);

    useEffect(() => {
        if(lng !== undefined && lng !== i18n.language) {
            i18n.changeLanguage(lng)
                .then(value => window.location.reload());
        }
    }, [lng]);

    const {	data: dataItem, isLoaded: isLoadedItem,
        	reset: resetItem, refetch: refetchItem} =
        useRecipeCategory({
            recipeCategoryId: parentRecipeCategoryId || recipeCategoryId,
            incrementViews: true,
            onError: (error) => {
                homeNavigate(navigate);
            }
        })

    const {	data: dataInsights, isLoaded: isLoadedInsights,
        reset: resetInsights, refetch: refetchInsights} =
        useInsights({
            enabled: isLoadedItem,
            collectionName: "recipeCategory",
            id: dataItem.recipeCategoryId
        })

    const {isSuccess, isError} =
        useResult({
            isLoaded: isLoaded,
            isSuccess: isLoadedItem && isLoadedRecipes
        })

    return (!isLoadedItem ? null :

        <Page
            pageTitle={localized(dataItem, 'name')}
            pageDescription={t(`recipeCategory.description.${dataItem?.recipeCategoryId}`)}
            pageKeywords={`recipes,${dataItem.name},${localized(dataItem, 'name')}`}
            pageUrl={`/recipes/${dataItem.recipeCategoryId}`}
            pageTitles={[
                <PageTitle
                    displayTitle={<Stack gap={0} align="flex-start" justify="flex-start">
                        			<Text lineClamp={2} inherit>{localized(dataItem, 'name')}</Text>
                        			<Text size={"xs"} pl={2} pt={"xs"} ff={theme.fontFamily}><NumberFormatter thousandSeparator="'" value={dataInsights.views}/> {t("common.views")}</Text>
                    			  </Stack>
                				 }
                    withDescription
                    descriptionComponent={<Text size={"sm"} lineClamp={5}>{t(`recipeCategory.description.${dataItem?.recipeCategoryId}`)}</Text>}
                    withSubTitle
                    subTitleComponent={<RecipeGptLink className={classes.recipegptlink}/>}
                    withCard
                    cardComponent={<RecipesCard recipeCategory={dataItem} recipesTotalCount={recipesTotalCount}/>}
                    titleIcon={<Icon name={"hat-chef"}/>}
                    />
            ]}>

            <Container>
                <AdvertisingGrid space={"xs"}>
                    <Space h="xs"/>
                    <Space h="lg"/>
                    <RecipeList
                                scope={`RecipesPage-${random}`}
                                parentRecipeCategoryId={parentRecipeCategoryId}
                                recipeCategoryId={recipeCategoryId}
                                showCategory={false}
                                onLoad={(ids, totalCount) => {setRecipesTotalCount(totalCount); setIsLoadedRecipes(true);}}
                                />

                    <Space h={"lg"}/>
                    <Sponsors section={"recipeCategory"}/>

                </AdvertisingGrid>
            </Container>
        </Page>
    );
}

export default RecipesPage;