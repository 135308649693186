import Qty from "js-quantities/esm";


/**
 * @param input
 * @returns {*}
 */
const preprocessUnit = (input) => {
    // Replace 'g' (if not followed by 'r') with 'gram'
    return input.replace(/\bgr?\b/g, 'gram');
};


/**
 * @param input
 * @returns {*|null}
 */
export const convertMeasure = (value, measure) => {

    if(!value || !measure) {
        return null;
    }

    try {
        const quantity = Qty(preprocessUnit(value));
        return `${quantity.to(preprocessUnit(measure)).scalar}${measure}`;
    }
    catch (error) {
        return null;
    }
};