import React from 'react';
import {Anchor} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {keyState} from "../useKey";

/**
 * HomeLink
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const HomeLink = (props) => {

    const {target, onClick = () => {}, children} = props;

    const navigate = useNavigate();

    return (
        <Anchor target={target} underline="never"
                {...props}
                onClick={(event) => {onClick(event); homeNavigate(navigate);}}
                >

          {children}
        </Anchor>
    );
};

/**
 * homeNavigate
 *
 * @returns {React.JSX.Element|undefined}
 * @constructor
 */
export function homeNavigate(navigate, target = "_self") {

    if(target === "_self") {
        navigate(`/`, {state: {...keyState()}});
    }
    else {
        window.open(`/`, "_blank");
    }
}