import React from 'react';
import {Anchor, Group, Stack, Text} from "@mantine/core";
import {localized} from "../../i18n";
import {useNavigate} from "react-router-dom";
import {keyState} from "../useKey";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

/**
 * RecipeCategoryLink
 *
 * @param recipeCategory
 * @param withDescription
 * @param target
 * @param style
 * @param className
 * @param onClick
 * @param children
 * @returns {Element}
 * @constructor
 */
const RecipeCategoryLink = ({recipeCategory, withDescription = false, target = "_self", style, styleDescription, className, classNameDescription, onClick = () => {}, children}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return !withDescription ?
            <Anchor target={target} underline="never" style={{...style}} className={className}
                    onClick={(event) => {
                        recipeCategoryNavigate(navigate, recipeCategory);
                        onClick(event);
                    }}>
                {localized(recipeCategory, 'name')}
            </Anchor>
            :
            <Anchor target={target} underline="never" style={{...style}} className={className}
                    onClick={(event) => {
                        recipeCategoryNavigate(navigate, recipeCategory);
                        onClick(event);
                    }}>
                <Group justify={"space-between"} wrap={"nowrap"}>
                    <Stack gap={0}>
                        {localized(recipeCategory, 'name')}

                        <Text size={'xs'} opacity={0.75} style={{...styleDescription}} className={classNameDescription}>
                            {t(`recipeCategory.description.${recipeCategory.recipeCategoryId}`)}
                        </Text>

                    </Stack>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </Group>
            </Anchor>
}

/**
 * recipesNavigate
 *
 * @returns {React.JSX.Element|undefined}
 * @constructor
 */
export function recipeCategoryNavigate(navigate, recipeCategory) {

    if(!recipeCategory) {
        return;
    }

    navigate(`/recipes/${recipeCategory.recipeCategoryId}`, {state: {...keyState()}});
}

export default RecipeCategoryLink;