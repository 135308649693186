import { useEffect, useState } from 'react';
import { fetchIngredientAll } from "../../api/api";
import { theme } from "../../Theme";
import { useAccountContext } from "../../components/account/AccountContext";
import { useErrorContext } from "../../components/error/ErrorContext";
import axios from 'axios';

/**
 * useIngredientAll
 */
const useIngredientAll = ({
							  enabled,
							  onSuccess,
							  onError,
							  unfiltered = true,
							  entityCategoryId,
							  molecularSearchTypes,
							  linkNames,
							  ingredientIds,
							  ingredientNames,
							  search,
							  random,
							  page
						  } = {}) => {

	const { getIdToken } = useAccountContext();
	const { onError: onErrorContext } = useErrorContext();

	const [data, setData] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [count, setCount] = useState(0);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [entityIds, setEntityIds] = useState([]);

	/**
	 * Fetch ingredient data from API using Axios, return a Promise
	 */
	const fetchData = async (force = false) => {

		if (!force && (!enabled || isLoading)) {
			return;
		}

		setIsLoading(true);
		setIsLoaded(false); // Ensure isLoaded is reset on fetch start

		reset();

		const cancelTokenSource = axios.CancelToken.source(); // Create a new cancel token

		try {
			const responseData = await fetchIngredientAll(
				entityCategoryId,
				molecularSearchTypes,
				linkNames,
				ingredientIds,
				ingredientNames,
				search,
				random,
				page,
				theme.custom.ingredient.paginationSize,
				await getIdToken(),
				cancelTokenSource.token
			);

			const { documents, totalCount, count } = responseData;

			if (unfiltered) {
				setData(documents);
				setTotalCount(totalCount);
				setCount(count);
			}
			else {
				const filteredData = documents.filter(document =>
					document.categories.some(category =>
						(category.entityCategoryId === parseInt(entityCategoryId) && category.representative > 0) ||
						(category.entityCategoryId === parseInt(entityCategoryId) && category.representative === 0 && category.naturalSourceName === 0)
					)
				);
				setData(filteredData);
				setTotalCount(filteredData.length);
				setCount(filteredData.length);
			}

			setEntityIds(getEntityIds(documents));

			if (onSuccess) {
				onSuccess(documents, totalCount, count);
			}

			setIsLoaded(true);
			return responseData;

		}
		catch (error) {

			if (axios.isCancel(error)) {
				console.log('Request canceled', error.message);
			}
			else {
				if(onError) {
					onError(error);
				}
				else {
					onErrorContext(error); // Handle the error via global error handler
					// throw error;
				}
			}
		}
		finally {
			setIsLoading(false);
		}

		// Cancel token cleanup
		return () => cancelTokenSource.cancel("Request canceled by user.");
	};

	/**
	 * Reset the state
	 */
	const reset = () => {
		setIsLoaded(false);
		setData([]);
		setTotalCount(0);
		setCount(0);
		setEntityIds([]);
	};

	/**
	 * Refetch the data (returns a Promise)
	 */
	const refetch = () => {
		return fetchData(true);
	};

	useEffect(() => {
		fetchData();
	}, [enabled]);

	/**
	 * Get unique entity IDs from data
	 */
	const getEntityIds = (data) => {
		if (!data) {
			return [];
		}

		return [...new Set(
			data.flatMap(ingredient => ingredient.categories
				?.filter(category => category.representative > 0 || (category.representative === 0 && category.naturalSourceName === 0)) // representative and synonyms
				.map(category => category.entityId)
			)
		)];
	};

	return {
		data,
		entityIds,
		isLoaded,
		totalCount,
		count,
		reset,
		refetch
	};
};

export default useIngredientAll;
