import {useMediaQuery} from "@mantine/hooks";
import {theme} from "../Theme";

/**
 * useMedia
 */
const useMedia = () => {

    const isXs = useMediaQuery(theme.custom.mediaQuery.xs);
    const isSm = useMediaQuery(theme.custom.mediaQuery.sm);
    const isMd = useMediaQuery(theme.custom.mediaQuery.md);
    const isLg = useMediaQuery(theme.custom.mediaQuery.lg);
    const isXl = useMediaQuery(theme.custom.mediaQuery.xl);

    return {
        isXs,
        isSm,
        isMd,
        isLg,
        isXl
    };
};

export default useMedia;