import React from "react";
import {useTranslation} from "react-i18next";
import {usePaymentContext} from "./PaymentContext";
import {Anchor, Button, Group, Stack, Text} from "@mantine/core";
import {SimpleBox} from "../simpleBox/SimpleBox";
import {Icon} from "../icons/Icons";
import {StripeCredits} from "../credits/Credits";
import classes from './Payment.module.css';

/**
 * PaymentSubscription
 */
export const PaymentSubscription = ({priceId, priceUsd, pricePeriod, disabled, color="secondary"}) => {

	const {subscription} = usePaymentContext();

	const { t } = useTranslation();

	return (
		<Button color={color} variant={"light"} size="lg"
				onClick={() => subscription(priceId)}
				disabled={disabled}
				style={{height: "70px"}}
				p={0}
				mb={2}
				>
			<Stack gap={0}>
				{priceUsd}
				<Text size={"xs"} pt={4}>{t(`payment.${pricePeriod}`)}</Text>
				<Text size={"xs"}>{t(`payment.${pricePeriod}Discount`)}</Text>
			</Stack>
		</Button>
	)
};

/**
 * PaymentDonateLink
 */
export const PaymentDonateLink = () => {

	const {paymentContributeLink} = usePaymentContext();

	const { t } = useTranslation();

	return (
		<Anchor target={"_blank"} underline="never"
				href={paymentContributeLink}
			>
			<SimpleBox color={"tertiary"}>
				<Group justify={"space-between"} align={"center"}>
					<Icon name={"contribute"} className={classes.paymentcontributeicon}/>
					<Stack gap={0} flex={1}>
						<Text size={"sm"} flex={1}>
							{t("payment.donateDescription")}
						</Text>
						<Group justify="space-between" pt={"md"}>
							<StripeCredits i18nKey={"common.stripeAttribution"} disabled={true}/>
							<Button color={"tertiary"} variant={"light"}>{t("payment.donate")}</Button>
						</Group>
					</Stack>
				</Group>
			</SimpleBox>
		</Anchor>
	);
};

/**
 * PaymentCustomerManagement
 */
export const PaymentCustomerManagement = ({color="tertiary"}) => {

	const {customerSession} = usePaymentContext();

	const { t } = useTranslation();

	return (
		<Button color={color} variant={"light"}
				onClick={() => customerSession()}
			>
			{t("payment.manage")}
		</Button>
	);
};