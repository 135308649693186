import { useState, useEffect } from "react";

/**
 * useUserLocation
 */
const useUserLocation = () => {

    const [location, setLocation] = useState(null);

    useEffect(() => {

        if (navigator.geolocation) {

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ latitude, longitude });
                },
                (error) => {
                    console.error("Error getting user location:", error);
                }
            );
        }
        else {
            console.error("Geolocation is not supported by this browser.");
        }

    }, []);

    return location;
};

export default useUserLocation;
