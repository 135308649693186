import i18n from 'i18next';
import {initReactI18next, useTranslation} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {useCallback, useEffect} from "react";

// Supported languages
const supportedLanguages = ['en', 'de', 'it', 'es', 'fr', 'pt'];

/**
 * Function to determine initial language
 */
function getInitialLanguage() {

    // Check local storage
    const storedLanguage = localStorage.getItem('i18nextLng');

    if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
        return storedLanguage;
    }

    // Check browser
    const browserLanguage = navigator.language.slice(0, 2); // Use primary language code (e.g., "en" from "en-US")
    if (supportedLanguages.includes(browserLanguage)) {
        return browserLanguage;
    }

    // Default to en
    return 'en';
}

/**
 * i18n
 */
i18n
    /*
       load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      */
    .use(HttpApi)
    /*
       detect user language
      */
    .use(LanguageDetector)
    /*
       pass the i18n instance to react-i18next.
      */
    .use(initReactI18next)
    /*
       init i18next
      */
    .init({
        debug: false,
        supportedLngs: supportedLanguages,
        fallbackLng: 'en',
        lng: getInitialLanguage(),
        defaultNS: 'translation',
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie'],
        },
        react: {
            useSuspense: false,
        },
    });

/**
 * useI18nLanguageChange
 *
 * @param onLanguageChange
 * @returns {{}}
 *
 * @example
 *
 *     useI18nLanguageChange((lng) => {
 *         console.log("language changed " + lng);
 *     })
 */
export const useI18nLanguageChange = (onLanguageChange) => {

    const { i18n } = useTranslation();

    const handleLanguageChanged = useCallback(() => {
        if (onLanguageChange) {
            onLanguageChange(i18n.language);
        }
    }, [onLanguageChange]);

    useEffect(() => {

        i18n.on('languageChanged', handleLanguageChanged);

        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [handleLanguageChanged]);

    return {};
};

/**
 * @param object
 * @param field
 * @param addEn
 * @returns {*}
 */
export const localized = (object, field, addEn = false) => {

    if(!object) {
        return undefined;
    }

    const lngText = object[`${field}${capitalize(i18n.language)}`];

    if(i18n.language === "en") {
        return lngText;
    }

    const enText = object[`${field}En`];

    return lngText ? lngText + (addEn ? ` (${enText})` : "") : enText;
};

/**
 * @param object
 * @param field
 * @returns {*}
 */
export const localizedEn = (object, field) => {

    if(!object) {
        return undefined;
    }

    return object[`${field}En`];
};

/**
 * @param value
 * @returns {string|string}
 */
export const capitalize = (value) => {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : '';
}

/**
 * @param value
 * @returns {string|string}
 */
export const upperCase = (value) => {
    return value ? value.toUpperCase() : '';
}

/**
 * @param value
 */
export const parameterLanguage = (value) => {

    if(value === undefined) {
        return undefined;
    }

    return supportedLanguages.includes(value) ? value : "en";
}

export default i18n;