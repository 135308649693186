import React from 'react';
import {Anchor, Group, Highlight, Stack, Text} from "@mantine/core";
import {localized} from "../../i18n";
import {useNavigate} from "react-router-dom";
import {keyState} from "../useKey";
import {useTranslation} from "react-i18next";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/**
 * EntityCategoryLink
 *
 * @param entityCategory
 * @param withDescription
 * @param target
 * @param style
 * @param highlight
 * @param className
 * @param onClick
 * @param children
 * @returns {Element}
 * @constructor
 */
export const EntityCategoryLink = ({entityCategory, withDescription = false, target = "_self", style, styleDescription, highlight, className, classNameDescription, onClick = () => {}}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return !withDescription ?
            <Anchor target={target} underline="never" style={{...style}} className={className}
                    onClick={(event) => {entityCategoryNavigate(navigate, entityCategory); onClick(event); }}>
                {highlight ?
                    <Highlight highlight={highlight}>
                        {localized(entityCategory, 'name')}
                    </Highlight>
                    :
                    localized(entityCategory, 'name')
                }
            </Anchor>
            :
            <Anchor target={target} underline="never" style={{...style}} className={className}
                    onClick={(event) => {entityCategoryNavigate(navigate, entityCategory); onClick(event); }}>
                <Group justify={"space-between"} wrap={"nowrap"}>
                    <Stack gap={0}>
                          {highlight ?
                              <Highlight highlight={highlight}>
                                  {localized(entityCategory, 'name')}
                              </Highlight>
                              :
                              localized(entityCategory, 'name')
                          }
                        <Text size={'xs'} opacity={0.75} style={{...styleDescription}} className={classNameDescription}>
                            {t(`entityCategory.description.${entityCategory.entityCategoryId}`)}
                        </Text>
                    </Stack>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </Group>
            </Anchor>
};

/**
 * EntityCategoryText
 *
 * @param entityCategory
 * @param highlight
 * @param className
 * @param onClick
 * @param children
 * @returns {Element}
 * @constructor
 */
export const EntityCategoryText = ({entityCategory, highlight}) => {

    return (
        <Highlight highlight={highlight}>
            {localized(entityCategory, 'name')}
        </Highlight>
    );
};

/**
 * entityCategoryNavigate
 *
 * @returns {React.JSX.Element|undefined}
 * @constructor
 */
export function entityCategoryNavigate(navigate, entityCategory, target = "_self") {

    if(!entityCategory) {
        return;
    }

    if(target === "_self") {
        navigate(`/ingredients/${entityCategory.entityCategoryId}`, {state: {...keyState()}});
    }
    else {
        window.open(`/ingredients/${entityCategory.entityCategoryId}`, "_blank");
    }
}