import { useEffect, useState } from 'react';
import { fetchWiki } from "../api/api";
import { useAccountContext } from "../components/account/AccountContext";
import { useErrorContext } from "../components/error/ErrorContext";
import axios from 'axios';

/**
 * useWiki
 */
const useWiki = ({
					 enabled,
					 onSuccess,
					 onError,
					 url
				 } = {}) => {

	const { getIdToken } = useAccountContext();
	const { onError: onErrorContext } = useErrorContext();

	const [data, setData] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Fetch data from API using Axios, return a Promise
	 */
	const fetchData = async (force = false) => {

		if (!force && (!enabled || isLoading)) {
			return;
		}

		setIsLoading(true);
		setIsLoaded(false); // Ensure isLoaded is reset on fetch start

		reset();

		const cancelTokenSource = axios.CancelToken.source(); // Create a new cancel token

		try {
			const responseData = await fetchWiki(url, await getIdToken(), cancelTokenSource.token);
			setData(responseData);

			if (onSuccess) {
				onSuccess(responseData);
			}

			setIsLoaded(true);
			return responseData; // Resolve the promise with fetched data

		}
		catch (error) {

			if (axios.isCancel(error)) {
				console.log('Request canceled', error.message);
			}
			else {
				if(onError) {
					onError(error);
				}
				else {
					onErrorContext(error); // Handle the error via global error handler
					// throw error;
				}
			}
		}
		finally {
			setIsLoading(false); // Mark loading as complete
		}

		// Cancel token cleanup
		return () => cancelTokenSource.cancel("Request canceled by user.");
	};

	/**
	 * Reset the state
	 */
	const reset = () => {
		setIsLoaded(false);
		setData({});
	};

	/**
	 * Refetch the data (returns a Promise)
	 */
	const refetch = () => {
		return fetchData(true); // Force a refetch, bypassing enabled check
	};

	useEffect(() => {
		fetchData();
	}, [enabled]);

	return {
		data,
		isLoaded,
		reset,
		refetch
	};
};

export default useWiki;
