import {useTranslation} from "react-i18next";
import {Avatar, Badge, Group, Pill, Tooltip} from "@mantine/core";
import classes from './RecipeUtil.module.css';
import React from "react";
import {isNew} from "../../util/time";
import {theme} from "../../Theme";

/**
 * @param item
 * @returns {*[]}
 */
export function sortedCategories(categories) {

    let sortedCategories = [];

    // Helper function to add categories in the correct order
    function addCategoryAndChildren(category) {
        sortedCategories.push(category);
        categories.forEach(cat => {
            if (cat.parentRecipeCategoryId === category.recipeCategoryId) {
                addCategoryAndChildren(cat);
            }
        });
    }

    // Start with root categories (those without a parent)
    if(categories) {

        categories.forEach(category => {
            if (!category.hasOwnProperty('parentRecipeCategoryId')) {
                addCategoryAndChildren(category);
            }
        });
    }

    return sortedCategories;
}

/**
 * RecipeDiet
 */
export const RecipeDiet = ({recipe, color = "primary"}) => {

    const {t} = useTranslation();

    function getRootClass() {

        switch(color) {

            case "white":
                return classes.pillrootwhite;

            case "secondary":
                return classes.pillrootsecondary;

            case "tertiary":
                return classes.pillroottertiary;

            default:
                return classes.pillrootprimary;
        }
    }

    /**
     * Diet
     */
    const Diet = ({diet}) => {

        return  <Pill color={color} classNames={{
                        root: getRootClass()
                     }}>
                    {t(`recipe.dietType.${diet}`)}
                </Pill>
    }

    return (
        <Group gap={4}>
            {!recipe.diabeticDiet || recipe.diabeticDiet === 0 ?  null : <Diet diet={"diabeticDiet"}/>}
            {!recipe.glutenFreeDiet || recipe.glutenFreeDiet === 0 ?  null : <Diet diet={"glutenFreeDiet"}/>}
            {!recipe.halalDiet || recipe.halalDiet === 0 ?  null : <Diet diet={"halalDiet"}/>}
            {!recipe.hinduDiet || recipe.hinduDiet === 0 ?  null : <Diet diet={"hinduDiet"}/>}
            {!recipe.kosherDiet || recipe.kosherDiet === 0 ?  null : <Diet diet={"kosherDiet"}/>}
            {!recipe.lowCalorieDiet || recipe.lowCalorieDiet === 0 ?  null : <Diet diet={"lowCalorieDiet"}/>}
            {!recipe.lowFatDiet || recipe.lowFatDiet === 0 ?  null : <Diet diet={"lowFatDiet"}/>}
            {!recipe.lowLactoseDiet || recipe.lowLactoseDiet === 0 ?  null : <Diet diet={"lowLactoseDiet"}/>}
            {!recipe.lowSaltDiet || recipe.lowSaltDiet === 0 ?  null : <Diet diet={"lowSaltDiet"}/>}
            {!recipe.veganDiet || recipe.veganDiet === 0 ?  null : <Diet diet={"veganDiet"}/>}
            {!recipe.vegetarianDiet || recipe.vegetarianDiet === 0 ?  null : <Diet diet={"vegetarianDiet"}/>}
        </Group>
    )
}

/**
 * RecipeNew
 */
export const RecipeNew = (props) => {

    const {recipe} = props;
    const {t} = useTranslation();

    return !isNew(recipe.date, theme.custom.recipe.isNewInDays) ? null :
        <Badge variant="filled" color="tertiary" size="xs" radius="sm" {...props}>
            {t("common.new")}
        </Badge>
}

/**
 * RecipeAuthorUser
 */
export const RecipeAuthorUser = (props) => {

    const {user} = props;

    return  <Tooltip label={user?.name} bg={"tertiary"} c={"white"} style={{visibility: user?.name !== undefined && user?.name.length > 0 ? "visible" : "hidden"}}>
                <Avatar variant="transparent" color={"white"} radius={"md"} size={"38px"} name={user.name} src={user.picture} {...props}/>
            </Tooltip>
}