import {useTranslation} from "react-i18next";
import {useAccountContext} from "../account/AccountContext";
import {useEnvironmentContext} from "../environment/EnvironmentContext";

/**
 * useFeatures
 */
export const useFeatures = () => {

	const {environment} = useEnvironmentContext();

	const { isAuthenticated, userAccount } = useAccountContext();

	const {t} = useTranslation();

	/**
	 * FeaturePlans
	 */
	const FeaturePlans = {
		FREE: {
			id: "free",
			name: t("feature.featurePlans.free"),
			description: t("feature.featurePlans.freeDescription"),
			enabled: true,
			priority: 0
		},
		ACCOUNT: {
			id: "account",
			name: t("feature.featurePlans.account"),
			description: t("feature.featurePlans.accountDescription", {trialDays: environment.paymentPremiumTrialDays}),
			enabled: isAuthenticated,
			priority: 1
		},
		PREMIUM: {
			id: "premium",
			name: t("feature.featurePlans.premium"),
			description: t("feature.featurePlans.premiumDescription"),
			enabled: isAuthenticated && (userAccount?.isAdmin() || userAccount?.isPremiumTrial() || userAccount?.isPremium()),
			priority: 2,
			payments: [
				{
					pricePeriod: "monthly",
					priceUsd: environment.paymentPremiumMonthlyPriceUsd,
					priceId: environment.paymentPremiumMonthlyPriceId,
					enabled: isAuthenticated
				},
				{
					pricePeriod: "yearly",
					priceUsd: environment.paymentPremiumYearlyPriceUsd,
					priceId: environment.paymentPremiumYearlyPriceId,
					enabled: isAuthenticated
				}
			]
		}
	};

	/**
	 * Features
	 */
	const Features = {
		account: {
			name: t("feature.account.account"),
			plan: FeaturePlans.FREE,
			features: {
				ingredientFavorites: {
					name: t("feature.account.ingredientFavorites"),
					plan: FeaturePlans.ACCOUNT,
					// maxRecords: FeaturePlans.ACCOUNT.enabled ? 50 : 0 // Example to limit records...
				},
				moleculeFavorites: {
					name: t("feature.account.moleculeFavorites"),
					plan: FeaturePlans.ACCOUNT
				},
				recipeFavorites: {
					name: t("feature.account.recipeFavorites"),
					plan: FeaturePlans.ACCOUNT
				}
			}
		},
		search: {
			name: t("feature.search.search"),
			plan: FeaturePlans.FREE,
			features: {
				ingredients: {
					name: t("feature.search.ingredients"),
					plan: FeaturePlans.FREE,
				},
				ingredientFlavors: {
					name: t("feature.search.ingredientFlavors"),
					plan: FeaturePlans.PREMIUM
				},
				ingredientOdors: {
					name: t("feature.search.ingredientOdors"),
					plan: FeaturePlans.PREMIUM
				},
				ingredientTastes: {
					name: t("feature.search.ingredientTastes"),
					plan: FeaturePlans.PREMIUM
				},
				ingredientCompounds: {
					name: t("feature.search.ingredientCompounds"),
					plan: FeaturePlans.PREMIUM
				},
				ingredientScientific: {
					name: t("feature.search.ingredientScientific"),
					plan: FeaturePlans.PREMIUM
				},
				molecules: {
					name: t("feature.search.molecules"),
					plan: FeaturePlans.ACCOUNT
				},
				recipes: {
					name: t("feature.search.recipes"),
					plan: FeaturePlans.FREE
				}
			}
		},
		ingredient: {
			name: t("feature.ingredient.ingredient"),
			plan: FeaturePlans.FREE,
			features: {
				foodFacts: {
					name: t("feature.ingredient.foodFacts"),
					plan: FeaturePlans.FREE
				},
				littleStories: {
					name: t("feature.ingredient.littleStories"),
					plan: FeaturePlans.FREE,
					note: "Coming Soon"
				},
				categoryPairing: {
					name: t("feature.ingredient.categoryPairing"),
					plan: FeaturePlans.FREE
				},
				pairing: {
					name: t("feature.ingredient.pairing"),
					plan: FeaturePlans.ACCOUNT
				},
				nutrients: {
					name: t("feature.ingredient.nutrients"),
					plan: FeaturePlans.PREMIUM,
					note: "Demo Data"
				},
				cookingTechniques: {
					name: t("feature.ingredient.cookingTechniques"),
					plan: FeaturePlans.PREMIUM,
					note: "Demo Data"
				},
				cookingLevels: {
					name: t("feature.ingredient.cookingLevels"),
					plan: FeaturePlans.PREMIUM,
					note: "Demo Data"
				},
				naturalSources: {
					name: t("feature.ingredient.naturalSources"),
					plan: FeaturePlans.ACCOUNT
				},
				relatedIngredients: {
					name: t("feature.ingredient.relatedIngredients"),
					plan: FeaturePlans.FREE
				},
				molecules: {
					name: t("feature.ingredient.molecules"),
					plan: FeaturePlans.ACCOUNT
				},
				relatedRecipes: {
					name: t("feature.ingredient.relatedRecipes"),
					plan: FeaturePlans.FREE
				}
			}
		},
		molecule: {
			name: t("feature.molecule.molecule"),
			plan: FeaturePlans.ACCOUNT,
			features: {
				flavors: {
					name: t("feature.molecule.flavors"),
					plan: FeaturePlans.ACCOUNT
				},
				odors: {
					name: t("feature.molecule.odors"),
					plan: FeaturePlans.ACCOUNT
				},
				tastes: {
					name: t("feature.molecule.tastes"),
					plan: FeaturePlans.ACCOUNT
				},
				compounds: {
					name: t("feature.molecule.compounds"),
					plan: FeaturePlans.ACCOUNT
				},
				scientific: {
					name: t("feature.molecule.scientific"),
					plan: FeaturePlans.ACCOUNT
				},
				foundInIngredients: {
					name: t("feature.molecule.foundInIngredients"),
					plan: FeaturePlans.ACCOUNT
				},
				foundInRecipes: {
					name: t("feature.molecule.foundInRecipes"),
					plan: FeaturePlans.ACCOUNT
				}
			}
		},
		recipe: {
			name: t("feature.recipe.recipe"),
			plan: FeaturePlans.FREE,
			note: "Demo Data",
			features: {
				ingredients: {
					name: t("feature.recipe.ingredients"),
					plan: FeaturePlans.FREE
				},
				ingredientsServingsCalculator: {
					name: t("feature.recipe.ingredientsServingsCalculator"),
					plan: FeaturePlans.FREE
				},
				ingredientsBasket: {
					name: t("feature.recipe.ingredientsBasket"),
					plan: FeaturePlans.ACCOUNT
				},
				instructions: {
					name: t("feature.recipe.instructions"),
					plan: FeaturePlans.FREE
				},
				instructionsStepsTracking: {
					name: t("feature.recipe.instructionsStepsTracking"),
					plan: FeaturePlans.ACCOUNT
				},
				instructionsStepsTimers: {
					name: t("feature.recipe.instructionsStepsTimers"),
					plan: FeaturePlans.PREMIUM
				},
				nutrients: {
					name: t("feature.recipe.nutrients"),
					plan: FeaturePlans.PREMIUM,
					note: "Coming Soon"
				},
				utensils: {
					name: t("feature.recipe.utensils"),
					plan: FeaturePlans.FREE,
					note: "Demo Data"
				},
				relatedMolecules: {
					name: t("feature.recipe.relatedMolecules"),
					plan: FeaturePlans.ACCOUNT
				},
				relatedRecipes: {
					name: t("feature.recipe.relatedRecipes"),
					plan: FeaturePlans.FREE
				},
			}
		},
		tools: {
			name: t("feature.tool.tool"),
			plan: FeaturePlans.PREMIUM,
			features: {
				studio: {
					name: t("feature.tool.foodPairingStudio"),
					plan: FeaturePlans.PREMIUM,
					note: "Coming Soon",
				}
			}
		},
	}

	return {
		FeaturePlans, Features
	};
};