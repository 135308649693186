import {useEffect} from 'react';
import {useErrorContext} from "../components/error/ErrorContext";

/**
 * useResult
 */
const useResult = ({isSuccess, onSuccess, isError, errors, isLoaded}) => {

    const {onError} = useErrorContext();

    /**
     * Success
     */
    useEffect(() => {

        if(isSuccess) {

            if(isLoaded) {
                isLoaded();
            }

            if(onSuccess) {
                onSuccess();
            }
        }

    }, [isSuccess]);

    /**
     * Error
     */
    useEffect(() => {
        if (isError && onError) {
            onError(errors);
        }
    }, [isError]);

    return {
        isSuccess,
        isError
    };
};

export default useResult;