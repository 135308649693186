import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    Group,
    Space,
    Table,
    Tabs,
    Text,
    Title,
    VisuallyHidden
} from '@mantine/core';
import {Page, PageCard, PageTitle} from "../../components/page/Page";
import {useTranslation} from "react-i18next";
import classes from "./SearchPage.module.css";
import {Ingredient09} from "../ingredient/IngredientList";
import {Recipe09} from "../recipe/RecipeList";
import {useNavigate, useParams} from "react-router-dom";
import {ScrollTab} from "../../components/scrollTab/ScrollTab";
import {faCheck, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import {searchNavigate} from "./SearchLink";
import {Molecule09} from "../molecule/MoleculeList";
import {CustomLoader} from "../loading/CustomLoader";
import {useLocalStorage} from "@mantine/hooks";
import useKey from "../useKey";
import useResult from "../useResult";
import {AdvertisingGrid} from "../../components/advertising/Advertising";
import useIngredientAll from "../ingredient/useIngredientAll";
import useMoleculeAll from "../molecule/useMoleculeAll";
import useRecipeAll from "../recipe/useRecipeAll";
import {useLoadingContext} from "../../components/loading/LoadingContext";
import useLifecycle from "../useLifecycle";
import {IngredientIconTitle} from "../ingredient/IngredientLink";
import {MoleculeIconTitle} from "../molecule/MoleculeLink";
import {RecipeIconTitle} from "../recipe/RecipeLink";
import {useFeatures} from "../../components/features/useFeatures";
import {Sponsors} from "../../components/sponsor/Sponsor";
import i18n, {parameterLanguage} from "../../i18n";

/**
 * SearchPage
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const SearchPage = (props) => {

    const {paramSearch} = useParams();
    const {paramLng} = useParams();
    const lng = parameterLanguage(paramLng);

    const [searchIngredientFlavor, setSearchIngredientFlavor] = useLocalStorage({
        key: 'searchIngredientFlavor',
        defaultValue: false,
    });

    const [searchIngredientOdor, setSearchIngredientOdor] = useLocalStorage({
        key: 'searchIngredientOdor',
        defaultValue: false,
    });

    const [searchIngredientTaste, setSearchIngredientTaste] = useLocalStorage({
        key: 'searchIngredientTaste',
        defaultValue: false,
    });

    const [searchIngredientCompound, setSearchIngredientCompound] = useLocalStorage({
        key: 'searchIngredientCompound',
        defaultValue: false,
    });

    const [searchIngredientScientific, setSearchIngredientScientific] = useLocalStorage({
        key: 'searchIngredientScientific',
        defaultValue: false,
    });

    const [tabValue, setTabValue] = useState("0");

    const { t } = useTranslation();

    const [loadableRecipes, setLoadableRecipes] = useState(false);
    const [pageRecipes, setPageRecipes] = useState(1);
    const [previousTotalCountRecipes, setPreviousTotalCountRecipes] = useState(0);

    const [description, setDescription] = useState("");

    const navigate = useNavigate();

    const { Features } = useFeatures();

    const {isLoaded} = useLoadingContext();

    const {key, stateKey, random} =
        useKey({
            onKeyChange: () => {
                console.debug(`SearchPage`);

                setLoadableRecipes(false);
                setPageRecipes(1);
                setPreviousTotalCountRecipes(0);

                resetIngredients();
                resetRecipes();

                if(Features.search.features.molecules.plan.enabled && paramSearch !== undefined && paramSearch.length > 0) {
                    refetchMolecules();
                }
                else {
                    resetMolecules();
                }
            }
        });

    const { data: dataIngredients, isLoaded: isLoadedIngredients, totalCount: totalCountIngredients,
            reset: resetIngredients, refetch: refetchIngredients} =
        useIngredientAll({
            molecularSearchTypes: getMolecularSearchTypes(),
            search: paramSearch,
            enabled: paramSearch !== undefined && paramSearch.length > 0,
            onSuccess: () => {
                setLoadableRecipes(true);
            }
        })

    const { data: dataMolecules, isLoaded: isLoadedMolecules, totalCount: totalCountMolecules,
            reset: resetMolecules, refetch: refetchMolecules} =
        useMoleculeAll({
            search: paramSearch,
            enabled: Features.search.features.molecules.plan.enabled && paramSearch !== undefined && paramSearch.length > 0
        })

    const {	data: dataRecipes, isLoaded: isLoadedRecipes, totalCount: totalCountRecipes,
        	reset: resetRecipes, refetch: refetchRecipes} =
        useRecipeAll({
            page: pageRecipes,
            search: paramSearch,
            ingredientIds: dataIngredients?.sort((a, b) => a.ingredientId - b.ingredientId).map(item => item.ingredientId),
            enabled: loadableRecipes && isLoadedIngredients && paramSearch !== undefined && paramSearch.length > 0,
            onSuccess: (data, totalCount, count) => {
                setPreviousTotalCountRecipes(totalCount);
                setLoadableRecipes(false);
            }
        })

    const {isSuccess, isError} =
        useResult({
            isLoaded: isLoaded,
            isSuccess: !paramSearch || (isLoadedIngredients && isLoadedMolecules && isLoadedRecipes),
        })

    useEffect(() => {
        if(lng !== undefined && lng !== i18n.language) {
            i18n.changeLanguage(lng)
                .then(value => window.location.reload());
        }
    }, [lng]);

    useEffect(() => {
        refetchIngredients();
    }, [key, searchIngredientFlavor, searchIngredientOdor, searchIngredientTaste, searchIngredientCompound, searchIngredientScientific]);

    useEffect(() => {
        setDescription(randomSearchPlaceholder());
    }, [t]);

    useEffect(() => {
        setLoadableRecipes(true);
        refetchRecipes();

    }, [pageRecipes]);

    useLifecycle({
        onMount: () => {
        },
        onUnmount: () => {
            setLoadableRecipes(false);
            setPageRecipes(1);

            resetRecipes();
            resetIngredients();
            resetMolecules();
        }
    });

    /**
     * @returns Random search placeholder
     */
    function randomSearchPlaceholder() {
        return t(`search.${Math.floor(Math.random() * 5) + 1}`);
    }

    /**
     * @returns {string}
     */
    function getMolecularSearchTypes() {

        let result = '';

        if(searchIngredientFlavor) {
            if(result !== '') {
                result += ',';
            }
            result += `flavor`;
        }

        if(searchIngredientOdor) {
            if(result !== '') {
                result += ',';
            }
            result += `odor`;
        }

        if(searchIngredientTaste) {
            if(result !== '') {
                result += ',';
            }
            result += `taste`;
        }

        if(searchIngredientCompound) {
            if(result !== '') {
                result += ',';
            }
            result += `compound`;
        }

        if(searchIngredientScientific) {
            if(result !== '') {
                result += ',';
            }
            result += `scientific`;
        }

        return result;
    }

    /**
     * handleMolecularSearch
     */
    function handleMolecularSearch() {
        if(paramSearch && paramSearch.length > 0) {
            searchNavigate(navigate, paramSearch);
        }
    }

    /**
     * @returns {Element}
     * @constructor
     */
    const SearchCard = () => {

        return (
            <PageCard>

                <StripedTable stripedColor={"tertiary"} className={classes.searchcardtable}>
                    <Table.Tbody>
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <IngredientIconTitle/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                {!isLoadedIngredients && paramSearch?.length > 0 ?
                                    <CustomLoader justify={"flex-end"}/>
                                    :
                                    <Text>
                                        {totalCountIngredients || 0}
                                    </Text>
                                }
                            </Table.Td>
                        </Table.Tr>
                        {Features.search.features.molecules.plan.enabled &&
                            <Table.Tr>
                                <Table.Td pl={0}>
                                    <MoleculeIconTitle/>
                                </Table.Td>
                                <Table.Td pr={0} ta={"right"}>
                                    {!isLoadedMolecules && paramSearch?.length > 0 ?
                                        <CustomLoader justify={"flex-end"}/>
                                        :
                                        <Text>
                                            {totalCountMolecules || 0}
                                        </Text>
                                    }
                                </Table.Td>
                            </Table.Tr>
                        }
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <RecipeIconTitle/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                {!isLoadedRecipes && paramSearch?.length > 0 ?
                                    <CustomLoader justify={"flex-end"}/>
                                    :
                                    <Text>
                                        {previousTotalCountRecipes || 0}
                                    </Text>
                                }
                            </Table.Td>
                        </Table.Tr>
                    </Table.Tbody>
                </StripedTable>
            </PageCard>
        );
    }

    /**
     * ButtonChip
     *
     * @param props
     * @returns {Element}
     * @constructor
     */
    const ButtonChip = (props) => {

        const {checked, onClick = () => {}, children} = props;

        return <Button  mah={28} fw={400} radius={"xl"} size={"sm"} color={"secondary"} variant={checked ? "filled" : "light"}
                        leftSection={checked && <FontAwesomeIcon icon={faCheck}/>}
                        style={{color: checked ? "var(--mantine-color-white)" : "var(--mantine-color-secondary-9)" }}
                        {...props}>
            {children}
        </Button>
    }

    return (

        <Page
            pageTitle={t("common.search") + (paramSearch ? ` '${paramSearch.trim()}'` : "")}
            pageDescription={description}
            pageKeywords={"search"}
            pageUrl={"/search"}
            hasTab
            pageTitles={[
                <PageTitle
                    displayTitle={<Text lineClamp={2} inherit>{t("common.search") + (paramSearch ? ` '${paramSearch.trim()}'` : "")}</Text>}
                    titleIcon={<FontAwesomeIcon icon={faMagnifyingGlass}/>}
                    pt={"82px"}
                    withDescription
                    descriptionComponent={<Text size={"sm"} lineClamp={5}>{description}</Text>}
                    withCard
                    cardComponent = {<SearchCard />}
                    hasTabs
                    />

            ]}>

            <Tabs defaultValue="0" variant="outline" value={tabValue}>

                <Container>

                    <ScrollTab>
                        <ScrollTab.Tab selected={tabValue === "0"} onClick={() => setTabValue("0")}>
                            <IngredientIconTitle />
                        </ScrollTab.Tab>
                        {Features.search.features.molecules.plan.enabled &&
                            <ScrollTab.Tab selected={tabValue === "1"} onClick={() => setTabValue("1")}>
                                <MoleculeIconTitle />
                            </ScrollTab.Tab>
                        }
                        <ScrollTab.Tab selected={tabValue === "2"} onClick={() => setTabValue("2")}>
                            <RecipeIconTitle />
                        </ScrollTab.Tab>
                    </ScrollTab>

                    <AdvertisingGrid>

                        <Tabs.Panel value="0">

                            {   (Features.search.features.ingredientFlavors.plan.enabled ||
                                Features.search.features.ingredientOdors.plan.enabled ||
                                Features.search.features.ingredientTastes.plan.enabled ||
                                Features.search.features.ingredientCompounds.plan.enabled ||
                                Features.search.features.ingredientScientific.plan.enabled) &&
                                <>
                                    <Space h={"lg"}/>

                                    <SimpleBox color={"secondary"}>
                                        {/*<Title order={3}>*/}
                                        {/*    {t('molecule.molecularFilter')}*/}
                                        {/*</Title>*/}
                                        {/*<Space h="lg"/>*/}

                                        <Group justify={"space-between"}>
                                            <Text size={"sm"} fw={700}>{t("molecule.molecularFilter")}</Text>
                                            <Group gap={"xs"} justify={"center"}>

                                                {Features.search.features.ingredientFlavors.plan.enabled &&
                                                    <ButtonChip checked={searchIngredientFlavor} onClick={() => {setSearchIngredientFlavor(!searchIngredientFlavor); handleMolecularSearch();}}>
                                                        {t("molecule.flavors")}
                                                    </ButtonChip>
                                                }

                                                {Features.search.features.ingredientOdors.plan.enabled &&
                                                    <ButtonChip checked={searchIngredientOdor} onClick={() => {setSearchIngredientOdor(!searchIngredientOdor); handleMolecularSearch();}}>
                                                        {t("molecule.odors")}
                                                    </ButtonChip>
                                                }

                                                {Features.search.features.ingredientTastes.plan.enabled &&
                                                    <ButtonChip checked={searchIngredientTaste} onClick={() => {setSearchIngredientTaste(!searchIngredientTaste); handleMolecularSearch();}}>
                                                        {t("molecule.tastes")}
                                                    </ButtonChip>
                                                }

                                                {Features.search.features.ingredientCompounds.plan.enabled &&
                                                    <ButtonChip checked={searchIngredientCompound} onClick={() => {setSearchIngredientCompound(!searchIngredientCompound); handleMolecularSearch();}}>
                                                        {t("molecule.compounds")}
                                                    </ButtonChip>
                                                }

                                                {Features.search.features.ingredientScientific.plan.enabled &&
                                                    <ButtonChip checked={searchIngredientScientific} onClick={() => {setSearchIngredientScientific(!searchIngredientScientific); handleMolecularSearch();}}>
                                                        {t("molecule.scientific")}
                                                    </ButtonChip>
                                                }
                                            </Group>
                                        </Group>
                                    </SimpleBox>
                                </>
                            }

                            {!isLoadedIngredients || paramSearch === undefined || paramSearch?.length === 0 ? null :
                                <Ingredient09 ingredients={dataIngredients}
                                              title={
                                                  <>
                                                      <VisuallyHidden>
                                                          <Title order={2} pt={"lg"}>{t('common.ingredients')}</Title>
                                                      </VisuallyHidden>
                                                      <Space h="lg"/>
                                                  </>
                                              }
                                              showCategory
                                              ingredientTarget="_self"
                                              categoryTarget="_self"
                                />
                            }
                        </Tabs.Panel>

                        {!Features.search.features.molecules.plan.enabled || !isLoadedMolecules || paramSearch === undefined || paramSearch?.length === 0 ? null :
                            <Tabs.Panel value="1">

                                {/*<SimpleBox color={"secondary"}>*/}
                                {/*    <Title order={3}>*/}
                                {/*        {t('common.filter')}*/}
                                {/*    </Title>*/}
                                {/*    <Space h="lg"/>*/}
                                {/*</SimpleBox>*/}


                                    <Molecule09 molecules={dataMolecules}
                                                title={
                                                    <>
                                                        <VisuallyHidden>
                                                            <Title order={2} pt={"lg"}>{t('common.molecules')}</Title>
                                                        </VisuallyHidden>
                                                        <Space h="lg"/>
                                                    </>
                                                }
                                    />
                            </Tabs.Panel>
                        }

                        {!isLoadedIngredients || paramSearch === undefined || paramSearch?.length === 0 ? null :
                            <Tabs.Panel value="2">

                                {/*<SimpleBox color={"secondary"}>*/}
                                {/*    <Title order={3}>*/}
                                {/*        {t('common.filter')}*/}
                                {/*    </Title>*/}
                                {/*    <Space h="lg"/>*/}
                                {/*</SimpleBox>*/}


                                    <Recipe09 title={
                                                    <>
                                                        <VisuallyHidden>
                                                            <Title order={2} pt={"lg"}>{t('common.recipes')}</Title>
                                                        </VisuallyHidden>
                                                        <Space h="lg"/>
                                                    </>
                                                }
                                              recipes={dataRecipes}
                                              page={pageRecipes}
                                              onPageChange={setPageRecipes}
                                              totalCount={totalCountRecipes}
                                              recipeTarget="_self"
                                              categoryTarget="_self"/>

                                </Tabs.Panel>
                            }

                        <Space h={"lg"}/>
                        <Sponsors section={"search"}/>

                    </AdvertisingGrid>
                </Container>
            </Tabs>

            {/*TODO delete*/}
            {true ? null :
                <>
                    Search {paramSearch}
                    <br/>
                    Molecular Search Types {getMolecularSearchTypes()}
                    <br/>
                    Ingredients {totalCountIngredients}
                    <br/>
                    Molecules {totalCountMolecules}
                    <br/>
                    Recipes {totalCountRecipes}
                </>
            }
        </Page>
    );
}

export default SearchPage;