import moment from 'moment/moment';
import 'moment-duration-format';

/** DATE_TIME_FORMAT */
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

/** DATE_TIME_MS_FORMAT */
export const DATE_TIME_MS_FORMAT = 'DD.MM.YYYY HH:mm:ss.SSS';

/** DATE_FORMAT */
export const DATE_FORMAT = 'DD.MM.YYYY';

/** TIME_FORMAT */
export const TIME_FORMAT = 'HH:mm:ss';

/** TIME_MS_FORMAT */
export const TIME_MS_FORMAT = 'HH:mm:ss.SSS';

/** IS_NEW_FOR_DAYS */
export const IS_NEW_FOR_DAYS = 3;

/**
 * @param time
 */
export const toDateTime = (time) => {
    return toMoment(time || moment.now(), DATE_TIME_FORMAT);
}

/**
 * @param time
 */
export const toDateTimeMs = (time) => {
    return toMoment(time || moment.now(), DATE_TIME_MS_FORMAT);
}

/**
 * @param time
 */
export const toDate = (time) => {
    return toMoment(time || moment.now(), DATE_FORMAT);
}

/**
 * @param time
 */
export const toTime = (time) => {
    return toMoment(time || moment.now(), TIME_FORMAT);
}

/**
 * @param time
 */
export const toTimeMs = (time) => {
    return toMoment(time || moment.now(), TIME_MS_FORMAT);
}

/**
 * @param time
 */
export const isNew = (time, days = IS_NEW_FOR_DAYS) => {
    const end = moment(new Date());
    const start = moment(time);
    return end.diff(start, 'days') + 1 <= days;
}

/**
 * @param daysFromNow
 */
export const daysFromNow = (time) => {
    const start = moment(new Date());
    const end = moment(time);
    return end.diff(start, 'days');
}

/**
 * @param timeMs
 */
export const toDurationMs = (timeMs) => {
    return timeMs ? moment.duration(timeMs).format(TIME_MS_FORMAT) : 0;
}

/**
 * @param date
 * @param format
 */
export const toMoment = (date, format) => {
    return date ? moment(date).format(format) : null;
}

/**
 * @param isoDuration
 * @returns {*}
 */
export const formatIsoDuration = (isoDuration) => {

    if(!isoDuration) {
        return null;
    }

    const duration = moment.duration(isoDuration);
    return duration.format("h[h] m[min] s[s]", { trim: "both", usePlural: false });
}