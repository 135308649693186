import {useEffect} from 'react';
import {useEnvironmentContext} from "../environment/EnvironmentContext";
import i18n from "../../i18n";

/**
 * useAmazon
 *
 * https://www.oreilly.com/library/view/amazon-hacks/0596005423/ch01s07.html
 */
const useAmazon = () => {

    const {environment} = useEnvironmentContext();

    /**
     * AMAZON_DOMAINS
     */
    const AMAZON_DOMAINS = {
        // en: 'www.amazon.com',
        en: 'www.amazon.co.uk',
        de: 'www.amazon.de',
        it: 'www.amazon.it',
        es: 'www.amazon.es',
        fr: 'www.amazon.fr',
        pt: 'www.amazon.es'
    };

    /**
     * Success
     */
    useEffect(() => {

    }, []);

    /**
     * amazonAffiliateLink
     */
    function amazonAffiliateLink(asin) {
        return `https://${AMAZON_DOMAINS[i18n.language]}/dp/${asin}?tag=${environment.amazonTag}`;
    }

    /**
     * amazonProductImageUrl
     */
    function amazonProductImageUrl(asin, size = "xs") {

        /**
         * getImageSize
         */
        function getImageSize(size) {

            switch (size.toLowerCase()) {
                case "sm":
                case "md":
                    return "MZZZZZZZ";
                case "lg":
                case "xl":
                    return "LZZZZZZZ";
            }

            return "THUMBZZZ";
        }

        return `https://images.amazon.com/images/P/${asin}.01.${getImageSize(size)}.jpg`;
    }

    return {
        amazonAffiliateLink,
        amazonProductImageUrl
    };
};

export default useAmazon;