import React, {useEffect, useState} from "react";
import {
	RangeSlider,
	ScrollArea,
	Space,
	Stack,
	Table,
	Title,
	useMantineTheme
} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {
	compatibilityMarks,
	extractEntitiesNotInNodes,
	extractEntityPairings,
	extractMolecules,
	formatPc,
	getFirstInputNode, getSharedMoleculesPc, searchEntities, toEdge
} from "./StudioUtils";
import {localized} from "../../i18n";
import classes from "./Studio.module.css";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {Paginator} from "../../components/paginator/Paginator";
import {FlavorDbCredits} from "../../components/credits/Credits";
import {SearchInput} from "../../components/search/Search";
import {IngredientText} from "../ingredient/IngredientLink";
import {paginationEndSkip, paginationStartSkip} from "../../util/pagination";
import {useDnDContext} from "./DnDContext";

/**
 * EntityPairingsPanel
 */
export const EntityPairingsPanel = ({entities, nodes, selectedNodes, selectionChange, isOnlyInputConnectable = false}) => {

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(" ");
	const [searchItems, setSearchItems] = useState([]);

	const [minCompatibility, setMinCompatibility] = useState(undefined);
	const [maxCompatibility, setMaxCompatibility] = useState(undefined);

	const [lastSelectionChange, setLastSelectionChange] = useState(Date.now());

	const [moleculesIds, setMoleculesIds] = useState([]);
	const [entityPairings, setEntityPairings] = useState([]);
	const [draggable, setDraggable] = useState(false);

	const {t} = useTranslation();
	const theme = useMantineTheme();

	const [node, setNode, edge, setEdge] = useDnDContext();

	/**
	 * onDragStart
	 */
	const onDragStart = (event, entity) => {

		// Selected node
		const selectedNode = isOnlyInputConnectable ? getFirstInputNode(selectedNodes) : selectedNodes[0];

		if(selectedNode) {

			setNode({
				id: `${entity.entityId}`,
				type: "intermediate"
			});

			setEdge(toEdge(selectedNode.id, "default", entity, moleculesIds));
		}

		// Mouse support
		if (event.dataTransfer) {
			event.dataTransfer.effectAllowed = 'move';
		}
	};

	useEffect(() => {

		if(lastSelectionChange !== selectionChange) {

			console.debug("Details.EntityPairingsPanel.reset")

			setLastSelectionChange(selectionChange);

			setSearch("");
			setPage(1);
			setDraggable(false);
			setSearchItems([]);
			setMoleculesIds([]);
			setEntityPairings([]);
		}
	}, [selectionChange]);

	useEffect(() => {

		try {

			if(selectedNodes.length === 0) {
				return;
			}

			console.debug("Details.EntityPairingsPanel.update")

			setDraggable(selectedNodes.length === 1 &&
				(isOnlyInputConnectable ? selectedNodes[0]?.type === "input" : true));

			// Filter out entities already present on the canvas
			const entitiesNotInNodes = extractEntitiesNotInNodes(entities, nodes);

			// Get all selected entity molecules
			const moleculesIds = extractMolecules(entities, nodes, selectedNodes).map((molecule) => molecule.moleculeId);

			// Extract pairing
			const entityPairings = extractEntityPairings(entitiesNotInNodes, moleculesIds);

			// Calculate min/max compatibility range
			const compatibilities = lastSelectionChange !== selectionChange ?
				defaultCompatibilities(entityPairings, moleculesIds, compatibilityMarks) :
				[minCompatibility, maxCompatibility];

			setMinCompatibility(compatibilities[0]);
			setMaxCompatibility(compatibilities[1]);

			// Entity pairings filtered by min/max compatibility
			const compatibilityEntityPairings = entityPairings.filter(entityPairing => {
				const sharedMoleculesPc = getSharedMoleculesPc(entityPairing.sharedMolecules, moleculesIds);
				return sharedMoleculesPc >= (minCompatibility || compatibilities[0]) && sharedMoleculesPc <= (maxCompatibility || compatibilities[1]);
			});

			if(!search) {
				setSearchItems(compatibilityEntityPairings);
			}
			else {
				setSearchItems(searchEntities(compatibilityEntityPairings, search));
			}

			setMoleculesIds(moleculesIds);
			setEntityPairings(entityPairings);

		}
		catch (ignored) {
			// noop
		}

	}, [search, minCompatibility, maxCompatibility, entities, nodes.length, selectedNodes]);

	/**
	 * @param value
	 */
	const onSearch = (value) => {
		setSearch(value);
		setPage(1);
	}

	/**
	 * @param minCompatibility
	 * @param maxCompatibility
	 */
	const onMinMaxCompatibility = (minCompatibility, maxCompatibility) => {
		setMinCompatibility(minCompatibility);
		setMaxCompatibility(maxCompatibility);
		setPage(1);
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 */
	function entityPairingsComparator(a, b) {

		// Inverted comparison
		const pcComparison = b.sharedMolecules - a.sharedMolecules;
		if (pcComparison !== 0) {
			return pcComparison;
		}

		return localized(a.representativeIngredient, "name").localeCompare(localized(b.representativeIngredient, "name"));
	}

	/**
	 * @param marks
	 * @param value
	 * @param type
	 * @returns {*}
	 */
	function defaultCompatibilities(compatibilities, moleculesIds, marks) {

		/**
		 * @param marks
		 * @param value
		 * @param type
		 * @returns {*}
		 */
		function normalize(marks, value, type) {

			for (let i = 0; i < marks.length - 1; i++) {

				if (value <= marks[i + 1].value) {
					// For the minimum, return the lower step
					if (type === 'min') {
						return marks[i].value;
					}
					// For the maximum, return the upper step
					if (type === 'max') {
						return marks[i + 1].value;
					}
				}
			}
		}

		// Extract shared molecules percentage
		const sharedMoleculesPc = compatibilities?.map(compatibility => getSharedMoleculesPc(compatibility.sharedMolecules, moleculesIds));

		// Fin min and max
		const minValue = Math.min(...sharedMoleculesPc);
		const maxValue = Math.max(...sharedMoleculesPc);

		const minValueNormalized = normalize(marks, minValue, 'min');
		const maxValueNormalized = normalize(marks, maxValue, 'max');

		return [minValueNormalized !== undefined ? minValueNormalized : 0, maxValueNormalized !== undefined ? maxValueNormalized : 0];
	}

	return (
		<ScrollArea type={"hover"}
					offsetScrollbars={false}
					w={"100%"}
					h={"100%"}
					p={"md"}
					classNames={{
						scrollbar: classes.scrollbar,
						thumb: classes.thumb,
						corner: classes.corner
					}}>

			<SimpleBox color={"secondary"}>
				<Title order={3}>
					{t('ingredient.compatibilityRange')}
				</Title>
				<Space h="lg"/>
				<RangeSlider color={"secondary"}
							 minRange={20} step={20}
							 label={null}
							 classNames={{
								 track: classes.rangeslidertrack,
								 markLabel: classes.rangeslidermarklabel,
								 mark: classes.rangeslidermark
							 }}
							 marks={compatibilityMarks}
							 value={[minCompatibility, maxCompatibility]}
							 onChange={(value) => onMinMaxCompatibility(value[0], value[1])} />
				<Space h="lg"/>
			</SimpleBox>

			<Space h="lg"/>

			<Stack justify="space-between" gap="0">
				<StripedTable highlightOnHover>
					<Table.Thead>
						<Table.Tr>
							<Table.Th p={0} pb={"lg"}>
								<SearchInput
									value={search}
									placeholder={t('common.ingredients') + " " + searchItems.length}
									onChange={(value) => onSearch(value)}
								/>
							</Table.Th>
						</Table.Tr>
					</Table.Thead>
					<Table.Tbody>
						{searchItems
							.sort(entityPairingsComparator)
							.slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
							.map((item, index) => (
								<Table.Tr key={`entity-${index}`} style={{cursor: draggable ? "pointer" : "auto"}}
										  onDragStart={(event) => onDragStart(event, item)}
										  draggable={draggable}>

									<Table.Td style={{verticalAlign: "middle"}}>
										<IngredientText ingredient={item.representativeIngredient}
														ingredientHighlight={search} showCategory/>

										Mol {item.sharedMolecules} / Pc {formatPc(getSharedMoleculesPc(item.sharedMolecules,moleculesIds))}
									</Table.Td>
								</Table.Tr>
							))}
					</Table.Tbody>
				</StripedTable>

				<Stack>
					<Space h={"lg"}/>
					<Paginator color={"tertiary"} page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
							   selectedCount={searchItems.length} totalCount={entityPairings.length}/>

					<FlavorDbCredits pl={"md"} pt={"md"} i18nKey={"common.flavordbIngredientsAttribution"}/>
				</Stack>
			</Stack>
		</ScrollArea>
	);
}