import React from 'react';
import {Center, Paper} from '@mantine/core';
import classes from './LoadingPage.module.css';
import {Icon} from "../../components/icons/Icons";

/**
 * LoadingPage
 *
 * @returns {Element}
 */
export const LoadingPage = () => {

    return (
        <Paper className={classes.loadingpage}
               shadow={"none"}
               radius={0}>

            <Center className={classes.loader}>
                <Icon name={"logo"} className={classes.logo}/>
            </Center>
        </Paper>
    );
}