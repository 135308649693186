import React, {useEffect, useState} from 'react';
import {
	Button,
	Container, Group,
	Space,
	Stack,
	Tabs,
	Text,
} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {Page, PageTitle} from "../../components/page/Page";
import classes from "./HomePage.module.css";
import useKey from "../useKey";
import {useAccountContext} from "../../components/account/AccountContext";
import {useLoadingContext} from "../../components/loading/LoadingContext";
import {useApplicationContext} from "../../components/application/ApplicationContext";
import {EntityCategoryLink} from "../ingredient/EntityCategoryLink";
import {AdvertisingGrid} from "../../components/advertising/Advertising";
import RecipeCategoryLink from "../recipe/RecipeCategoryLink";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import useRecipeAll from "../recipe/useRecipeAll";
import RecipeLink, {RecipeIconTitle} from "../recipe/RecipeLink";
import useEntityAll from "../ingredient/useEntityAll";
import {
	IngredientIconTitle,
	IngredientLink, LinkedIngredients
} from "../ingredient/IngredientLink";
import {CustomCarousel} from "../../components/carousel/CustomCarousel";
import {ScrollTab} from "../../components/scrollTab/ScrollTab";
import {FlavorDbCredits, SchemaOrgCredits} from "../../components/credits/Credits";
import {Icon} from "../../components/icons/Icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faPlus, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {useScrollIntoView} from "@mantine/hooks";
import {theme} from "../../Theme";
import {PaymentDonateLink} from "../../components/payment/Payment";
import {Utensils} from "../recipe/UtensilLink";
import {faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons/faHeart";
import {SocialLink} from "../../components/social/Social";
import {Sponsors, SponsorsBanner} from "../../components/sponsor/Sponsor";
import useMedia from "../useMedia";
import {LanguageBanner} from "../../components/languageSelector/LanguageSelector";
import i18n, {parameterLanguage} from "../../i18n";
import {FeaturesBanner} from "../../components/features/Features";
import {useParams} from "react-router-dom";

/**
 * FoodPairingBanner
 */
const FoodPairingBanner = ({color = "tertiary"}) => {

	const {t} = useTranslation();
	const [left] = useState(Math.random() < 0.6);

	const FoodPairingIcon = () => <Icon name={"circle-overlap"} className={classes.foodpairingicon}/>

	return	<SimpleBox color={color}>
				<Group justify={"space-between"} align={"center"}>
					{left && <FoodPairingIcon />}
					<Stack gap={0} flex={1}>
						<Text flex={1} size={"sm"}>
							{t("ingredient.pairingDescription")}
						</Text>
					</Stack>
					{!left && <FoodPairingIcon />}
					{/*{left &&*/}
					{/*    <FontAwesomeIcon icon={faUpRightFromSquare} color={`var(--mantine-color-${color}-3)`} style={{width: "18px", height: "18px"}}/>*/}
					{/*}*/}
				</Group>
			</SimpleBox>
}

/**
 * Home
 */
const Home = (props) => {

	const {paramLng} = useParams();
	const lng = parameterLanguage(paramLng);

	const {isAuthenticated, userAccount } = useAccountContext();

    const {isLoading, isLoaded} = useLoadingContext();

	const [tabValue, setTabValue] = useState("0");

    const { t } = useTranslation();

	const {entityCategories, recipeCategories} = useApplicationContext();

	const { isSm } = useMedia();

	const { scrollIntoView, targetRef } = useScrollIntoView({
		offset: theme.custom.components.header.heightPx,
		smooth: true,
	});

	const {key, stateKey} =
        useKey({
			isLoading: isLoading,
			onKeyChange: () => {
				console.debug(`Home ` + stateKey);
				isLoaded();
			}
		});

	useEffect(() => {
		if(lng !== undefined && lng !== i18n.language) {
			i18n.changeLanguage(lng)
				.then(value => window.location.reload());
		}
	}, [lng]);

	/**
	 * Function to randomly select a component based on weights
	 */
	const getRandomWeightedComponent = (components) => {
		// Step 1: Build a weighted list
		// Each component is repeated in the list based on its weight
		const weightedList = components.flatMap(({ component, weight }) =>
			Array(weight).fill(component) // Add the component `weight` number of times
		);

		// Step 2: Pick a random index from the weighted list
		const randomIndex = Math.floor(Math.random() * weightedList.length);

		// Step 3: Return the randomly selected component
		return weightedList[randomIndex];
	};

	const RandomComponent = () => {
		// Get a random component using the weighted function
		const selectedComponent = getRandomWeightedComponent([
			{ component: <FeaturesBanner />, weight: 20, enabled: !isAuthenticated },
			{ component: <SocialLink random />, weight: 20, enabled: true },
			{ component: <LinkedIngredients />, weight: 15, enabled: true },
			{ component: <Utensils />, 			weight: 15, enabled: true },
			{ component: <SponsorsBanner />, weight: 10, enabled: true },
			{ component: <PaymentDonateLink />, weight: 10, enabled: true },
			{ component: <FoodPairingBanner />, weight: 5, enabled: true },
			{ component: <LanguageBanner />, weight: 5, enabled: i18n.language !== "en" },
		].filter(item => item.enabled));

		// Render the selected component
		return <>{selectedComponent}</>;
	};

	/**
	 * Entities
	 */
	const Entities = () => {

		/**
		 * EntitiesFromCategory
		 */
		const EntitiesFromCategory = ({category}) => {

			const {key, stateKey} =
				useKey({
					onKeyChange: () => {
						refetchItems();
					}
				});

			const { data: dataItems, isLoaded: isLoadedItems, totalCount: totalCountItems,
				reset: resetItems, refetch: refetchItems} =
				useEntityAll({
					entityCategoryId: category.entityCategoryId,
					random: theme.custom.home.randomIngredients,
				})

			return !isLoadedItems ? null :
				<CustomCarousel interval = {0}
								color={"secondary"}
								withControls={false}
								withIndicators={false}
								slideSize={{ base: '100%', sm: '50%'}}
								slidesToScroll={{ base: 1, sm: 2}}
								slideGap={"md"}>
					{dataItems?.map((item, index) =>
						<CustomCarousel.Slide>
							<IngredientLink entity={item} showCategory={false}/>
						</CustomCarousel.Slide>
					)}
				</CustomCarousel>
		}

		return (
			<>
				{entityCategories.map((category, index) =>
					<>
						{index % 3 === 0 &&
							<>
								<RandomComponent/>
								<Space h={"lg"}/>
							</>
						}

						<SimpleBox color={"secondary"}>
							<EntityCategoryLink entityCategory={category} withDescription
												style={{color: "var(--mantine-color-secondary-6)"}}
												styleDescription={{color: "var(--mantine-color-secondary-9)"}}/>
							<Space h={"lg"}/>
							<EntitiesFromCategory category={category}/>
						</SimpleBox>
						<Space h={"lg"}/>
					</>

				)}
			</>
		)
	}

	/**
	 * Recipes
	 */
	const Recipes = () => {

		/**
		 * RecipesFromCategory
		 */
		const RecipesFromCategory = ({category}) => {

			const {key, stateKey} =
				useKey({
					onKeyChange: () => {
						refetchItems();
					}
				});

			const { data: dataItems, isLoaded: isLoadedItems, totalCount: totalCountItems,
				reset: resetItems, refetch: refetchItems} =
				useRecipeAll({
					recipeCategoryId: category.recipeCategoryId,
					random: theme.custom.home.randomRecipes,
				})

			return !isLoadedItems ? null :
				<CustomCarousel interval = {0}
								color={"secondary"}
								withControls={false}
								withIndicators={false}
								slideSize={{ base: '100%', sm: '50%'}}
								slidesToScroll={{ base: 1, sm: 2}}
								slideGap={"md"}>
					{dataItems?.map((item, index) =>
						<CustomCarousel.Slide>
							<RecipeLink recipe={item} showCategory={false}/>
						</CustomCarousel.Slide>
					)}
				</CustomCarousel>
		}

		return (
			<>
				{recipeCategories.map((category, index) =>
					<>
						{index % 3 === 0 &&
							<>
								<RandomComponent/>
								<Space h={"lg"}/>
							</>
						}

						<SimpleBox color={"secondary"}>
							<RecipeCategoryLink recipeCategory={category} withDescription
												style={{color: "var(--mantine-color-secondary-6)"}}
												styleDescription={{color: "var(--mantine-color-secondary-9)"}}/>
							<Space h={"lg"}/>
							<RecipesFromCategory category={category}/>
						</SimpleBox>
						<Space h={"lg"}/>
					</>
				)}
			</>
		)
	}

    return (

        <Page
            pageTitle={t('home.title')}
			pageDescription={t('home.welcome0')}
			pageKeywords={"home"}
			pageUrl={`/`}
			pageImage={`${window.location.origin}/home-welcome-2.png`}
			svgZIndex={1}
			hasTabs
			pageTitles={[
				<PageTitle
					simpleText={
						<Stack align={"center"} style={{marginTop: isSm ? "calc(var(--mantine-spacing-md) * -4)" : "0"}}>
							<Icon name={"logo-full-v"} className={classes.homelogo} />
							<Text inherit className={classes.homesimpletext}>{t('home.welcome0')}</Text>
						</Stack>
					}
					backgroundImageSrc={"/home-welcome-0.png"}
					backgroundImageBlur={0}
					hasNavigationButton={false}
					hasHomeButton={false}
					hasTabs
				/>,
				<PageTitle
					simpleText={
						<Stack align={"center"} style={{marginTop: isSm ? "calc(var(--mantine-spacing-md) * -4)" : "0"}}>
							<Icon name={"leaf"} className={classes.homeicon} />
							<Text inherit className={classes.homesimpletext}>{t('home.welcome1', {name: isAuthenticated && userAccount?.getFirstName() ? `, ${userAccount.getFirstName()}, ` : ``})}</Text>
						</Stack>
					}
					backgroundImageSrc={"/home-welcome-1.png"}
					backgroundImageBlur={0}
					hasNavigationButton={false}
					hasHomeButton={false}
					hasTabs
				/>,
				<PageTitle
					simpleText={
						<Stack align={"center"} style={{marginTop: isSm ? "calc(var(--mantine-spacing-md) * -4)" : "0"}}>
							<FontAwesomeIcon icon={faHeartSolid} className={classes.homeicon + " " + classes.homeheartbeaticon}/>
							<Text inherit className={classes.homesimpletext}>{t('home.welcome2')}</Text>
						</Stack>
					}
					backgroundImageSrc={"/home-welcome-2.png"}
					backgroundImageBlur={0}
					hasNavigationButton={false}
					hasHomeButton={false}
					hasTabs
				/>,
				<PageTitle
					simpleText={
						<Stack align={"center"} gap={!isSm ? 0 : "md"} style={{marginTop: "calc(var(--mantine-spacing-md) * -6)"}}>
							<Group mah={"150px"}>
								<Icon name={"gpt"} className={classes.homegpticon} />
								<FontAwesomeIcon icon={faPlus} className={classes.homeplusicon}/>
								<Icon name={"hat-chef"} className={classes.homeicon} />
							</Group>

							<Text inherit className={classes.homesimpletext}>{t('recipe.actionCallGpt')}</Text>
							<Stack mt={!isSm ? "lg" : "xs"}>
								<Button component={"a"} size={!isSm ? "lg" : "xs"} color={"secondary"} variant={"filled"}
										style={{fontFamily: theme.fontFamily, color: "var(--mantine-color-white)"}}
										justify="space-between"
										leftSection={<Icon name={"gpt"} style={{width: "42px", height: "42px", padding: !isSm ? "0" : "12px", marginLeft: "-12px" }}/>}
										rightSection={<FontAwesomeIcon icon={faUpRightFromSquare}/>}
										target="_blank" href="https://chatgpt.com/g/g-FDauaPnt4-cuckookitchen-ai"
								>
									{t("recipe.getCookingNow")}
								</Button>
								<Button component={"a"} size={!isSm ? "lg" : "xs"} color={"secondary"} variant={"filled"}
										style={{fontFamily: theme.fontFamily, color: "var(--mantine-color-white)"}}
										justify="space-between"
										leftSection={<Icon name={"hat-chef"} style={{width: "18px", height: "18px", marginRight: "12px"}}/>}
										rightSection={<FontAwesomeIcon icon={faChevronRight} style={{paddingRight: "6px"}}/>}
										onClick={() => {setTabValue("1"); scrollIntoView();}}
								>
									{t("recipe.tryAiCreations")}
								</Button>
							</Stack>
							{isSm ? null :
								<Text size={"md"} mt={!isSm ? "lg" : "xs"} c={"white"} maw={!isSm ? "75%" : "100%"} style={{fontFamily: theme.fontFamily}}>
									{t("recipe.actionCallGptClause")}
								</Text>
							}
						</Stack>
					}
					backgroundImageSrc={"/recipes-gpt.png"}
					backgroundImageBlur={0}
					hasNavigationButton={false}
					hasHomeButton={false}
					hasTabs
				/>
			]}>

			{/*<Box className={classes.homesvgouter} visibleFrom={"sm"}>*/}
			{/*	<BackgroundSvg className={classes.homesvg}/>*/}
			{/*</Box>*/}

			<Tabs defaultValue="0" variant="outline" value={tabValue}>

				<Container>

					<ScrollTab>
						<ScrollTab.Tab selected={tabValue === "0"} onClick={() => setTabValue("0")}>
							<IngredientIconTitle />
						</ScrollTab.Tab>
						<ScrollTab.Tab selected={tabValue === "1"} onClick={() => setTabValue("1")}>
							<RecipeIconTitle />
						</ScrollTab.Tab>
					</ScrollTab>

					<AdvertisingGrid>

						<Tabs.Panel value="0">
							<Space h={"lg"}/>
							<Entities />
							<FlavorDbCredits pl={"md"} i18nKey={"common.flavordbIngredientsAttribution"}/>
						</Tabs.Panel>

						<Tabs.Panel value="1" ref={targetRef}>
							<Space h={"lg"}/>
							<Recipes />
							<SchemaOrgCredits pl={"md"} i18nKey={"common.schemaOrgAttribution"}/>
						</Tabs.Panel>

						<Space h={"lg"}/>
						<Sponsors section={"home"}/>

					</AdvertisingGrid>
				</Container>
			</Tabs>
        </Page>
	)
}

export default Home;