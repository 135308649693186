import {localized} from "../i18n";

export const WIKI_LINK_NAME = "wiki";
export const WIKI_NATURAL_SOURCE_LINK_NAME = "wikiNaturalSource";
export const AMAZON_ASIN_LINK_NAME = "amazonAsin";
export const FOOD_FACTS_VIDEO_ID_LINK_NAME = "foodFactsVideoId";
export const FOOD_FACTS_VIDEO_LINK_NAME = "foodFactsVideo";
export const LITTLE_STORIES_LINK_NAME = "littleStories";

/**
 * @param links
 * @param name
 * @returns {string|string}
 */
export const localizedLink = (links, name) => {

    if (!links) {
        return undefined;
    }

    const link = links.find(link => link.name === name);
    return link ? localized(link, "url") : undefined;
}