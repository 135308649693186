// TimerButton.js
import React, { useEffect, useState } from 'react';
import {Button, Text} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useTimerManagerContext } from "../../components/timermanager/TimerManagerContext";
import {localized} from "../../i18n";
import classes from './TimerButton.module.css';
import {useTranslation} from "react-i18next";

/**
 * TimerButton
 */
const TimerButton = ({ instruction, recipe, recipeImage, onClick = () => {} }) => {

	const { addTimer, removeTimer, getTimeLeft, timers } = useTimerManagerContext();
	const [timeLeft, setTimeLeft] = useState(0);
	const [isRunning, setIsRunning] = useState(false);

	const timerId = `${recipe.recipeId}-${instruction.recipeInstructionId}`;

	const {t} = useTranslation();

	// Parse ISO Duration to seconds
	const parseDurationInSeconds = (durationISO) => {
		return moment.duration(durationISO).asSeconds();
	};

	// Start timer with parsed duration and custom notification settings
	const startTimer = () => {
		const durationInSeconds = parseDurationInSeconds(instruction.time);
		addTimer(
			timerId,
			durationInSeconds,
			"recipe",
			{
				recipe: recipe,
				image: recipeImage,
				instruction: instruction
			}
		);
		setIsRunning(true);
	};

	// Stop the timer
	const stopTimer = () => {
		removeTimer(timerId);
		setIsRunning(false);
		setTimeLeft(0);
	};

	// Synchronize local timeLeft with TimerManager when navigating back to the page
	useEffect(() => {
		const updateTimerState = () => {
			const currentTimeLeft = getTimeLeft(timerId);
			if (currentTimeLeft > 0) {
				setTimeLeft(currentTimeLeft);
				setIsRunning(true);
			} else {
				setIsRunning(false);
				setTimeLeft(0);
			}
		};

		updateTimerState();

		const interval = setInterval(() => {
			updateTimerState();
		}, 1000);

		return () => clearInterval(interval);
	}, [getTimeLeft, timerId, timers]);

	// Format time as mm:ss
	const formatTime = (seconds) => {
		const mins = Math.floor(seconds / 60);
		const secs = seconds % 60;
		return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
	};

	return (
		<Button
			color="secondary"
			pl={10}
			pr={10}
			pt={2}
			pb={2}
			leftSection={<FontAwesomeIcon icon={faStopwatch} size="lg" />}
			onClick={(event) => {
				event.stopPropagation();
				event.preventDefault();
				isRunning ? stopTimer() : startTimer();
				onClick();
			}}
		>
			{isRunning ?
				<Text size={"sm"} className={classes.timerbuttonactive}>
					{formatTime(timeLeft)}
				</Text>
				:
				<Text size={"sm"}>
					{formatTime(parseDurationInSeconds(instruction.time))}
				</Text>
			}
		</Button>
	);
};

export default TimerButton;
