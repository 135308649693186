import React, {useEffect, useState} from "react";
import {Box, Group, ScrollArea, Table, Tabs, Text} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {extractMolecules, formatPc} from "./StudioUtils";
import {localized} from "../../i18n";
import {PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer} from "recharts";
import {ScrollTab} from "../../components/scrollTab/ScrollTab";
import classes from "./Studio.module.css";
import {Panel} from "@xyflow/react";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import {StripedTable} from "../../components/stripedTable/StripedTable";

/**
 * InfoPanel
 */
export const InfoPanel = ({position, entities, nodes, edges}) => {

	const { t } = useTranslation();
	const [averageSharedMoleculesPc, setAverageSharedMoleculesPc] = useState(0);

	useEffect(() => {
		setAverageSharedMoleculesPc(calculateAverageSharedMoleculesPc(edges));
	}, [entities, nodes, edges]);

	/**
	 * calculateAverageSharedMoleculesPc
	 */
	function calculateAverageSharedMoleculesPc(edges) {

		if (!edges || edges.length === 0) {
			return 0;
		}

		// Sum all sharedMoleculesPc values
		const total = edges.reduce((sum, edge) => {
			const sharedMoleculesPc = edge?.data?.sharedMoleculesPc || 0; // Fallback a 0 se non esiste
			return sum + sharedMoleculesPc;
		}, 0);

		return total / edges.length;
	}

	return <Panel position={position}>
		<SimpleBox color={"tertiary"}>
			<StripedTable striped={false} className={classes.infopaneltable}>
				<Table.Tbody>
					<Table.Tr>
						<Table.Td>
							<Text size={"xs"}>
								{t("common.ingredients")}
							</Text>
						</Table.Td>
						<Table.Td ta={"right"}>
							<Text size={"xs"} fw={700}>
								{nodes?.length || 0}
							</Text>
						</Table.Td>
					</Table.Tr>
					{edges?.length > 0 &&
						<Table.Tr>
							<Table.Td>
								<Text size={"xs"}>
									{t("ingredient.pairing")}
								</Text>
							</Table.Td>
							<Table.Td ta={"right"}>
								<Text size={"xs"} fw={700}>
									{edges?.length || 0}
								</Text>
							</Table.Td>
						</Table.Tr>
					}
					{averageSharedMoleculesPc > 0 &&
						<Table.Tr>
							<Table.Td>
								<Text size={"xs"}>
									{t("ingredient.compatibility")}
								</Text>
							</Table.Td>
							<Table.Td ta={"right"}>
								<Text size={"xs"} fw={700}>
									{formatPc(averageSharedMoleculesPc)}
								</Text>
							</Table.Td>
						</Table.Tr>
					}
				</Table.Tbody>
			</StripedTable>
		</SimpleBox>
	</Panel>
}

/**
 * EntityCategoryCharts
 */
export const EntityCategoryCharts = ({ entities, nodes, selectedNodes, maxItems = 10 }) => {

	const [tabValue, setTabValue] = useState("0");

	const { t } = useTranslation();

	console.debug("Statistics.EntityCategoryCharts.update")

	return (
		<Tabs defaultValue="0" variant="outline" value={tabValue} w={"100%"} h={"100%"}>
			{/* Tabs Header */}
			<ScrollTab mt={0} height={38}>
				<ScrollTab.Tab selected={tabValue === "0"} onClick={() => setTabValue("0")}>
					{t(`molecule.flavors`)}
				</ScrollTab.Tab>
				<ScrollTab.Tab selected={tabValue === "1"} onClick={() => setTabValue("1")}>
					{t(`molecule.odors`)}
				</ScrollTab.Tab>
				<ScrollTab.Tab selected={tabValue === "2"} onClick={() => setTabValue("2")}>
					{t(`molecule.tastes`)}
				</ScrollTab.Tab>
				<ScrollTab.Tab selected={tabValue === "3"} onClick={() => setTabValue("3")}>
					{t(`molecule.compounds`)}
				</ScrollTab.Tab>
			</ScrollTab>

			{/* Tabs Content */}
			<Tabs.Panel value="0" w={"100%"} h={"100%"}>
				<MolecularChart entities={entities} nodes={nodes} selectedNodes={selectedNodes} attribute={"flavors"} />
			</Tabs.Panel>

			<Tabs.Panel value="1" w={"100%"} h={"100%"}>
				<MolecularChart entities={entities} nodes={nodes} selectedNodes={selectedNodes} attribute={"odors"} />
			</Tabs.Panel>

			<Tabs.Panel value="2" w={"100%"} h={"100%"}>
				<MolecularChart entities={entities} nodes={nodes} selectedNodes={selectedNodes} attribute={"tastes"} />
			</Tabs.Panel>

			<Tabs.Panel value="3" w={"100%"} h={"100%"}>
				<MolecularChart entities={entities} nodes={nodes} selectedNodes={selectedNodes} attribute={"compounds"} />
			</Tabs.Panel>
		</Tabs>
	);
};

/**
 * MolecularCharts
 */
export const MolecularCharts = ({ entities, nodes, selectedNodes, maxItems = 10 }) => {

	const [tabValue, setTabValue] = useState("0");

	const { t } = useTranslation();

	console.debug("Statistics.MolecularCharts.update")

	return (
		<Tabs defaultValue="0" variant="outline" value={tabValue} w={"100%"} h={"100%"}>
			{/* Tabs Header */}
			<ScrollTab mt={0} height={38}>
				<ScrollTab.Tab selected={tabValue === "0"} onClick={() => setTabValue("0")}>
					{t(`molecule.flavors`)}
				</ScrollTab.Tab>
				<ScrollTab.Tab selected={tabValue === "1"} onClick={() => setTabValue("1")}>
					{t(`molecule.odors`)}
				</ScrollTab.Tab>
				<ScrollTab.Tab selected={tabValue === "2"} onClick={() => setTabValue("2")}>
					{t(`molecule.tastes`)}
				</ScrollTab.Tab>
				<ScrollTab.Tab selected={tabValue === "3"} onClick={() => setTabValue("3")}>
					{t(`molecule.compounds`)}
				</ScrollTab.Tab>
			</ScrollTab>

			{/* Tabs Content */}
			<Tabs.Panel value="0" w={"100%"} h={"100%"}>
				<MolecularChart entities={entities} nodes={nodes} selectedNodes={selectedNodes} attribute={"flavors"} />
			</Tabs.Panel>

			<Tabs.Panel value="1" w={"100%"} h={"100%"}>
				<MolecularChart entities={entities} nodes={nodes} selectedNodes={selectedNodes} attribute={"odors"} />
			</Tabs.Panel>

			<Tabs.Panel value="2" w={"100%"} h={"100%"}>
				<MolecularChart entities={entities} nodes={nodes} selectedNodes={selectedNodes} attribute={"tastes"} />
			</Tabs.Panel>

			<Tabs.Panel value="3" w={"100%"} h={"100%"}>
				<MolecularChart entities={entities} nodes={nodes} selectedNodes={selectedNodes} attribute={"compounds"} />
			</Tabs.Panel>
		</Tabs>
	);
};

/**
 * MolecularChart
 */
const MolecularChart = ({ entities, nodes, selectedNodes, selectionChange, attribute = "flavors", maxItems = 10 }) => {

	const { t } = useTranslation();

	const [data, setData] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [domain, setDomain] = useState([0, 100]);

	useEffect(() => {

		const molecules = extractMolecules(entities, nodes, selectedNodes?.length > 0 ? selectedNodes : nodes);

		/**
		 * Helper function to accumulate frequencies as percentages
		 * @param {Array} items - The array of items to process
		 * @returns {Object[]} - A sorted array of unique items with counts and percentages
		 */
		const accumulateFrequency = (items) => {

			// Ensure items array exists and is valid
			if (!items || items.length === 0) return [];

			// Count occurrences
			const totalItems = items.length;
			const frequencyMap = items.reduce((acc, item) => {
				const name = localized(item, "name"); // Use the localized name as the unique key
				if (name) {
					acc[name] = acc[name] || { name, count: 0 }; // Initialize if not present
					acc[name].count += 1; // Increment count
					acc[name].pc = acc[name].count / totalItems * 100;
				}
				return acc;
			}, {});

			// Convert the frequency map to an array
			const frequencyArray = Object.values(frequencyMap);

			// Sort by `pc` (descending) and then by `name` (alphabetical ascending)
			return frequencyArray.sort((a, b) => {
				if (b.pc === a.pc) {
					return a.name.localeCompare(b.name); // Sort alphabetically if `pc` is the same
				}
				return b.pc - a.pc; // Sort by `pc` (descending)
			});
		};

		// Update all data for the specified attribute (flavors, odors, tastes, compounds)
		const result = accumulateFrequency(
			molecules.flatMap((molecule) => molecule[attribute] || [])
		);

		setData(result);

	}, [entities, nodes, selectedNodes, attribute]);

	useEffect(() => {

		// Limit the chart data to maxItems
		const limitedChartData = data.slice(0, maxItems).map((item) => ({
			element: item.name,
			frequency: item.pc,
		}));

		setChartData(limitedChartData);

		// Calculate the domain for PolarRadiusAxis based on limited data
		if (limitedChartData.length > 0) {
			const frequencies = limitedChartData.map((item) => item.frequency);
			const max = Math.max(...frequencies); // Maximum percentage
			setDomain([0, Math.min(100, max)]); // Add padding for better visuals
		}

	}, [data, maxItems]);

	/**
	 * PolarRadiusAxisTick
	 */
	const PolarRadiusAxisTick = ({ payload, x, y, ...rest }) => {

		// if (payload.value === 0 || payload.value === 100) return null;

		return (
			<text
				x={x}
				y={y}
				dx={4}
				dy={14}
				// transform={`rotate(360 ${x} ${y})`} // Rotate label 90° clockwise
				fill="var(--mantine-color-tertiary-9)"
				fontSize={10}
				textAnchor="left"
			>
				{`${formatPc(payload.value)}`}
			</text>
		);
	};

	// Render the graphics section dynamically based on the attributes prop
	return chartData === undefined || chartData.length === 0 ? null :
		<Box bg={"var(--mantine-color-tertiary-outline-hover)"} w={"100%"} h={"100%"} p={"md"} pr={"xs"}>

			<Group align={"flex-start"} w={"100%"} h={"100%"} grow>

				<ResponsiveContainer>
					<RadarChart data={chartData} outerRadius="70%">

						<PolarGrid stroke="var(--mantine-color-tertiary-9)" strokeOpacity={0.25}/>

						<PolarAngleAxis dataKey="element"
										tick={{
											fill: "var(--mantine-color-tertiary-9)",
											fontSize: 12,
											fontWeight: 700,
										}}
						/>

						<PolarRadiusAxis angle={90}
										 domain={domain} // Dynamic domain
										 tick={<PolarRadiusAxisTick />}/>

						<Radar isAnimationActive={false} dataKey="frequency"
							   stroke={"var(--mantine-color-tertiary-6)"}
							   fill={"var(--mantine-color-tertiary-6)"}
							   fillOpacity={0.25}
						/>
					</RadarChart>
				</ResponsiveContainer>

				<ScrollArea type={"auto"}
							w={"100%"}
							h={"100%"}
							offsetScrollbars={true}
							classNames={{
								scrollbar: classes.scrollbar,
								thumb: classes.thumb,
								corner: classes.corner
							}}>
					{data.map((item, index) => (
						<Group justify={"space-between"} pl={"xs"} pr={"xs"} pt={"xs"} pb={"xs"} wrap={"nowrap"}
							   bg={index % 2 === 0 ? "var(--mantine-color-tertiary-outline-hover)" : "transparent"}>
							<Text c={"tertiary.9"} size="xs" fw={index < maxItems ? 700 : 400} ta={"left"}>{item.name}</Text>
							<Group gap={"xs"} w={"50%"} grow>
								<Text c={"tertiary.9"} size="xs" fw={index < maxItems ? 700 : 400} ta={"right"}>{item.count}</Text>
								<Text c={"tertiary.9"} size="xs" fw={index < maxItems ? 700 : 400} ta={"right"}>{formatPc(item.pc)}</Text>
							</Group>
						</Group>
					))}

					<Text size={"xs"} c={"tertiary.9"} pt={"lg"}>
						{t(`molecule.${attribute}Description`)}
					</Text>
				</ScrollArea>
			</Group>
		</Box>
};