import React, {useState} from "react";
import {ActionIcon, Anchor, Group, Image, Stack, Text} from '@mantine/core';
import classes from './Social.module.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faEtsy,
    faFacebook,
    faInstagram, faLinkedin,
    faPinterest,
    faTiktok,
    faXTwitter,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import {Icon} from "../icons/Icons";
import {useTranslation} from "react-i18next";
import {SimpleBox} from "../simpleBox/SimpleBox";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";

// Define social types with weights
const typesWithWeights = [
    { type: "gpt", weight: 50 },
    { type: "youtube", weight: 25 },
    { type: "tiktok", weight: 25 },
    { type: "instagram", weight: 25 },
    { type: "facebook", weight: 25 },
    { type: "x", weight: 5 },
    { type: "pinterest", weight: 5 },
    { type: "linkedin", weight: 5 },
    { type: "etsy", weight: 5 },
];

/**
 * SocialLink
 */
export const SocialLink = ({type = undefined, random = false, color = "primary"}) => {

    const {t} = useTranslation();

    // Function to get a weighted random type
    const getRandomWeightedType = (typesWithWeights) => {

        // Calculate total weight
        const totalWeight = typesWithWeights.reduce((sum, { weight }) => sum + weight, 0);

        // Generate a random number within the total weight
        let randomValue = Math.random() * totalWeight;

        // Iterate through the types and subtract their weights until the random value falls into a range
        for (const { type, weight } of typesWithWeights) {
            if (randomValue < weight) {
                return type;
            }
            randomValue -= weight;
        }

        return null; // Fallback, should never happen
    };

    function getSocial(type) {

        switch(type.toLowerCase()) {

            case "gpt":
                return {
                    description: t("social.gptDescription"),
                    url: "https://chatgpt.com/g/g-FDauaPnt4-cuckookitchen-ai",
                    icon: <Icon name={"gpt"} className={classes.sociallinksvg} style={{fill: `var(--mantine-color-${color}-6)`}}/>
                }

            case "youtube":
                return {
                    description: t("social.youtubeDescription"),
                    url: "https://www.youtube.com/@cuckookitchen",
                    icon: <FontAwesomeIcon icon={faYoutube} className={classes.sociallinkicon} color={`var(--mantine-color-${color}-6)`}/>
                }

            case "tiktok":
                return {
                    description: t("social.tiktokDescription"),
                    url: "https://www.tiktok.com/@cuckookitchen",
                    icon: <FontAwesomeIcon icon={faTiktok} className={classes.sociallinkicon} color={`var(--mantine-color-${color}-6)`}/>
                }

            case "instagram":
                return {
                    description: t("social.instagramDescription"),
                    url: "https://www.instagram.com/cuckookitchenai",
                    icon: <FontAwesomeIcon icon={faInstagram} className={classes.sociallinkicon} color={`var(--mantine-color-${color}-6)`}/>
                }

            case "facebook":
                return {
                    description: t("social.facebookDescription"),
                    url: "https://www.facebook.com/cuckookitchenai",
                    icon: <FontAwesomeIcon icon={faFacebook} className={classes.sociallinkicon} color={`var(--mantine-color-${color}-6)`}/>
                }

            case "x":
                return {
                    description: t("social.xDescription"),
                    url: "https://x.com/cuckookitchenai",
                    icon: <FontAwesomeIcon icon={faXTwitter} className={classes.sociallinkicon} color={`var(--mantine-color-${color}-6)`}/>
                }

            case "pinterest":
                return {
                    description: t("social.pinterestDescription"),
                    url: "https://ch.pinterest.com/cuckookitchenai",
                    icon: <FontAwesomeIcon icon={faPinterest} className={classes.sociallinkicon} color={`var(--mantine-color-${color}-6)`}/>
                }

            case "linkedin":
                return {
                    description: t("social.linkedinDescription"),
                    url: "https://www.linkedin.com/in/media-cuckookitchen-04012926b/",
                    icon: <FontAwesomeIcon icon={faLinkedin} className={classes.sociallinkicon} color={`var(--mantine-color-${color}-6)`}/>
                }

            case "etsy":
                return {
                    description: t("social.etsyDescription"),
                    url: "https://www.etsy.com/shop/CuckooKitchen",
                    icon: <FontAwesomeIcon icon={faEtsy} className={classes.sociallinkicon} color={`var(--mantine-color-${color}-6)`}/>
                }
        }
    }

    const [social] = useState(getSocial(random ? getRandomWeightedType(typesWithWeights) : type));
    const [left] = useState(Math.random() < 0.4);

    return social &&
        <Anchor href={social.url}
                target={"_blank"} underline="never">

            <SimpleBox color={color} highlightOnHover>
                <Group justify={"space-between"} align={"center"}>
                    {left && social.icon}
                    <Stack gap={0} flex={1}>
                        <Text flex={1}>
                            {social.description}
                        </Text>
                    </Stack>
                    {!left && social.icon}
                    {/*{left &&*/}
                    {/*    <FontAwesomeIcon icon={faUpRightFromSquare} color={`var(--mantine-color-${color}-3)`} style={{width: "18px", height: "18px"}}/>*/}
                    {/*}*/}
                </Group>
            </SimpleBox>
        </Anchor>
};

/**
 * Social
 */
export const Social = ({justify, variant = "subtle"}) => {

    return (
        <Group gap={0} className={classes.social} justify={justify ? justify : 'flex-start'} >
            <ActionIcon size='lg' variant={variant} color="white"
                        component="a"
                        target="_blank"
                        href="https://chatgpt.com/g/g-FDauaPnt4-cuckookitchen-ai">
                <Icon name={"gpt"} style={{width: "28px", fill: "var(--mantine-color-white)"}} />
            </ActionIcon>
            <ActionIcon size='lg' variant={variant} color="white"
                        component="a"
                        target="_blank"
                        href="https://www.youtube.com/@cuckookitchen">
                <FontAwesomeIcon icon={faYoutube} size={"lg"}/>
            </ActionIcon>
            <ActionIcon size='lg' variant={variant} color="white"
                        component="a"
                        target="_blank"
                        href="https://www.tiktok.com/@cuckookitchen">
                <FontAwesomeIcon icon={faTiktok} size={"lg"}/>
            </ActionIcon>
            <ActionIcon size='lg' variant={variant} color="white"
                        component="a"
                        target="_blank"
                        href="https://www.instagram.com/cuckookitchenai">
                <FontAwesomeIcon icon={faInstagram} size={"lg"}/>
            </ActionIcon>
            <ActionIcon size='lg' variant={variant} color="white"
                        component="a"
                        target="_blank"
                        href="https://www.facebook.com/cuckookitchenai">
                <FontAwesomeIcon icon={faFacebook} size={"lg"}/>
            </ActionIcon>
            <ActionIcon size='lg' variant={variant} color="white"
                        component="a"
                        target="_blank"
                        href="https://x.com/cuckookitchenai">
                <FontAwesomeIcon icon={faXTwitter} size={"lg"}/>
            </ActionIcon>
            <ActionIcon size='lg' variant={variant} color="white"
                        component="a"
                        target="_blank"
                        href="https://ch.pinterest.com/cuckookitchenai">
                <FontAwesomeIcon icon={faPinterest} size={"lg"}/>
            </ActionIcon>
            <ActionIcon size='lg' variant={variant} color="white"
                        component="a"
                        target="_blank"
                        href="https://www.linkedin.com/in/media-cuckookitchen-04012926b/">
                <FontAwesomeIcon icon={faLinkedin} size={"lg"}/>
            </ActionIcon>
            <ActionIcon size='lg' variant={variant} color="white"
                        component="a"
                        target="_blank"
                        href="https://www.etsy.com/shop/CuckooKitchen">
                <FontAwesomeIcon icon={faEtsy} size={"lg"}/>
            </ActionIcon>
        </Group>
    )
}