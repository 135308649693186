import React from "react";
import {Button, Text, useMantineTheme} from "@mantine/core";
import {Trans} from "react-i18next";
import useMedia from "../../pages/useMedia";

/**
 * WikipediaCredits
 */
export const WikipediaCredits = (props) => {

    const {i18nKey, textColor = "var(--mantine-color-tertiary-9)", linkColor = "var(--mantine-color-tertiary-9)", wikiUrl} = props;

    return (
        <Text size="xs" fs="italic" c={textColor} {...props}>
            <Trans i18nKey={i18nKey}>
                <Button component="a" size={"xs"} variant='subtle' color={linkColor} p={0} pl={3} pr={3} mt={-1} href="https://www.wikipedia.org/" target="_blank">site</Button>
                <Button component="a" size={"xs"} variant='subtle' color={linkColor} p={0} pl={3} pr={3} mt={-1} href="https://creativecommons.org/licenses/by-sa/3.0/" target="_blank">licence</Button>
                <Button component="a" size={"xs"} variant='subtle' color={linkColor} p={0} pl={3} pr={3} mt={-1} href={wikiUrl} target="_blank">more</Button>
            </Trans>
        </Text>
    )
}

/**
 * FlavorDbCredits
 */
export const FlavorDbCredits = (props) => {

    const {i18nKey, textColor = "var(--mantine-color-tertiary-9)", linkColor = "var(--mantine-color-tertiary-9)"} = props;

    const theme = useMantineTheme();
    const { isSm } = useMedia();

    return (
        <Text size="xs" fs="italic" c={textColor} opacity={0.5} ta={isSm ? "center" : "left"} {...props}>
            <Trans i18nKey={i18nKey}>
                <Button component="a" size={"xs"} variant='subtle' color={linkColor} p={0} pl={3} pr={3} mt={-1} href="https://cosylab.iiitd.edu.in/flavordb2/" target="_blank">site</Button>
                <Button component="a" size={"xs"} variant='subtle' color={linkColor} p={0} pl={3} pr={3} mt={-1} href="https://creativecommons.org/licenses/by-nc-sa/3.0/" target="_blank">licence</Button>
            </Trans>
        </Text>
    )
}

/**
 * FoodDataCentralCredits
 */
export const FoodDataCentralCredits = (props) => {

    const {i18nKey, textColor = "var(--mantine-color-tertiary-9)", linkColor = "var(--mantine-color-tertiary-9)"} = props;

    const theme = useMantineTheme();
    const { isSm } = useMedia();

    return (
        <Text size="xs" fs="italic" c={textColor} opacity={0.5} ta={isSm ? "center" : "left"} {...props}>
            <Trans i18nKey={i18nKey}>
                <Button component="a" size={"xs"} variant='subtle' color={linkColor} p={0} pl={3} pr={3} mt={-1} href="https://fdc.nal.usda.gov/" target="_blank">site</Button>
                <Button component="a" size={"xs"} variant='subtle' color={linkColor} p={0} pl={3} pr={3} mt={-1} href="https://creativecommons.org/publicdomain/zero/1.0/deed.en" target="_blank">licence</Button>
            </Trans>
        </Text>
    )
}

/**
 * SchemaOrgCredits
 */
export const SchemaOrgCredits = (props) => {

    const {i18nKey, textColor = "var(--mantine-color-tertiary-9)", linkColor = "var(--mantine-color-tertiary-9)"} = props;

    const theme = useMantineTheme();
    const { isSm } = useMedia();

    return (
        <Text size="xs" fs="italic" c={textColor} opacity={0.5} ta={isSm ? "center" : "left"} {...props}>
            <Trans i18nKey={i18nKey}>
                <Button component="a" size={"xs"} variant='subtle' color={linkColor} p={0} pl={3} pr={3} mt={-1} href="https://schema.org/" target="_blank">site</Button>
                <Button component="a" size={"xs"} variant='subtle' color={linkColor} p={0} pl={3} pr={3} mt={-1} href="https://schema.org/Recipe" target="_blank">recipe</Button>
            </Trans>
        </Text>
    )
}

/**
 * Auth0Credits
 */
export const Auth0Credits = (props) => {

    const {i18nKey, textColor = "var(--mantine-color-tertiary-9)", linkColor = "var(--mantine-color-tertiary-9)"} = props;

    const theme = useMantineTheme();
    const { isSm } = useMedia();

    return (
        <Text size="xs" fs="italic" c={textColor} opacity={0.5} ta={isSm ? "center" : "left"} {...props}>
            <Trans i18nKey={i18nKey}>
                <Button component="a" size={"xs"} variant='subtle' color={linkColor} p={0} pl={3} pr={3} mt={-1} href="https://auth0.com/" target="_blank">site</Button>
            </Trans>
        </Text>
    )
}

/**
 * StripeCredits
 */
export const StripeCredits = (props) => {

    const {i18nKey, disabled = false, textColor = "var(--mantine-color-tertiary-9)", linkColor = "var(--mantine-color-tertiary-9)"} = props;

    const theme = useMantineTheme();
    const { isSm } = useMedia();

    return (
        <Text size="xs" fs="italic" c={textColor} opacity={0.5} ta={isSm ? "center" : "left"} {...props}>
            <Trans i18nKey={i18nKey}>
                <Button component="a" size={"xs"} variant='subtle' color={linkColor} p={0} pl={3} pr={3} mt={-1} href="https://stripe.com/" target="_blank"
                style={{pointerEvents: disabled ? "none" : "auto"}}
                >site</Button>
            </Trans>
        </Text>
    )
}