import {keyState} from "../useKey";

/**
 * searchNavigate
 *
 * @returns {React.JSX.Element|undefined}
 * @constructor
 */
export function searchNavigate(navigate, search, target = "_self") {

    if(target === "_self") {
        navigate(`/search/${search || ""}`, {state: {...keyState()}});
    }
    else {
        window.open(`/search/${search || ""}`, "_blank");
    }
}