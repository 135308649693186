// TimerManager.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import {toDateTime} from "../../util/time";
import {notification} from "./Notifications";

/**
 * TimerManagerContext
 */
const TimerManagerContext = createContext();

/**
 * useTimerManagerContext
 */
export const useTimerManagerContext = () => useContext(TimerManagerContext);

/**
 * TimerManagerProvider
 */
export const TimerManagerProvider = ({ children }) => {

	const [timers, setTimers] = useState(() => JSON.parse(localStorage.getItem('timerManagerTimers')) || {});

	// Check all timers every second and update time left
	useEffect(() => {

		const interval = setInterval(() => {
			const now = Date.now();
			const updatedTimers = { ...timers };

			Object.keys(updatedTimers).forEach((timerId) => {
				const { endTime } = updatedTimers[timerId];
				const timeLeft = Math.floor((endTime - now) / 1000);

				if (timeLeft <= 0) {
					// Timer expired
					completeTimer(timerId);
					delete updatedTimers[timerId];
				} else {
					// Update remaining time based on endTime
					updatedTimers[timerId].timeLeft = timeLeft;
				}
			});

			setTimers(updatedTimers);
			localStorage.setItem('timerManagerTimers', JSON.stringify(updatedTimers));
		}, 1000);

		return () => clearInterval(interval);
	}, [timers]);

	const addTimer = (timerId, durationInSeconds, type, payload) => {
		const startTime = Date.now();
		const endTime = startTime + durationInSeconds * 1000;
		const updatedTimers = {
			...timers,
			[timerId]: { startTime, endTime, timeLeft: durationInSeconds, type, payload },
		};

		setTimers(updatedTimers);
		localStorage.setItem('timerManagerTimers', JSON.stringify(updatedTimers));
	};

	const removeTimer = (timerId) => {
		const updatedTimers = { ...timers };
		delete updatedTimers[timerId];
		setTimers(updatedTimers);
		localStorage.setItem('timerManagerTimers', JSON.stringify(updatedTimers));
	};

	const completeTimer = (timerId) => {
		const timer = timers[timerId];
		if (timer) {
			showNotification(notification(timer.startTime, timer.endTime, timer.type, timer.payload));
			removeTimer(timerId);
		}
	};

	const getTimeLeft = (timerId) => timers[timerId]?.timeLeft || 0;

	return (
		<TimerManagerContext.Provider value={{ addTimer, removeTimer, getTimeLeft, timers }}>
			{children}
		</TimerManagerContext.Provider>
	);
};
