import classes from "./Amazon.module.css";
import {useTranslation} from "react-i18next";
import {
	Anchor,
	Box, Card,
	Center,
	Container,
	Group,
	Highlight,
	Image,
	Overlay,
	Stack,
	Text,
	Transition
} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faImage, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {localized} from "../../i18n";
import React, {useState} from "react";
import useAmazon from "./useAmazon";
import {AMAZON_ASIN_LINK_NAME, localizedLink} from "../../util/links";
import {faAmazon} from "@fortawesome/free-brands-svg-icons";

/**
 * Image169WithZoom
 */
const Image169WithZoom = ({src, utensil, showDescription = true, highlight, hovered, color= "secondary"}) => {

	const {t} = useTranslation();

	return (
		<Box className={classes.amazonlinkimage169root}>

			{!src ?
				<Center className={classes.amazonlinkimage169noimage}>
					<FontAwesomeIcon icon={faImage} size={"8x"} color={`var(--mantine-color-${color}-light-hover)`}/>
				</Center>
				:
				<>
					<Transition
						mounted={true}
						transition="scale"
						timingFunction="ease-in-out">
						{(styles) => (
							<>
								<Image
									src={src}
									className={classes.amazonlinkimage169image}
									style={{
										transform: hovered? 'scale(1.1)' : 'scale(1)',  // Scale on hover
										transition: 'transform 0.15s ease-in-out',  // Smooth transition
									}}
									fallbackSrc={"/image-placeholder-tertiary.png"}
								/>
							</>
						)}
					</Transition>
					<Box className={classes.amazonlinkimage169righticon}>
						<Box className={classes.amazonlinkimage169withzoomicon}>
							<Center>
								<FontAwesomeIcon icon={faAmazon} size={"lg"}/>
							</Center>
						</Box>
					</Box>
				</>
			}

			<Container className={classes.amazonlinkimage169overlay} p={"md"}>
				<Overlay color={`var(--mantine-color-${color}-6)`} backgroundOpacity={!hovered ? 0.8 : 0.95} blur={0} zIndex={1}/>

				<Group gap={0} justify="space-between" wrap={"nowrap"} className={classes.amazonlinkimage169title}>
					<Stack gap={0} align={"flex-start"}>
						<Text c={"white"}>
							<Highlight highlight={highlight}>
								{localized(utensil, 'name')}
							</Highlight>
						</Text>
						{!showDescription ? null :
							<Text size={'xs'} opacity={0.75} c={"white"}>
								{localized(utensil, 'description')}
							</Text>
						}
					</Stack>
					<FontAwesomeIcon icon={faUpRightFromSquare} color={"white"} />
				</Group>
			</Container>
		</Box>
	);
};

/**
 * AmazonLink
 */
export const AmazonLink = ({utensil, showDescription = true, highlight, color= "secondary"}) => {

	const [hovered, setHovered] = useState(false);
	const {t} = useTranslation();

	const {amazonAffiliateLink, amazonProductImageUrl} = useAmazon();

	const amazonAsin = localizedLink(utensil.links, AMAZON_ASIN_LINK_NAME);

	return (
		<Anchor href={amazonAsin ? amazonAffiliateLink(amazonAsin) : undefined}
				target={"_blank"} underline="never"
				className={classes.amazonlinkroot}>

			<Card shadow="none" padding="0"
				// withBorder style={{border: "2px solid var(--mantine-color-primary-light-hover)"}}
				  style={{height: "100%"}}
				  bg={hovered ? `var(--mantine-color-${color}-light-hover)` : `var(--mantine-color-${color}-outline-hover)`}
				  onMouseEnter={() => setHovered(true)}
				  onMouseLeave={() => setHovered(false)}
			>
				<Card.Section>
					<Image169WithZoom src={amazonAsin ? amazonProductImageUrl(amazonAsin, "lg") : undefined}
									  utensil={utensil} showDescription={showDescription} highlight={highlight}
									  hovered={hovered} color={color}/>
				</Card.Section>
			</Card>
		</Anchor>
	)
}