import React from 'react';
import {Box, Button, Collapse, Group, Modal, ScrollArea, Space, Text} from '@mantine/core';
import classes from './ErrorPage.module.css';
import {useTranslation} from "react-i18next";
import {useErrorContext} from "../../components/error/ErrorContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {useDisclosure} from "@mantine/hooks";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import useMedia from "../useMedia";

/**
 * ErrorPage
 *
 * @returns {Element}
 */
export const ErrorPage = () => {

    const [collapseOpened, { toggle: collapseToggle }] = useDisclosure(false);

    const { isSm } = useMedia();

    const { t } = useTranslation();
    const {error} = useErrorContext();

    return (
        <Modal opened={true}
               color={"primary"}
               centered
               closeOnClickOutside={false}
               withCloseButton={false}
               size={"lg"}
               overlayProps={{color: "var(--mantine-color-primary-12)", backgroundOpacity: 1}}
               zIndex={404}
               onClose={() => window.location.reload()}
               classNames={{
                   root: classes.modalroot,
                   header: classes.modalheader,
                   content: classes.modalcontent,
                   inner: classes.modalinner,
                   body: classes.modalbody,
               }}
            >
            <Modal.Header>
                <Group justify={"center"} style={{width: "100%", height: "15vh", textAlign: "center"}}>
                    <FontAwesomeIcon icon={faTriangleExclamation} size={"6x"}/>
                </Group>
            </Modal.Header>

            <SimpleBox color={"primary"}>
                <Text >
                    {t("common.errorMessage")}
                </Text>
            </SimpleBox>
            <Space h={"lg"}/>

            <SimpleBox color={"primary"}>
                <Box>
                    <Button p={0} variant={"transparent"} justify="space-between" w={"100%"} color={"var(--mantine-color-primary-9)"} onClick={collapseToggle}
                            rightSection={<FontAwesomeIcon icon={faChevronDown}/>}
                    >{t("common.forNerdsOnly")}</Button>

                    <Collapse in={collapseOpened}>
                        <ScrollArea h={250}>
                            <Text size={"xs"}>
                            <pre style={{textWrap: "wrap"}}>{JSON.stringify(error, null, 4)}</pre>
                            </Text>
                        </ScrollArea>
                    </Collapse>
                </Box>
            </SimpleBox>

            <Space h={"lg"}/>

            <Group justify="center">
                <Group justify={!isSm ? "flex-end" : "space-between"} pt={"md"}>
                    <Button color={"var(--mantine-color-primary-9)"} miw={140} onClick={() =>  window.open(`/`, "_self")}>{t("common.retry")}</Button>
                </Group>
            </Group>

        </Modal>
    );
}