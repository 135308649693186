import {useCallback, useEffect} from "react";
import {useWindowEvent} from "@mantine/hooks";

/**
 * useWindowPopstate
 *
 * @returns {{}}
 *
 * @example
 *
 *     useWindowPopstate((url) => {
 *         console.log("url changed " + url)
 *     })
 * @param onUrlChange
 */
export const useWindowPopstate = (onUrlChange) => {

    const handle = useCallback((event) => {
        event.preventDefault();
        onUrlChange(window.location.pathname);
    }, []);

    useWindowEvent('popstate', (event) => {
        handle(event);
    });

    // useEffect(() => {
    //
    //     // Catch back and forward button and convert in navigate
    //     const handlePopState = (event) => {
    //
    //         event.preventDefault(); // Prevent the default back button action
    //
    //         onUrlChange(window.location.pathname)// Get the current URL
    //     };
    //
    //     // Add event listener for popstate (browser back/forward navigation)
    //     window.addEventListener('popstate', handlePopState);
    //
    //     return () => {
    //         // Cleanup event listener when component unmounts
    //         window.removeEventListener('popstate', handlePopState);
    //     };
    // }, []);

    return {};
};