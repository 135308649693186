import {localized} from "../i18n";
import Fraction from "fraction.js";

/**
 * @param value
 * @returns {string|string}
 */
export const stringToImageSrc = (value) => {
    return value ? `data:image/jpeg;base64,${value}` : undefined;
}

/**
 * @param a
 * @param b
 * @returns {number}
 */
export const alphabeticComparator = (a, b) => {

    const nameA = a.toLowerCase();
    const nameB = b.toLowerCase();

    if (nameA < nameB) {
        return -1;
    }

    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
}

/**
 * @param items
 * @param key
 * @returns {unknown[]}
 */
export const extractFirstLetters = (items, key) => {
    const firstLettersSet = new Set();

    items.forEach(item => {
        const firstLetter = localized(item, key)[0].toUpperCase();
        firstLettersSet.add(firstLetter);
    });

    return Array.from(firstLettersSet).sort();
}

/**
 * @param decimal
 * @returns {string}
 */
export const convertToFraction = (decimal) => {
    const fraction = new Fraction(decimal);
    return fraction.toFraction(true);
}

/**
 * @param temp
 * @returns {null|string}
 */
export const formatTemperature = (temp) => {
    // Extract the numerical value and the unit (assuming the input is like "50C" or "122F")
    let value = parseFloat(temp);
    let unit = temp.slice(-1).toUpperCase(); // Get the last character to check if it's "C" or "F"

    if (unit === 'C') {
        return `${value}°C`;  // Format for Celsius
    }
    else if (unit === 'F') {
        return `${value}°F`;  // Format for Fahrenheit
    }

    return null;
}