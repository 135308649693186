import React, {useEffect, useState} from 'react';
import {
    Anchor,
    Badge,
    Box,
    Card, Center, Container, Divider,
    Group,
    Highlight,
    Image,
    Overlay,
    Stack,
    Text,
    Tooltip,
    Transition
} from "@mantine/core";
import i18n, {localized, localizedEn} from "../../i18n";
import {alphabeticComparator} from "../../util/utils";
import {TooltipEn, useLanguageStore} from "../../components/languageSelector/LanguageSelector";
import {useNavigate} from "react-router-dom";
import useKey, {keyState} from "../useKey";
import {useTranslation} from "react-i18next";
import {Icon} from "../../components/icons/Icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-regular-svg-icons/faHeart";
import {faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons/faHeart";
import {Settings, useAccountContext} from "../../components/account/AccountContext";
import {
    FOOD_FACTS_VIDEO_ID_LINK_NAME,
    FOOD_FACTS_VIDEO_LINK_NAME, LITTLE_STORIES_LINK_NAME,
    localizedLink,
    WIKI_LINK_NAME
} from "../../util/links";
import useWiki from "../useWiki";
import {
    getEntityNaturalSourceIngredient,
    getEntityRepresentativeIngredient
} from "./IngredientUtil";
import useResult from "../useResult";
import classes from "./IngredientLink.module.css";
import {faChevronRight, faImage, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import useIngredient from "./useIngredient";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import {CustomCarousel} from "../../components/carousel/CustomCarousel";
import {theme} from "../../Theme";
import useEntityAll from "./useEntityAll";
import {useFeatures} from "../../components/features/useFeatures";
import useMedia from "../useMedia";


/**
 * LinkedIngredients
 */
export const LinkedIngredients = () => {

    const { isSm } = useMedia();

    const {key, stateKey} =
        useKey({
            onKeyChange: () => {
                refetchItems();
            }
        });

    const { data: dataItems, isLoaded: isLoadedItems, totalCount: totalCountItems,
        reset: resetItems, refetch: refetchItems} =
        useEntityAll({
            linkNames: [FOOD_FACTS_VIDEO_LINK_NAME],
            random: theme.custom.home.randomLinkedIngredients,
        })

    /**
     * DataItemsCarousel
     */
    const DataItemsCarousel = () => {

        return (
            <CustomCarousel interval = {0}
                            color={"secondary"}
                            withControls={false}
                            withIndicators={false}
                            slideSize={{ base: '100%', sm: '50%'}}
                            slidesToScroll={{ base: 1, sm: 2}}
                            slideGap={"md"}>
                {dataItems?.map((item, index) =>
                    <CustomCarousel.Slide>
                        <IngredientLink entity={item} showCategory isFoodFacts onClick={() =>  window.open(localizedLink(item.links, FOOD_FACTS_VIDEO_LINK_NAME), "_blank")}/>
                    </CustomCarousel.Slide>
                )}
            </CustomCarousel>
        )
    }

    return !isLoadedItems ? null :
        <SimpleBox color={"primary"}>
            {!isSm ?
                <Group justify={"space-between"} align={"center"}>
                    <Icon name={"food-facts"} className={classes.foodfactsicon}/>
                    <DataItemsCarousel/>
                </Group>
                :
                <Stack>
                    <Center>
                        <Icon name={"food-facts"} className={classes.foodfactsicon}/>
                    </Center>
                    <DataItemsCarousel/>
                </Stack>
            }
        </SimpleBox>
}

/**
 * IngredientIconTitle
 */
export const IngredientIconTitle = (props) => {

    const {textKey = 'common.ingredients', color = "var(--mantine-color-white)", withIcon = true} = props;

    const { t } = useTranslation();

    return <Group gap="2" wrap={"nowrap"} {...props}>
                {!withIcon ? null :
                    <Icon name={"leaf"} style={{width: "18px", height: "18px", marginBottom: "-2px", fill: color}}/>
                }
                <Text pl={withIcon ? 4 : 0} size={"sm"} fw={700}>{t(textKey)}</Text>
            </Group>
}

/**
 * IngredientPairingIconTitle
 */
export const IngredientPairingIconTitle = (props) => {

    const {textKey = 'ingredient.entityPairing', color = "var(--mantine-color-white)", withIcon = true} = props;

    const { t } = useTranslation();

    return <Group gap="2" wrap={"nowrap"} {...props}>
        {!withIcon ? null :
            <Icon name={"circle-overlap"} style={{width: "18px", height: "18px", marginBottom: "-2px", fill: color}}/>
        }
        <Text pl={withIcon ? 4 : 0} size={"sm"} fw={700}>{t(textKey)}</Text>
    </Group>
}

/**
 * Image169WithZoom
 */
const Image169WithZoom = ({src, ingredient, showCategory, hovered, isFoodFacts = false}) => {

    const { isSm } = useMedia();
    const {t} = useTranslation();

    // if(!src) {
    //     console.log("no src" + ingredient.ingredientId)
    // }

    return (
        <Box className={classes.ingredientlinkimage169root}>

            {!src ?
                <Center className={classes.ingredientlinkimage169noimage}>
                    <FontAwesomeIcon icon={faImage} size={!isSm ? "8x" : "6x"}/>
                </Center>
                :
                <>
                    <Transition
                        mounted={true}
                        transition="scale"
                        timingFunction="ease-in-out">
                        {(styles) => (
                            <>
                                <Image
                                    src={src}
                                    className={classes.ingredientlinkimage169image}
                                    style={{
                                        transform: hovered? 'scale(1.1)' : 'scale(1)',  // Scale on hover
                                        transition: 'transform 0.15s ease-in-out',  // Smooth transition
                                    }}
                                />
                            </>
                        )}
                    </Transition>
                    {!isFoodFacts ? null :
                        <Box className={classes.ingredientlinkimage169righticon}>
                            <Box className={classes.ingredientlinkimage169withzoomicon}>
                                <Center>
                                    <FontAwesomeIcon icon={faYoutube} size={"lg"}/>
                                </Center>
                            </Box>
                        </Box>
                    }
                </>
            }

            <Container className={classes.ingredientlinkimage169overlay} p={"md"}>
                <Overlay color={!isFoodFacts ? "var(--mantine-color-primary-9)" : "var(--mantine-color-primary-6)"} backgroundOpacity={!hovered ? 0.8 : 0.95} blur={0} zIndex={1}/>

                <Group gap={0} justify="space-between" wrap={"nowrap"} className={classes.ingredientlinkimage169title}>
                    <IngredientText ingredient={ingredient}
                                    showCategory={showCategory}
                                    favoriteColor={"white"} color={"white"}
                                    badgeVariant={"filled"}/>
                    {!isFoodFacts ?
                        <FontAwesomeIcon icon={faChevronRight} color={"white"} />
                        :
                        <FontAwesomeIcon icon={faUpRightFromSquare} color={"white"} />
                    }
                </Group>
            </Container>
        </Box>
    );
};

/**
 * IngredientLink
 */
export const IngredientLink = ({entity, target = "_self", isFoodFacts = false,
                                showCategory = true, onClick}) => {

    const [hovered, setHovered] = useState(false);

    const { Features } = useFeatures();

    // const [ingredient, setIngredient] = useState(getEntityRepresentativeIngredient(entity));
    const [ingredientNaturalSource, setIngredientNaturalSource] = useState(getEntityNaturalSourceIngredient(entity));

    const {t} = useTranslation();
    const navigate = useNavigate();

    let languageEnglishDescriptionsEnabled = useLanguageStore((state) => state.languageEnglishDescriptionsEnabled);

    /**
     * Ingredient
     */
    const isEnabledIngredient = entity !== undefined && entity.entityId !== undefined;
    const {	data: dataIngredient, isLoaded: isLoadedIngredient,
        reset: resetIngredient, refetch: refetchIngredient} =
        useIngredient({
            enabled: isEnabledIngredient,
            ingredientId: getEntityRepresentativeIngredient(entity).ingredientId,
            entityId: entity.entityId,
        })

    /**
     * Wiki
     */
    const isEnabledEntityWiki = entity?.links.length > 0 && localizedLink(entity?.links, WIKI_LINK_NAME) !== undefined;
    const { data: dataEntityWiki, isLoaded: isLoadedEntityWiki,
        reset: resetEntityWiki, refetch: refetchEntityWiki} =
        useWiki({
            url: localizedLink(entity?.links, WIKI_LINK_NAME),
            enabled: isEnabledEntityWiki
        })

    const {isSuccess, isError} =
        useResult({
            isSuccess: (isEnabledIngredient ? isLoadedIngredient : true) &&
                        (isEnabledEntityWiki ? isLoadedEntityWiki : true)
        })

    return !isSuccess ? null :
        <Anchor target={target} underline="never"
                onClick={(event) => onClick ? onClick(event) : ingredientNavigate(navigate, dataIngredient)}
                className={classes.ingredientlinkroot}>

            <Card shadow="none" padding="0"
                // withBorder style={{border: "2px solid var(--mantine-color-primary-light-hover)"}}
                  style={{height: "100%"}}
                  bg={hovered ? "var(--mantine-color-primary-light-hover)" : "var(--mantine-color-primary-outline-hover)"}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
            >
                <Card.Section>
                    <Image169WithZoom src={dataEntityWiki?.image} hovered={hovered} ingredient={dataIngredient} showCategory={showCategory} isFoodFacts={isFoodFacts}/>
                </Card.Section>

                {!isFoodFacts &&
                    <Stack align="stretch" justify="space-between" gap={0}  flex={1}>

                        {(languageEnglishDescriptionsEnabled || i18n.language === "en") && dataEntityWiki && dataEntityWiki.extract ?
                            <Box p={"md"} pb={0}>
                                <Text c={"var(--mantine-color-primary-9)"} size="sm" lineClamp={3}>
                                    {dataEntityWiki.extract}
                                </Text>
                                {/*<Text c={"var(--mantine-color-primary-9)"} size={"sm"} pt={"xs"}>{t("recipe.author", {author: dataUser.name})}</Text>*/}
                            </Box>
                            :
                            <Box/>
                        }

                        <Box pt={   (!Features.ingredient.features.naturalSources.plan.enabled || ingredientNaturalSource === undefined) &&
                                    !Features.ingredient.features.pairing.plan.enabled &&
                                    !Features.molecule.plan.enabled ? 0 : "lg"}>

                            <Group align="flex-start" justify="space-between" gap="0" p={"md"} pt={0}>
                                {!Features.ingredient.features.naturalSources.plan.enabled || ingredientNaturalSource === undefined ? <Stack w={"33%"}/> :
                                    <>
                                        <Stack align="stretch" justify="flex-start" gap="4" w={"33%"}>
                                            <Text c={"var(--mantine-color-primary-9)"} ta={"center"} lineClamp={1}>{localized(ingredientNaturalSource, "name")}</Text>
                                            <Text c={"var(--mantine-color-primary-9)"} ta={"center"} size={'xs'} opacity={0.75}>
                                                {t("ingredient.naturalSource")}
                                            </Text>
                                        </Stack>
                                        <Divider w={1} orientation="vertical" color={"var(--mantine-color-primary-9)"} opacity={0.1}/>
                                    </>
                                }
                                {!Features.ingredient.features.pairing.plan.enabled ? <Stack w={"33%"}/> :
                                    <>
                                        <Stack align="stretch" justify="center" gap="4" w={"33%"}>
                                            <Group justify="center" gap="6">
                                                <Icon name={"circle-overlap"} style={{width: "18px", height: "18px", fill: "var(--mantine-color-primary-9)", marginBottom: "-2px"}}/>
                                                <Text c={"var(--mantine-color-primary-9)"} ta={"center"}>{entity.pairingsCount}</Text>
                                            </Group>
                                            <Text c={"var(--mantine-color-primary-9)"} ta={"center"} size={'xs'} opacity={0.75}>
                                                {t("ingredient.entityPairing")}
                                            </Text>
                                        </Stack>
                                        <Divider w={1} orientation="vertical" color={"var(--mantine-color-primary-9)"} opacity={0.1}/>
                                    </>
                                }
                                {!Features.molecule.plan.enabled ? <Stack w={"33%"}/> :
                                    <Stack align="stretch" justify="center" gap="4" w={"33%"}>
                                        <Group justify="center" gap="6">
                                            <Icon name={"physics"} style={{width: "18px", height: "18px", fill: "var(--mantine-color-primary-9)", marginBottom: "-2px"}}/>
                                            <Text c={"var(--mantine-color-primary-9)"} ta={"center"}>{entity.moleculesCount}</Text>
                                        </Group>
                                        <Text c={"var(--mantine-color-primary-9)"} ta={"center"} size={'xs'} opacity={0.75}>
                                            {t("molecule.molecules")}
                                        </Text>
                                    </Stack>
                                }
                                {/*<Divider w={1} orientation="vertical" color={"var(--mantine-color-primary-9)"} opacity={0.1}/>*/}
                                {/*<Stack align="stretch" justify="center" gap="xs" w={"33%"}>*/}
                                {/*    <Group justify="center" gap="6">*/}
                                {/*        <Icon name={"leaf"} style={{width: "18px", height: "18px", marginBottom: "-5px", fill: "var(--mantine-color-primary-9)"}}/>*/}
                                {/*        <Text c={"var(--mantine-color-primary-9)"} ta={"center"}>{recipe.ingredientsCount}</Text>*/}
                                {/*    </Group>*/}
                                {/*    <Text c={"var(--mantine-color-primary-9)"} ta={"center"} size={'xs'} opacity={0.75}>*/}
                                {/*        {t("common.ingredients")}*/}
                                {/*    </Text>*/}
                                {/*</Stack>*/}
                            </Group>
                        </Box>
                    </Stack>
                }
            </Card>
        </Anchor>
};

/**
 * IngredientText
 *
 * @param ingredient
 * @param ingredientHighlight
 * @param showCategory
 * @returns {React.JSX.Element|undefined}
 * @constructor
 */
export const IngredientText = ({   ingredient, ingredientHighlight,
                                   favoriteColor= "primary", color= "black", className,
                                    badgeVariant = "light",
                                   showCategory = true, align = "flex-start"}) => {

    const { isAuthenticated, userAccount, updateUserAccountSettings } = useAccountContext();
    const [settings] = useState(isAuthenticated ? userAccount.getSettings() : new Settings({}));
    const [favorite, setFavorite] = useState(settings.isFavoriteIngredient(ingredient?.name));

    const { Features } = useFeatures();

    const [categories, setCategories] = useState([]);

    const { t } = useTranslation();

    /**
     * @param ingredient
     */
    const getCategories = (ingredient) => {

        // Categories
        if(ingredient.categories && ingredient.categories.length > 0) {
            let categories = ingredient.categories.filter((category) => category.representative > 0)
            // return categories?.length > 0 ? categories[0] : ingredient.categories[0];
            return categories && categories.length > 0 ? categories : ingredient.categories;
        }
        // Single category
        else if (ingredient.category) {
            return [ingredient.category];
        }

        return [];
    }

    useEffect(() => {
        if(showCategory && ingredient) {

            // Remove duplicates
            setCategories(getCategories(ingredient).filter((category, index, self) =>
                index === self.findIndex((c) => c.entityCategoryId === category.entityCategoryId)
            ));
        }
    }, [ingredient]);


    /**
     * handleFavorite
     */
    function handleFavorite() {
        settings.setFavoriteIngredient(ingredient.name);
        updateUserAccountSettings(userAccount);
        setFavorite(settings.isFavoriteIngredient(ingredient.name));
    }

    if(!ingredient) {
        return undefined;
    }

    return (
        <Group gap={6} align="flex-start" wrap={"nowrap"} className={className}>

            {Features.account.features.ingredientFavorites.plan.enabled &&
                <FontAwesomeIcon icon={settings.isFavoriteIngredient(ingredient.name) ? faHeartSolid : faHeart} size={"md"} color={favoriteColor} opacity={0.25}
                                 style={{height: "18px", paddingTop: "4px"}}
                                 onClick={(event) => {event.stopPropagation(); event.preventDefault(); handleFavorite()}}/>
            }

            <Stack gap={0} align={align}>
                <Group justify={"flex-start"} gap={2} wrap={"nowrap"}>
                    <Text c={color} pb={4}>
                        <Highlight highlight={ingredientHighlight}>
                             {localized(ingredient, 'name')}
                        </Highlight>
                    </Text>
                    {ingredient.cookingLevelsCount > 0 &&
                        <Tooltip label={t("ingredient.cookingLevels")} bg={"tertiary"} c={"white"}>
                            <Badge variant={badgeVariant} color="tertiary" size="xs" ml={4} mt={-8}>
                                {t("ingredient.cookingLevelsAbbreviation")}
                            </Badge>
                        </Tooltip>
                    }
                    {ingredient.cookingTechniquesCount > 0 &&
                        <Tooltip label={t("ingredient.cookingTechniques")} bg={"tertiary"} c={"white"}>
                            <Badge variant={badgeVariant} color="tertiary" size="xs" ml={4} mt={-8}>
                                {t("ingredient.cookingTechniquesAbbreviation")}
                            </Badge>
                        </Tooltip>
                    }
                    {localizedLink(ingredient.links, FOOD_FACTS_VIDEO_LINK_NAME) === undefined && localizedLink(ingredient.links, FOOD_FACTS_VIDEO_ID_LINK_NAME) === undefined ? null :
                        <Tooltip label={t("ingredient.foodFacts")} bg={"tertiary"} c={"white"}>
                            <Badge variant={badgeVariant} color="tertiary" size="xs" ml={4} mt={-8}>
                                {t("ingredient.foodFactsAbbreviation")}
                            </Badge>
                        </Tooltip>
                    }
                    {localizedLink(ingredient.links, LITTLE_STORIES_LINK_NAME) === undefined ? null :
                        <Tooltip label={t("ingredient.littleStories")} bg={"tertiary"} c={"white"}>
                            <Badge variant={badgeVariant} color="tertiary" size="xs" ml={4} mt={-8}>
                                {t("ingredient.littleStoriesAbbreviation")}
                            </Badge>
                        </Tooltip>
                    }
                    <TooltipEn label={localized(ingredient, 'name')} labelEn={localizedEn(ingredient, 'name')}/>
                </Group>
                {!showCategory || !categories || categories?.length === 0 ? null :
                    <Text size={'xs'} opacity={0.75} c={color}>
                        {categories
                            .sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
                            .map((category, index) => localized(category, 'name')).join(", ")}
                    </Text>
                }
            </Stack>
        </Group>
    )
};

/**
 * IngredientTextByName
 */
export const IngredientTextByNameAndCategories = ({  ingredientName, ingredientNameEn, ingredientHighlight,
                                                    entityCategories, showCategory = true, align = "flex-start"}) => {

    const [categories, setCategories] = useState([]);

    const { t } = useTranslation();

    /**
     * @param ingredient
     */
    const getCategories = () => {

        // Categories
        if(entityCategories && entityCategories.length > 0) {
            let categories = entityCategories.filter((category) => category.representative > 0)
            // return categories?.length > 0 ? categories[0] : ingredient.categories[0];
            return categories && categories.length > 0 ? categories : entityCategories;
        }

        return [];
    }

    useEffect(() => {
        if(showCategory && ingredientName) {

            // Remove duplicates
            setCategories(getCategories().filter((category, index, self) =>
                index === self.findIndex((c) => c.entityCategoryId === category.entityCategoryId)
            ));
        }
    }, [ingredientName]);

    if(!ingredientName) {
        return undefined;
    }

    return (
        <Stack gap={0} align={align}>
            <Group justify={"flex-start"} gap={2}>
                <Highlight highlight={ingredientHighlight}>
                    {ingredientName}
                </Highlight>
                <TooltipEn label={ingredientName} labelEn={ingredientNameEn}/>
            </Group>
            {!showCategory || !categories || categories?.length === 0 ? null :
                <Text size={'xs'} opacity={0.75}>
                    {categories
                        .sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
                        .map((category, index) => localized(category, 'name')).join(", ")}
                </Text>
            }
        </Stack>
    )
};

/**
 * ingredientNavigate
 */
export function ingredientNavigate(navigate, ingredient, target = "_self") {

    if(!ingredient) {
        return;
    }

    if(target === "_self") {
        navigate(`/ingredient/${ingredient.ingredientId}`, {state: {...keyState()}});
    }
    else {
        window.open(`/ingredient/${ingredient.ingredientId}`, "_blank");
    }
}

/**
 * entityNavigate
 */
export function entityNavigate(navigate, entityId, target = "_self") {

    if(!entityId) {
        return;
    }

    if(target === "_self") {
        navigate(`/ingredient/entity/${entityId}`, {state: {...keyState()}});
    }
    else {
        window.open(`/ingredient/entity/${entityId}`, "_blank");
    }
}