/**
 * @param totalCount
 * @param paginationSize
 * @returns {number}
 */
export const paginationTotalPages = (totalCount, pageSize) => {
    return Math.ceil(totalCount / pageSize);
}

/**
 * @param page
 * @param pageSize
 * @returns {number}
 */
export const paginationStartSkip = (page, pageSize) => {
    return (page - 1) * pageSize;
}

/**
 * @param page
 * @param pageSize
 * @returns {number}
 */
export const paginationEndSkip = (page, pageSize) => {
    return (page - 1) * pageSize + pageSize;
}

/**
 * @param page
 * @param pageSize
 * @returns {number}
 */
export const paginationStartCount = (page, pageSize) => {
    return (page - 1) * pageSize + 1;
}

/**
 * @param page
 * @param pageSize
 * @param totalCount
 * @returns {number}
 */
export const paginationEndCount = (page, pageSize,totalCount) => {
    let result = (page - 1) * pageSize + pageSize;
    return result > totalCount ? totalCount : result;
}