import {useEffect, useState} from 'react';

/**
 * useLifecycle
 */
const useLifecycle = ({onMount, onUnmount} = {}) => {

    useEffect(() => {

        if(onMount) {
            onMount();
        }

        return () => {
            if(onUnmount) {
                onUnmount();
            }
        }
    }, []);

    return null;
};

export default useLifecycle;