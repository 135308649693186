import React from "react";
import {
    Text,
    Container,
    Divider,
    Button,
    Stack,
    Box,
    useMantineTheme,
    Group,
    Space,
    Center,
    SimpleGrid, Anchor
} from '@mantine/core';
import classes from './Footer.module.css';
import {Trans, useTranslation} from "react-i18next";
import {HomeLink} from "../../pages/home/HomeLink";
import {Icon} from "../icons/Icons";
import {ReactComponent as FooterSvg} from "./footer.svg";
import {Social} from "../social/Social";
import useMedia from "../../pages/useMedia";
import {useEnvironmentContext} from "../environment/EnvironmentContext";
import {useNavigate} from "react-router-dom";
import {usePaymentContext} from "../payment/PaymentContext";
import {FeaturesPlan} from "../features/Features";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandHoldingHeart} from "@fortawesome/free-solid-svg-icons";

/**
 * Footer
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = () => {

    const {environment} = useEnvironmentContext();

    const {paymentContributeLink} = usePaymentContext();

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const navigate = useNavigate();

    const { isSm } = useMedia();

    /**
     * About
     */
    const About = (props) => {

        return (
            <Stack gap={0} align="flex-start" justify="flex-start" {...props} >
                {/*<Text pb={"sm"} size={"sm"} >{t("footer.aboutUs")}</Text>*/}
                <Anchor underline="never" size={"xs"} variant='subtle' className={classes.staticLink} href={"/static/about-us.html"} target={"_blank"}>{t("footer.aboutUs")}</Anchor>
                <Anchor underline="never" size={"xs"} variant='subtle' className={classes.staticLink} href={paymentContributeLink} target={"_blank"}>{t("payment.donate")}</Anchor>
                <FeaturesPlan targetComponent={
                    <Anchor underline="never" size={"xs"} variant='subtle' >
                        <Text className={classes.staticLink}>
                            {t("feature.plans")}
                        </Text>
                    </Anchor>
                }/>
                <Anchor underline="never" size={"xs"} variant='subtle' className={classes.staticLink} href={"/static/disclaimer.html"} target={"_blank"}>{t("footer.disclaimer")}</Anchor>
            </Stack>
        )
    }

    /**
     * Conditions
     */
    const Conditions = (props) => {

        return (
            <Stack gap={0} align="flex-start" justify="flex-start" {...props} >
                {/*<Text pb={"sm"} size={"sm"} >{t("footer.conditions")}</Text>*/}
                <Anchor underline="never" size={"xs"} variant='subtle' className={classes.staticLink} href={"/static/terms-of-use.html"} target={"_blank"}>{t("footer.termsOfUse")}</Anchor>
                <Anchor underline="never" size={"xs"} variant='subtle' className={classes.staticLink} href={"/static/privacy-policy.html"} target={"_blank"}>{t("footer.privacyPolicy")}</Anchor>
                <Anchor underline="never" size={"xs"} variant='subtle' className={classes.staticLink} href={"/static/cookie-policy.html"} target={"_blank"}>{t("footer.cookiePolicy")}</Anchor>
                <Anchor underline="never" size={"xs"} variant='subtle' className={classes.staticLink} href={"/static/cookie-settings.html"} target={"_blank"}>{t("footer.cookieSettings")}</Anchor>
            </Stack>
        )
    }

    return (
        <footer className={!isSm ? classes.footer : classes.footermobile}>

            {isSm ? null :
                <FooterSvg className={classes.footersvg}/>
            }

            <Container h={"100%"} w={"100%"}>

                <Stack h={"100%"} align="stretch"
                       justify="flex-end"
                       gap={"lg"}>

                    <Group align={"flex-start"} justify={"space-between"} pb={"xl"} visibleFrom={"sm"}>
                        <Stack gap={0} className={classes.logo}>
                            <HomeLink>
                                <Icon name={"logo-full"}/>
                            </HomeLink>
                            <Text pt={3} size='xs' className={classes.description}>
                                {t("slogan")}
                            </Text>
                        </Stack>
                        <SimpleGrid cols={2}>
                            <About w={150}/>
                            <Conditions w={150}/>
                        </SimpleGrid>
                    </Group>

                    <Divider visibleFrom={"sm"} opacity={0.5}/>

                    <Group gap={0} justify={!isSm ? "space-between" : "center"} align={"center"} pt={"xl"} style={{paddingBottom: !isSm ? "calc(var(--mantine-spacing-xl) * 2.5)" : "calc(var(--mantine-spacing-xl) * 1.5)"}}>
                        <Text size='xs'>
                            <Trans i18nKey="common.copyright" values={{ date: new Date().getFullYear() }}>
                                <Button component="a" size={"xs"} variant='subtle' color="white" p={0} pl={3} pr={3} mt={-1} href="https://www.swissimpact.com/" target="_blank">company</Button>
                            </Trans>
                        </Text>
                            <Social justify={"flex-end"}/>
                    </Group>
                </Stack>
            </Container>

            {!isSm &&
                <Box className={classes.footerLast}>
                    <Container>
                        <Group opacity={0.75} gap={"md"} justify="center">
                            <Text size={"xs"}  c={"var(--mantine-color-white)"}>
                                {t("footer.companyInformation1", {registration: environment.companyRegistration})}
                            </Text>
                            <Text size={"xs"}  c={"var(--mantine-color-white)"}>
                                {t("footer.companyInformation2", {duns: environment.companyDuns})}
                            </Text>
                            <Text size={"xs"}  c={"var(--mantine-color-white)"}>
                                {t("footer.companyInformation3", {vat: environment.companyVatNumber})}
                            </Text>
                        </Group>
                    </Container>
                </Box>
            }
        </footer>
    );
}

export default Footer;