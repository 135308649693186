import React, {createContext, useContext, useState} from 'react';
import {useLoadingContext} from "../loading/LoadingContext";
import {ErrorPage} from "../../pages/error/ErrorPage";
import {MantineProvider} from "@mantine/core";
import {cssVariablesResolver, theme} from "../../Theme";

/**
 * ErrorContext
 */
const ErrorContext = createContext();

/**
 * useErrorContext
 */
export const useErrorContext = () => {
	return useContext(ErrorContext);
};

/**
 * ErrorProvider
 */
export const ErrorProvider = ({ children }) => {

	const { isLoaded } = useLoadingContext();

	const [error, setError] = useState(undefined);

	/**
	 * @param error Error object or undefined to close error page
	 * @returns {function(): void}
	 */
	const onError = (error) => {
		// console.error(error);
		setError(error);
		isLoaded();
	};

	return (
		<ErrorContext.Provider value={{error, onError}}>

			{error ?
				<MantineProvider withGlobalStyles
								 withNormalizeCSS
								 theme={theme}
								 cssVariablesResolver={cssVariablesResolver}>
					<ErrorPage />
				</MantineProvider>
				:
				children
			}

		</ErrorContext.Provider>
	);
};