import {keyState} from "../useKey";

/**
 * studioNavigate
 */
export function studioNavigate(navigate, target = "_self") {

    if(target === "_self") {
        navigate(`/studio`, {state: {...keyState()}});
    }
    else {
        window.open(`/studio`, "_blank");
    }
}