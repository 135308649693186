import React, {Suspense} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './styles/main.css';
import {createRoot} from 'react-dom/client';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from './i18n';
import {AuthProvider} from "./components/account/AccountContext";
import {LoadingProvider} from "./components/loading/LoadingContext";
import {ErrorProvider} from "./components/error/ErrorContext";
import {ApplicationProvider} from "./components/application/ApplicationContext";
import {PaymentProvider} from "./components/payment/PaymentContext";
import {EnvironmentProvider} from "./components/environment/EnvironmentContext";

const container = document.getElementById('root');
const root = createRoot(container);

i18n.use(LanguageDetector)

root.render(
    // <React.StrictMode>
        <Suspense>
            <LoadingProvider>
                <ErrorProvider>
                    <EnvironmentProvider>
                        <AuthProvider>
                            <PaymentProvider>
                                <ApplicationProvider>
                                    <Router>
                                        <App/>
                                    </Router>
                                </ApplicationProvider>
                            </PaymentProvider>
                        </AuthProvider>
                    </EnvironmentProvider>
                </ErrorProvider>
            </LoadingProvider>
        </Suspense>
    // </React.StrictMode>
);