import React, {useEffect, useState} from 'react';
import {
    Container,
    Space, Text,
} from '@mantine/core';
import classes from './MoleculesPage.module.css';
import {Page, PageTitle} from "../../components/page/Page";
import {useTranslation} from "react-i18next";
import {Icon} from "../../components/icons/Icons";
import {MoleculeList} from "./MoleculeList";
import useKey from "../useKey";
import useResult from "../useResult";
import {AdvertisingGrid} from "../../components/advertising/Advertising";
import {useLoadingContext} from "../../components/loading/LoadingContext";
import {Sponsors} from "../../components/sponsor/Sponsor";
import i18n, {parameterLanguage} from "../../i18n";
import {useParams} from "react-router-dom";

/**
 * MoleculesPage
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const MoleculesPage = (props) => {

	const {paramLng} = useParams();
	const lng = parameterLanguage(paramLng);

    const { isLoading, isLoaded } = useLoadingContext();

    const [isLoadedMolecules, setIsLoadedMolecules] = useState(false);

    const { t } = useTranslation();

    const {key, stateKey, random} =
        useKey({
			isLoading: isLoading,
			onKeyChange: () => {
				console.debug(`MoleculesPage`);

				setIsLoadedMolecules(false);
			}
		});

	useEffect(() => {
		if(lng !== undefined && lng !== i18n.language) {
			i18n.changeLanguage(lng)
				.then(value => window.location.reload());
		}
	}, [lng]);

    const {isSuccess, isError} =
        useResult({
			isLoaded: isLoaded,
			isSuccess: isLoadedMolecules
        })

    return (

        <Page
            pageTitle={t("common.molecules")}
			pageKeywords={`molecules`}
			pageUrl={`/molecules`}
			pageTitles={[
				<PageTitle
					displayTitle={<Text lineClamp={2} inherit>{t("common.molecules")}</Text>}
					titleIcon={<Icon name={"physics"}/>}
					/>
			]}>

            <Container>
				<AdvertisingGrid space={"xs"}>
					<Space h="xs"/>
					<Space h="lg"/>
					<MoleculeList
						scope={`MoleculesPage`}
						onLoad={(ids, totalCount) => setIsLoadedMolecules(true)}/>

					<Space h={"lg"}/>
					<Sponsors section={"molecules"}/>

				</AdvertisingGrid>
            </Container>
        </Page>
    );
}

export default MoleculesPage;