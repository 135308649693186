import { useEffect, useState } from 'react';
import { fetchIngredient } from "../../api/api";
import { useAccountContext } from "../../components/account/AccountContext";
import { useErrorContext } from "../../components/error/ErrorContext";
import axios from 'axios';

/**
 * IngredientTypes
 */
export const IngredientTypes = {
	Representative: 'Representative',
	NaturalSource: 'NaturalSource',
	Synonym: 'Synonym'
};

/**
 * useIngredient
 */
const useIngredient = ({
						   enabled ,
						   onSuccess,
						   onError,
						   ingredientId,
						   ingredientName,
						   entityId,
						   excludeProjections,
						   incrementViews = false
					   } = {}) => {

	const { getIdToken } = useAccountContext();
	const { onError: onErrorContext } = useErrorContext();

	const [data, setData] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [ingredientType, setIngredientType] = useState(undefined);
	const [ingredientEntityId, setIngredientEntityId] = useState(undefined);
	const [naturalSourceEntityIds, setNaturalSourceEntityIds] = useState([]);

	/**
	 * Fetch ingredient data from API using Axios, return a Promise
	 */
	const fetchData = async (force = false) => {

		if (!force && (!enabled || isLoading)) {
			return;
		}

		setIsLoading(true);
		setIsLoaded(false); // Ensure isLoaded is reset on fetch start

		reset();

		const cancelTokenSource = axios.CancelToken.source(); // Create a new cancel token

		try {
			const responseData = await fetchIngredient(ingredientId, ingredientName, entityId, excludeProjections, incrementViews, await getIdToken(), cancelTokenSource.token);

			setData(responseData);
			setIngredientType(getIngredientType(responseData));
			setIngredientEntityId(getEntityId(responseData));
			setNaturalSourceEntityIds(getNaturalSourceEntityIds(responseData));

			if (onSuccess) {
				onSuccess(responseData);
			}

			setIsLoaded(true);
			return responseData;

		}
		catch (error) {

			if (axios.isCancel(error)) {
				console.log('Request canceled', error.message);
			}
			else {

				if(onError) {
					onError(error);
				}
				else {
					onErrorContext(error); // Handle the error via global error handler
					// throw error;
				}
			}
		}
		finally {
			setIsLoading(false);
		}

		// Cancel token cleanup
		return () => cancelTokenSource.cancel("Request canceled by user.");
	};

	/**
	 * Reset the state
	 */
	const reset = () => {
		setIsLoaded(false);
		setData({});
		setIngredientEntityId(undefined);
		setNaturalSourceEntityIds([]);
	};

	/**
	 * Refetch the data (returns a Promise)
	 */
	const refetch = () => {
		return fetchData(true);
	};

	useEffect(() => {
		fetchData();
	}, [enabled]);

	/**
	 * Get the type of the ingredient based on its entities
	 */
	const getIngredientType = (data) => {
		if (!data.entities) {
			return undefined;
		}

		const representativeEntities = data.entities.filter((entity) => entity.representative > 0);
		if (representativeEntities.length > 0) {
			return IngredientTypes.Representative;
		}

		const synonymEntities = data.entities.filter((entity) => entity.representative === 0 && entity.naturalSourceName === 0);
		if (synonymEntities.length > 0) {
			return IngredientTypes.Synonym;
		}

		const naturalSourceEntities = data.entities.filter((entity) => entity.representative === 0 && entity.naturalSourceName === 1);
		if (naturalSourceEntities.length > 0) {
			return IngredientTypes.NaturalSource;
		}

		return undefined;
	};

	/**
	 * Get the main entity ID of the ingredient
	 */
	const getEntityId = (data) => {
		if (!data.entities) {
			return undefined;
		}

		const representativeEntities = data.entities.filter((entity) => entity.representative > 0);
		if (representativeEntities.length > 0) {
			return representativeEntities[0].entityId;
		}

		const synonymEntities = data.entities.filter((entity) => entity.representative === 0 && entity.naturalSourceName === 0);
		if (synonymEntities.length > 0) {
			return synonymEntities[0].entityId;
		}

		return undefined;
	};

	/**
	 * Get the entity IDs of the natural sources
	 */
	const getNaturalSourceEntityIds = (data) => {
		if (!data.entities) {
			return undefined;
		}

		const result = data.entities.filter((entity) => entity.naturalSourceName > 0)
			.sort((a, b) => a.entityId - b.entityId)
			.map(entity => entity.entityId)
			.join(',');

		if (!result || result.length === 0) {
			return "-1"; // Force emptying query store
		}

		return result;
	};

	return {
		data,
		ingredientType,
		ingredientEntityId,
		naturalSourceEntityIds,
		isLoaded,
		reset,
		refetch
	};
};

export default useIngredient;
