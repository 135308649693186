import {Route, Routes} from 'react-router-dom';
import HomePage from "./pages/home/HomePage";
import SearchPage from "./pages/search/SearchPage";
import RecipesPage from "./pages/recipe/RecipesPage";
import RecipePage from "./pages/recipe/RecipePage";
import IngredientsPage from "./pages/ingredient/IngredientsPage";
import IngredientPage from "./pages/ingredient/IngredientPage";
import MoleculePage from "./pages/molecule/MoleculePage";
import MoleculesPage from "./pages/molecule/MoleculesPage";
import AccountPage from "./pages/account/AccountPage";
import StudioPage from "./pages/studio/StudioPage";

/**
 * AppRoutes
 *
 * @returns {JSX.Element}
 * @constructor
 */
const AppRoutes = () => {

	return (
		<Routes>
			<Route path='*' element={<HomePage/>}/>
			<Route path='/' element={<HomePage/>}/>
			<Route path='/:paramLng/' element={<HomePage/>}/>

			<Route path='/account' element={<AccountPage/>}/>
			<Route path='/:paramLng/account' element={<AccountPage/>}/>

			<Route path='/search/:paramSearch?' element={<SearchPage/>}/>
			<Route path='/:paramLng/search/:paramSearch?' element={<SearchPage/>}/>

			<Route path='/studio' element={<StudioPage/>}/>
			<Route path='/:paramLng/studio' element={<StudioPage/>}/>

			<Route path='/recipes/:paramParentRecipeCategoryId' element={<RecipesPage/>}/>
			<Route path='/:paramLng/recipes/:paramParentRecipeCategoryId' element={<RecipesPage/>}/>
			<Route path='/recipe/:paramRecipeId' element={<RecipePage/>}/>
			<Route path='/:paramLng/recipe/:paramRecipeId' element={<RecipePage/>}/>

			<Route path='/ingredients/:paramEntityCategoryId' element={<IngredientsPage/>}/>
			<Route path='/:paramLng/ingredients/:paramEntityCategoryId' element={<IngredientsPage/>}/>
			<Route path='/ingredient/:paramIngredientId' element={<IngredientPage/>}/>
			<Route path='/:paramLng/ingredient/:paramIngredientId' element={<IngredientPage/>}/>
			<Route path='/ingredient/name/:paramIngredientName' element={<IngredientPage isIngredientName/>}/>
			<Route path='/:paramLng/ingredient/name/:paramIngredientName' element={<IngredientPage isIngredientName/>}/>
			<Route path='/ingredient/entity/:paramEntityId' element={<IngredientPage isEntity/>}/>
			<Route path='/:paramLng/ingredient/entity/:paramEntityId' element={<IngredientPage isEntity/>}/>

			<Route path='/molecules' element={<MoleculesPage/>}/>
			<Route path='/:paramLng/molecules' element={<MoleculesPage/>}/>
			<Route path='/molecule/:paramMoleculeId' element={<MoleculePage/>}/>
			<Route path='/:paramLng/molecule/:paramMoleculeId' element={<MoleculePage/>}/>
		</Routes>
	);
};

export default AppRoutes;