import React, {useEffect, useState} from 'react';
import {
    SimpleGrid, Space,
    Stack,
    useMantineTheme
} from '@mantine/core';
import RecipeLink from "./RecipeLink";
import {Paginator} from "../../components/paginator/Paginator";
import useKey from "../useKey";
import useResult from "../useResult";
import useRecipeAll from "./useRecipeAll";
import useMedia from "../useMedia";

/**
 * Recipe09
 */
export const Recipe09 = ({ title, recipes, page, onPageChange, totalCount, recipeTarget = "_self", showCategory = true, categoryTarget = "_self"}) => {

    const theme = useMantineTheme();
    const { isSm } = useMedia();

    return (
        <Stack justify="flex-start" gap="0">
            {title ? title : null}
            <SimpleGrid cols={!isSm ? 2 : 1} spacing="lg" verticalSpacing="lg">
                {recipes.map((item, index) => (
                    <RecipeLink key={index} recipe={item} recipeTarget={recipeTarget} showCategory={showCategory} categoryTarget={categoryTarget}/>
                ))}
            </SimpleGrid>
            <Space h={"lg"}/>
            <Paginator color={"tertiary"} page={page} onPageChange={onPageChange} paginationSize={theme.custom.recipe.paginationSize}
                       selectedCount={totalCount} totalCount={totalCount}/>
        </Stack>
    )
}

/**
 * RecipeList
 */
const RecipeList = ({   recipeCategoryId, parentRecipeCategoryId, excludeRecipeId, recipeIds, ingredientIds, search, random, onLoad = (ids, totalCount) => {},
                        title, recipeTarget = "_self", showCategory = true, categoryTarget = "_self"}) => {

    const [page, setPage] = useState(1);

    const {key, stateKey} =
        useKey({
            onKeyChange: () => {
                console.debug(`RecipeList`);
            }
        });

    const { data: dataItems, isLoaded: isLoadedItems, totalCount: totalCountItems,
            reset: resetItems, refetch: refetchItems} =
        useRecipeAll({
            recipeCategoryId: recipeCategoryId,
            parentRecipeCategoryId: parentRecipeCategoryId,
            excludeRecipeId: excludeRecipeId,
            recipeIds: recipeIds,
            ingredientIds: ingredientIds,
            search: search,
            random: random,
            page: page
        })

    const {isSuccess, isError} =
        useResult({
            isSuccess: isLoadedItems,
            onSuccess: () => {
                onLoad(dataItems.sort((a, b) => a.recipeId - b.recipeId).map(item => item.recipeId), totalCountItems);
            }
        })

    useEffect(() => {

        resetItems();
        refetchItems();

    }, [page]);

    return (!isSuccess ? null :
            <Recipe09 title={title}
                      recipes={dataItems}
                      page={page}
                      onPageChange={setPage}
                      totalCount={totalCountItems}
                      recipeTarget={recipeTarget}
                      showCategory={showCategory}
                      categoryTarget={categoryTarget}/>
    );
}

export default RecipeList;