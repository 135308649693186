import React, {memo, useCallback, useEffect, useState} from "react";
import {Box, Button, Center, Group, Image, Stack, Text, Tooltip, Transition} from "@mantine/core";
import {
	getBezierPath,
	Handle, NodeResizeControl,
	NodeToolbar,
	Position,
	useInternalNode,
	useOnSelectionChange, useReactFlow, useUpdateNodeInternals,
} from "@xyflow/react";

import classes from "./Studio.module.css"
import useWiki from "../useWiki";
import {
	faImage,
	faObjectUngroup,
	faTrash,
	faUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getNodesByParentId, ungroupNode} from "./StudioUtils";
import {entityNavigate} from "../ingredient/IngredientLink";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

/**
 * ImageWithZoom
 */
const ImageWithZoom = ({node}) => {

	const [hovered, setHovered] = useState(false);

	const { setNodes, getNode } = useReactFlow();

	const { data: dataEntityWiki, isLoaded: isLoadedEntityWiki,
		reset: resetEntityWiki, refetch: refetchEntityWiki} =
		useWiki({
			enabled: true,
			url: node.data.entity.wikiLink,
			onSuccess: (wiki) => {

				setNodes((prevNodes) =>
					prevNodes.map((n) => {
						// Update only the current group node
						if (n.id === node.id) {
							return {
								...n,
								data: {
									...n.data,
									wiki: wiki
								}
							};
						}
						return n; // Leave other nodes unchanged
					})
				);
			}
		})

	useEffect(() => {

	}, [node]);

	return !dataEntityWiki || !dataEntityWiki.image ?
		<Box className={`${classes.imagewithzoomroot} ${classes.imagewithzoomnoimage}`}>
			<FontAwesomeIcon icon={faImage} size={"3x"} color={node.selected ? "var(--mantine-color-primary-6)" : "var(--mantine-color-primary-2)"}/>
		</Box>
		:
		<Box className={classes.imagewithzoomroot}
			 onMouseEnter={() => setHovered(true)}
			 onMouseLeave={() => setHovered(false)}>
			<Transition
				mounted={true}
				transition="scale"
				timingFunction="ease-in-out">
				{(styles) => (
					<Image
						src={dataEntityWiki.image}
						className={classes.imagewithzoomimage}
						style={{
							transform: hovered? 'scale(1.1)' : 'scale(1)',  // Scale on hover
							transition: 'transform 0.15s ease-in-out',  // Smooth transition
						}}
					/>
				)}
			</Transition>

			{/*<Container className={classes.imagewithzoomoverlay}>*/}
			{/*	<Overlay color="var(--mantine-color-primary-9)" backgroundOpacity={!hovered ? 0 : 0.12} blur={0} zIndex={1}/>*/}
			{/*	/!*<Stack h={"100%"} pt={"100%"} align="flex-end" justify="center">*!/*/}
			{/*	/!*	*!/*/}
			{/*	/!*</Stack>*!/*/}
			{/*	<Stack gap={0} justify="flex-end" className={classes.imagewithzoomtitle}>*/}
			{/*		<Text size={"sm"}>{node.data.label}</Text>*/}
			{/*/!*		<Group align="flex-start" gap={6} wrap={"nowrap"} >*!/*/}

			{/*/!*			<Stack gap={0}>*!/*/}
			{/*/!*				<Text c={"white"} lineClamp={1} pb={4}>*!/*/}
			{/*/!*					{localized(recipe, 'name')}*!/*/}
			{/*/!*				</Text>*!/*/}
			{/*/!*				{showCategory ?*!/*/}
			{/*/!*					<Text c={"white"} size={'xs'} opacity={0.75}>*!/*/}
			{/*/!*						{sortedCategories(recipe.categories)*!/*/}
			{/*/!*							.map((category, index) => localized(category, 'name'))*!/*/}
			{/*/!*							.join(" / ")}*!/*/}
			{/*/!*					</Text>*!/*/}
			{/*/!*					:*!/*/}
			{/*/!*					// <Text c={"white"} size={'xs'} opacity={0.75}>*!/*/}
			{/*/!*					//     &nbsp;*!/*/}
			{/*/!*					// </Text>*!/*/}
			{/*/!*					null*!/*/}
			{/*/!*				}*!/*/}
			{/*/!*			</Stack>*!/*/}
			{/*/!*		</Group>*!/*/}
			{/*/!*		<FontAwesomeIcon icon={faChevronRight} color={"white"} />*!/*/}
			{/*	</Stack>*/}
			{/*</Container>*/}
		</Box>

};

/**
 * NodeToolbarDefault
 */
const NodeToolbarDefault = ({ node }) => {

	const { setNodes, getNode } = useReactFlow();
	const [selectedNodes, setSelectedNodes] = useState([]); // State to keep track of selected nodes

	const navigate = useNavigate();
	const { t } = useTranslation();

	// This callback updates the selected nodes' IDs whenever a selection change occurs
	const onChange = useCallback(({ nodes }) => {
		setSelectedNodes(nodes.map((node) => node.id)); // Map selected nodes to their IDs
	}, []);

	// React Flow hook to listen for selection changes
	useOnSelectionChange({
		onChange, // Pass the memoized onChange handler
	});

	/**
	 * handleUngroup
	 * This function removes the `parentId` and `extent` properties from the node,
	 * effectively ungrouping it, and recalculates its absolute position to ensure it remains in the same spot on the canvas.
	 */
	const handleUngroup = () => {
		setNodes((prevNodes) =>

			prevNodes.map((n) => {

				if (n.id === node.id) {
					// Calculate the node's absolute position
					let absolutePosition = n.position;

					// If the node is inside a group, calculate its absolute position relative to the group's position
					if (n.parentId) {
						const groupNode = getNode(n.parentId); // Retrieve the parent group node using its ID
						if (groupNode) {
							// Add the group's position to the node's position to calculate the absolute position
							absolutePosition = {
								x: groupNode.position.x + n.position.x,
								y: groupNode.position.y + n.position.y,
							};
						}
					}

					// Return a new node object with `parentId` and `extent` removed, and the absolute position updated
					return ungroupNode(n, absolutePosition);
				}

				// Return unchanged nodes
				return n;
			})
		);
	};

	/**
	 * handleRemoveNode
	 * Removes the node entirely from the React Flow canvas.
	 */
	const handleRemoveNode = () => {
		setNodes((prevNodes) => prevNodes.filter((n) => n.id !== node.id)); // Filter out the current node by ID
	};

	// Render the Node Toolbar. It is only visible when exactly one node is selected and it is the current node
	return (
		<NodeToolbar isVisible={selectedNodes.length === 1 && node.selected} position={"top"}>
			<Button.Group pb={2}>
				{/* Render the ungroup button only if the node has a parentId (i.e., it is part of a group) */}
				{node.parentId && (
					<Tooltip label={t("studio.ungroup")}>
						<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} onClick={handleUngroup}>
							<FontAwesomeIcon icon={faObjectUngroup}/>
						</Button>
					</Tooltip>
				)}
				<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} onClick={() => entityNavigate(navigate, node.id, "_blank")}>
					<FontAwesomeIcon icon={faUpRightFromSquare}/>
				</Button>
				<Tooltip label={t("studio.delete")}>
					<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} onClick={handleRemoveNode}>
						<FontAwesomeIcon icon={faTrash}/>
					</Button>
				</Tooltip>
			</Button.Group>
		</NodeToolbar>
	);
};

/**
 * GroupNode
 */
export const GroupNode = memo((node) => {

	const { setNodes, getNodes } = useReactFlow();

	const [selectedNodes, setSelectedNodes] = useState([]); // State to keep track of selected nodes
	const [hasChildren, setHasChildren] = useState(false); // State to track if the group has child nodes
	const [temporarySelection, setTemporarySelection] = useState(false);


	const { t } = useTranslation();

	// This callback updates the selected nodes' IDs whenever a selection change occurs
	const onChange = useCallback(({ nodes }) => {
		const childNodes = getNodesByParentId(getNodes(), node.id);
		setHasChildren(childNodes.length > 0); // Update state to reflect if the group has children
		setSelectedNodes([nodes.map((node) => node.id)]); // Map selected nodes to their IDs

	}, []);

	// React Flow hook to listen for selection changes
	useOnSelectionChange({
		onChange, // Pass the memoized onChange handler
	});

	/**
	 * handleUngroup
	 * This function removes the `parentId` and `extent` properties from all child nodes of the group,
	 * effectively ungrouping them.
	 */
	const handleUngroup = () => {
		setNodes((prevNodes) => {

			const updatedNodes = prevNodes.map((n) => {

				// Check if the node is a child of the current group
				if (n.parentId === node.id) {
					const position = {
						x: n.position.x + (node.positionAbsoluteX || 0), // Adjust x position to keep its position on the canvas
						y: n.position.y + (node.positionAbsoluteY || 0), // Adjust y position to keep its position on the canvas
					};

					// Remove parentId and extent to ungroup the node
					return ungroupNode(n, position);
				}
				return n; // Return the node as is if it's not a child of the group
			});

			// After ungrouping, update `hasChildren` to false if there are no child nodes left
			setHasChildren(false);

			return updatedNodes;
		});
	};

	/**
	 * handleRemoveNode
	 * Removes the group node and all child nodes that reference it as a parentId.
	 */
	const handleRemoveNode = () => {

		setNodes((prevNodes) => {

			// Get all child nodes that have the current group node as their parentId
			const childNodes = prevNodes.filter((n) => n.parentId === node.id);

			// Remove the group node and all child nodes
			return prevNodes.filter((n) => n.id !== node.id && !childNodes.includes(n));
		});
	};

	/**
	 * changeWeighting
	 * Updates the `data.weighting` of the group node dynamically.
	 * @param {number} newWeighting - The new weighting value (between 0 and 1).
	 */
	const changeWeighting = (newWeighting) => () => {

		setTemporarySelection(true);

		setNodes((prevNodes) =>
			prevNodes.map((n) => {
				// Update only the current group node
				if (n.id === node.id) {
					return {
						...n,
						data: {
							...n.data,
							weighting: newWeighting, // Update weighting value
						},
						selected: false
					};
				}
				return n; // Leave other nodes unchanged
			})
		);

		// Simulate a re-selection with a slight delay to ensure React Flow processes the change
		setTimeout(() => {
			setNodes((prevNodes) =>
				prevNodes.map((n) => {
					if (n.id === node.id) {
						return {
							...n,
							selected: true, // Re-select the node
						};
					}
					return n;
				})
			);

			setTemporarySelection(false)
		}, 0); // Adjust the delay if necessary
	};

	return <Box className={classes.nodebase} style={{outline: temporarySelection ? "2px solid var(--mantine-color-primary-6)" : "none", borderRadius: "4px"}}>

		<Group justify={"center"} p={"xs"}>
			<Text size={"xs"} lh={1.3}>{node.data.label}</Text>
		</Group>

		<NodeToolbar isVisible={temporarySelection || (selectedNodes.length === 1 && node.selected)} position={"top"}>
			<Button.Group pb={2}>
				{hasChildren &&
					<Tooltip label={t("studio.ungroup")}>
						<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} onClick={handleUngroup}>
							<FontAwesomeIcon icon={faObjectUngroup}/>
						</Button>
					</Tooltip>
				}
				<Tooltip label={t("studio.delete")}>
					<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} onClick={handleRemoveNode}>
						<FontAwesomeIcon icon={faTrash}/>
					</Button>
				</Tooltip>
				<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} disabled={node.data.weighting === 0} onClick={changeWeighting(0)}>
					0
				</Button>
				<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} disabled={node.data.weighting === 0.5} onClick={changeWeighting(0.5)}>
					0.5
				</Button>
				<Button variant={"filled"} color={"secondary"} size={"xs"} radius={4} disabled={node.data.weighting === 1} onClick={changeWeighting(1)}>
					1
				</Button>
			</Button.Group>
		</NodeToolbar>

		<NodeResizeControl position={"bottom-right"}/>

		<Handle
			type="target"
			position={Position.Top}
			isConnectable={false}
			style={{
				backgroundColor: "transparent",
				border: "none",
			}}
		/>

		<Handle
			type="source"
			position={Position.Bottom}
			isConnectable={false}
			style={{
				backgroundColor: "transparent",
				border: "none",
			}}
		/>
	</Box>
});

/**
 * InputNode
 */
export const InputNode = memo((node) => {

	return <Box className={classes.nodebase}>

			<ImageWithZoom node={node}/>

			<Center>
				<Stack gap={0} justify="flex-end" className={classes.inputtitle}
					   style={{
						   color: node.selected ? "white" : "var(--mantine-color-primary-9)",
						   backgroundColor: node.selected ? "var(--mantine-color-primary-6)" : "var(--mantine-custom-color-body-light-hover)"
				}}>
					<Text size={"sm"} lh={1.3} >{node.data.label}</Text>
					<Text size={"xs"} lh={1.3} opacity={0.75}>{node.data.entity.categoryLabel}</Text>
					{/*<Text size={"xs"} lh={1.3} opacity={0.75}>{JSON.stringify(node.data.wiki)}</Text>*/}
				</Stack>
			</Center>

			<NodeToolbarDefault node={node}/>

			<Handle
				type="source"
				position={Position.Bottom}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>
		</Box>
});

/**
 * IntermediateNode
 */
export const IntermediateNode = memo((node) => {

	return <Box className={classes.nodebase}>

		<ImageWithZoom node={node}/>

		<Center>
			<Stack gap={0} justify="flex-end" className={classes.intermediatetitle}
				   style={{
					   color: node.selected ? "white" : "var(--mantine-color-primary-9)",
					   backgroundColor: node.selected ? "var(--mantine-color-primary-6)" : "var(--mantine-custom-color-body-light-hover)"
			}}>
				<Text size={"sm"} lh={1.3}>{node.data.label}</Text>
				<Text size={"xs"} lh={1.3} opacity={0.75}>{node.data.entity.categoryLabel}</Text>
				{/*<Text size={"xs"} lh={1.3} opacity={0.75}>{JSON.stringify(node.data.wiki)}</Text>*/}
			</Stack>
		</Center>

		<NodeToolbarDefault node={node}/>

		<Handle
			type="target"
			position={Position.Top}
			isConnectable={false}
			style={{
				backgroundColor: "transparent",
				border: "none",
			}}
		/>

		<Handle
			type="source"
			position={Position.Bottom}
			isConnectable={false}
			style={{
				backgroundColor: "transparent",
				border: "none",
			}}
		/>
	</Box>
});

/**
 * DefaultEdge
 */
export const DefaultEdge = memo(({ id, source, target, style = {}, data, markerEnd, selected }) => {

	// Use `useInternalNode` to get details of the `source` and `target` nodes
	const sourceNode = useInternalNode(source);
	const targetNode = useInternalNode(target);

	// If one of the nodes is undefined (e.g., during initial rendering), return null
	if (!sourceNode || !targetNode) {
		return null;
	}

	// Calculate the center positions of the source and target nodes
	const sourceCenterX = sourceNode.internals.positionAbsolute.x + sourceNode.measured.width / 2;
	const sourceCenterY = sourceNode.internals.positionAbsolute.y + sourceNode.measured.height / 2;

	const targetCenterX = targetNode.internals.positionAbsolute.x + targetNode.measured.width / 2;
	const targetCenterY = targetNode.internals.positionAbsolute.y + targetNode.measured.height / 2;

	// Determine the positions (e.g., Top, Bottom, Left, Right) relative to the node positions
	const sourcePosition = sourceCenterX <= targetCenterX ? Position.Right : Position.Left; // If target is to the right, source is "Right"
	const targetPosition = sourceCenterX <= targetCenterX ? Position.Left : Position.Right; // If target is to the right, target is "Left"

	// Build the Bezier curve path
	const [edgePath] = getBezierPath({
		sourceX: sourceCenterX,
		sourceY: sourceCenterY,
		sourcePosition,
		targetX: targetCenterX,
		targetY: targetCenterY,
		targetPosition,
	});

	// Modify the style dynamically based on the `selected` state
	const strokeWidth = data?.width > 3 ? data?.width : 3;

	const edgeStyle = {
		stroke: selected ? "var(--mantine-color-primary-light-hover)" : data?.color || "var(--mantine-color-primary-outline-hover)", // Change color if selected
		strokeWidth: strokeWidth,
		...style,
	};

	// Dynamically calculate the label dimensions
	const fontSize = 12; // Font size for the text
	const padding = 6; // Padding around the text
	const lineHeight = fontSize * 1.2; // Approximate line height
	const textLines = data?.label ? data.label.split("\n") : []; // Split label into multiple lines if it contains `\n`
	const textWidth = Math.max(...textLines.map((line) => line.length * (fontSize * 0.6))); // Calculate the width based on the longest line
	const textHeight = textLines.length * lineHeight + padding * 2; // Calculate the height dynamically based on the number of lines

	// Render the curve and the optional label
	return (
		<>
			{/* Render the dynamic path */}
			<path
				id={id}
				className="react-flow__edge-path"
				d={edgePath}
				style={edgeStyle} // Apply dynamic styles
				markerEnd={markerEnd} // Marker at the end of the curve
			/>
			{/* Optional label positioned at the center of the edge */}
			{data?.label && (
				<>
					{/* Add background for the label */}
					<rect
						x={(sourceCenterX + targetCenterX) / 2 - textWidth / 2} // Position slightly left to center the label
						y={(sourceCenterY + targetCenterY) / 2 - textHeight / 2} // Center the label vertically
						rx={4} // Border radius for the label background
						ry={4} // Border radius for the label background
						width={textWidth} // Dynamic width based on text length
						height={textHeight} // Dynamic height based on number of lines
						fill={selected ? "var(--mantine-color-primary-6)" : "var(--mantine-custom-color-body-light-hover)"} // Background color for the label
						opacity={selected ? 1 : 1} // Slight transparency for the background
					/>
					{/* Render the text on top of the background */}
					{textLines.map((line, index) => (
						<text
							key={index}
							x={(sourceCenterX + targetCenterX) / 2} // Center the text horizontally
							y={(sourceCenterY + targetCenterY) / 2 - textHeight / 2 + padding + lineHeight * (index + 0.8)} // Position each line dynamically
							textAnchor="middle"
							style={{
								fontSize: `${fontSize}px`,
								fill: selected ? "var(--mantine-color-primary-0)" : "var(--mantine-color-primary-9)",
								pointerEvents: "none",
							}}
						>
							{line}
						</text>
					))}
				</>
			)}
		</>
	);
});

