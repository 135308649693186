import { useEffect, useState } from 'react';
import {fetchInsights, fetchUser} from "../api/api";
import { useErrorContext } from "../components/error/ErrorContext";
import axios from 'axios';

/**
 * useInsights
 */
const useInsights = ({
					enabled,
					onSuccess,
					onError,
					collectionName,
					id
						} = {}) => {

	const { onError: onErrorContext } = useErrorContext();

	const [data, setData] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Fetch data from API using Axios, return a Promise
	 */
	const fetchData = async (force = false) => {

		if (!force && (!enabled || isLoading)) {
			return;
		}

		setIsLoading(true);
		setIsLoaded(false); // Ensure isLoaded is reset on fetch start

		reset();

		const cancelTokenSource = axios.CancelToken.source(); // Create a new cancel token

		try {
			const responseData = await fetchInsights(collectionName, id, cancelTokenSource.token);

			setData(responseData);

			if (onSuccess) {
				onSuccess(responseData);
			}

			setIsLoaded(true);
			return responseData; // Resolve the promise with fetched data
		}
		catch (error) {

			if (axios.isCancel(error)) {
				console.log('Request canceled', error.message);
			}
			else {

				// Not found
				if (error.status === 404) {

					const responseData = {
						collectionName: collectionName,
						id: id,
						views: 1
					};

					setData(responseData);

					if (onSuccess) {
						onSuccess(responseData);
					}

					setIsLoaded(true);
					return responseData; // Resolve the promise with fetched data
				}
				else {
					if(onError) {
						onError(error);
					}
					else {
						onErrorContext(error); // Handle the error via global error handler
						// throw error;
					}
				}
			}
		}
		finally {
			setIsLoading(false); // Mark loading as complete
		}

		// Cancel token cleanup
		return () => cancelTokenSource.cancel("Request canceled by user.");
	};

	/**
	 * Reset the state
	 */
	const reset = () => {
		setIsLoaded(false);
		setData({});
	};

	/**
	 * Refetch the data (returns a Promise)
	 */
	const refetch = () => {
		return fetchData(true); // Force a refetch, bypassing the enabled check
	};

	useEffect(() => {
		fetchData();
	}, [enabled]);

	return {
		data,
		isLoaded,
		reset,
		refetch
	};
};

export default useInsights;
