import {ReactComponent as LanguageEn} from "./english.svg";
import {ReactComponent as LanguageFr} from "./french.svg";
import {ReactComponent as LanguageDe} from "./german.svg";
import {ReactComponent as LanguageIt} from "./italian.svg";
import {ReactComponent as LanguagePt} from "./portuguese.svg";
import {ReactComponent as LanguageEs} from "./spanish.svg";

import {ReactComponent as Low} from "./low.svg";
import {ReactComponent as Medium} from "./medium.svg";
import {ReactComponent as High} from "./high.svg";

import {ReactComponent as Autumn} from "./autumn.svg";
import {ReactComponent as Spring} from "./spring.svg";
import {ReactComponent as Summer} from "./summer.svg";
import {ReactComponent as Winter} from "./winter.svg";

import {ReactComponent as HatChef} from "./hat-chef.svg";
import {ReactComponent as Leaf} from "./leaf.svg";
import {ReactComponent as Physics} from "./physics.svg";
import {ReactComponent as CircleOverlap} from "./circle-overlap.svg";

import {ReactComponent as Logo} from "./logo.svg";
import {ReactComponent as LogoFull} from "./logo-full.svg";
import {ReactComponent as LogoFullV} from "./logo-full-v.svg";

import {ReactComponent as ChatGpt} from "./gpt.svg";

import {ReactComponent as FoodFacts} from "./food-facts.svg";

import {ReactComponent as Contribute} from "./contribute.svg";

import {ReactComponent as Utensil} from "./utensil.svg";

import classes from './Icons.module.css';
import {Box} from "@mantine/core";

/**
 * Icons
 *
 * @returns {JSX.Element}
 */
export const Icon = (props) => {

    let {name, className} = props;

    let children = undefined;

    switch (name.toLowerCase()) {

        case "language-en":
            children = <LanguageEn className={className ? className : classes.icon} {...props}/>
            break;

        case "language-fr":
            children = <LanguageFr className={className ? className : classes.icon} {...props}/>
            break;

        case "language-de":
            children = <LanguageDe className={className ? className : classes.icon} {...props}/>
            break;

        case "language-it":
            children = <LanguageIt className={className ? className : classes.icon} {...props}/>
            break;

        case "language-pt":
            children = <LanguagePt className={className ? className : classes.icon} {...props}/>
            break;

        case "language-es":
            children = <LanguageEs className={className ? className : classes.icon} {...props}/>
            break;


        case "low":
            children = <Low className={className ? className : classes.icon} {...props}/>
            break;

        case "medium":
            children = <Medium className={className ? className : classes.icon} {...props}/>
            break;

        case "high":
            children = <High className={className ? className : classes.icon} {...props}/>
            break;


        case "autumn":
            children = <Autumn className={className ? className : classes.icon} {...props}/>
            break;

        case "spring":
            children = <Spring className={className ? className : classes.icon} {...props}/>
            break;

        case "summer":
            children = <Summer className={className ? className : classes.icon} {...props}/>
            break;

        case "Winter":
            children = <Winter className={className ? className : classes.icon} {...props}/>
            break;


        case "hat-chef":
            children = <HatChef className={className ? className : classes.icon} {...props}/>
            break;

        case "leaf":
            children = <Leaf className={className ? className : classes.icon} {...props}/>
            break;

        case "physics":
            children = <Physics className={className ? className : classes.icon} {...props}/>
            break;

        case "circle-overlap":
            children = <CircleOverlap className={className ? className : classes.icon} {...props}/>
            break;


        case "logo":
            children = <Logo className={className ? className : classes.logo} {...props}/>
            break;

        case "logo-full":
            children = <LogoFull className={className ? className : classes.logoFull} {...props}/>
            break;

        case "logo-full-v":
            children = <LogoFullV className={className ? className : classes.logoFull} {...props}/>
            break;


        case "gpt":
            children = <ChatGpt className={className ? className : classes.icon} {...props}/>
            break;


        case "food-facts":
            children = <FoodFacts className={className ? className : classes.icon} {...props}/>
            break;


        case "contribute":
            children = <Contribute className={className ? className : classes.icon} {...props}/>
            break;


        case "utensil":
            children = <Utensil className={className ? className : classes.icon} {...props}/>
            break;
    }

    return <Box>{children}</Box>
}