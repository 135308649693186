import {toDateTime} from "../../util/time";
import i18n, {localized} from "../../i18n";
import classes from './Notifications.module.css';
import {Box, Group, Image, Stack, Text} from "@mantine/core";
import {stringToImageSrc} from "../../util/utils";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStopwatch} from "@fortawesome/free-solid-svg-icons";

/**
 * notification
 */
export function notification(startTime, endTime, type, payload) {

	switch(type.toLowerCase()) {

		case "recipe":

			return {
				title: (
					<Stack gap={0}>
						<Text lineClamp={1}>{`${payload.instruction.sort} - ${localized(payload.instruction, "name")}`}</Text>
						<Text size={"xs"}>{`${i18n.t("common.finished")} ${toDateTime(startTime)}`}</Text>
					</Stack>
				),
				message: (
					<Text size={"sm"} lineClamp={1}>{localized(payload.recipe, "name")}</Text>
				),
				icon: (
					<Box className={classes.image169root} flex={1}>
						<Image
							src={stringToImageSrc(payload.image)}
							className={classes.image169image}
						/>
						<Box className={classes.image169lefticon}>
							<FontAwesomeIcon icon={faStopwatch} size={"3x"}/>
						</Box>
					</Box>
				),
				autoClose: false,
				withBorder: false,
				color: "tertiary",
				onOpen: () => {
					const sound = new Audio('/spot-short.mp3');
					// Set the volume
					// sound.volume = 0.75; // Adjust this value (0.1 = 10%, 0.5 = 50%, etc.)
					sound.play().catch((error) => {
						console.error("Audio error", error);
					});
				},
				classNames: {
					root: classes.recipenotificationroot,
					icon: classes.recipenotificationicon,
					title: classes.recipenotificationtitle,
					description: classes.recipenotificationdescription,
					closeButton: classes.recipenotificationclosebutton,
				}
			}

		default:
			return undefined;
	}
}